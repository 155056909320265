import {html} from 'lit-html';
import '@material/mwc-button';

import {getUserPool} from "../mixins/user-pool-mixin";
import {commonStyles} from '../mixins/common-styles';
import {translate} from "../mixins/translate-mixin";
import {determineLanguage} from '../mixins/determine-language-mixin';
import {makeLambdaRequest} from '../mixins/lambda-request-mixin';
import '../components/symcon-card';
import '../components/notification-dialog';
import '../components/send-activation-again-dialog';

const AmazonCognitoIdentity = require('amazon-cognito-identity-js');
const querystring = require('querystring');

const userPool = getUserPool();
const language = determineLanguage();
const resources = {
    en: {
        'login-description': 'Please login with your username and password to access further functions like managing your licenses or access the community forum. If you do not have an account yet, click "Register" to create a new account.',
        'username': 'Username',
        'password': 'Password',
        'forgot-password': 'Forgot Password?',
        'register': 'Register',
        'login': 'Login',
        'license-without-account': 'We have found your license. There is no corresponding account in our new account management yet. Do you want to register a new account and use access expanded functionality?',
        'cancel': 'Cancel',
        'login-failed': 'Login failed',
        'login-email-failed': 'Login with email failed',
        'username-was-changed': 'Your username was changed. You should have received a corresponding e-mail. If not, please contact us.',
        'username-not-existing': 'The username does not exist. If you do not have an account for our new account management please create one. Afterwards you will be able to link it to our community forum.',
        'password-incorrect': 'The password is incorrect. If you do not have an account for our new account management please create one. Afterwards you will be able to link it to our community forum.',
        'username-missing': 'Please enter a username',
        'username-invalid': 'The entered username is invalid. It probably contains spaces. If you do not have an account for our new account management please create one. Afterwards you will be able to link it to our community forum.',
        'password-missing': 'Please enter a password',
        'forum-link': '/account/forum',
        'forgot-password-link': '/account/forgot-password',
        'register-link': '/account/register'
    },
    de: {
        'login-description': 'Bitte melden Sie sich mit Benutzername und Passwort an um weitere Funktionen wie das Überprüfen Ihrer Lizenzen zu verwenden oder am Community Forum teilzunehmen. Wenn Sie noch kein Konto haben, klicken Sie auf "Registrieren" um ein Konto zu erstellen.',
        'username': 'Benutzername',
        'password': 'Passwort',
        'forgot-password': 'Passwort vergessen?',
        'register': 'Registrieren',
        'login': 'Anmelden',
        'license-without-account': 'Wir haben Ihre Lizenz gefunden. In unserer neuen Kontoverwaltung existiert hierzu noch kein Konto. Wollen Sie ein neues Konto registrieren und auf erweiterte Funktionen zugreifen?',
        'cancel': 'Abbrechen',
        'login-failed': 'Anmeldung fehlgeschlagen',
        'login-email-failed': 'Anmeldung mit E-Mail-Adresse fehlgeschlagen',
        'username-was-changed': 'Ihr Benutzername wurde geändert. Sie sollten eine dazugehörige E-Mail erhalten haben. Falls nicht, kontaktieren Sie uns bitte.',
        'username-not-existing': 'Der Benutzername existiert nicht. Sofern Sie noch kein Konto für den persönlichen Bereich besitzen, erstellen Sie bitte eins. Danach können Sie dies wieder mit dem Community-Forum verknüpfen.',
        'password-incorrect': 'Das Passwort ist fehlerhaft. Sofern Sie noch kein Konto für den persönlichen Bereich besitzen, erstellen Sie bitte eins. Danach können Sie dies wieder mit dem Community-Forum verknüpfen.',
        'username-missing': 'Bitte geben Sie einen Benutzernamen ein',
        'username-invalid': 'Der eingegebene Benutzername ist ungültig. Er enthält wahrscheinlich Leerzeichen. Sofern Sie noch kein Konto für den persönlichen Bereich besitzen, erstellen Sie bitte eins. Danach können Sie dies wieder mit dem Community-Forum verknüpfen.',
        'password-missing': 'Bitte geben Sie ein Passwort ein',
        'forum-link': '/konto/forum',
        'forgot-password-link': '/konto/passwort-vergessen',
        'register-link': '/konto/registrieren'
    }
};



let cognitoUser = null;



const _ = (key) => {
    return translate(resources, language, key)
}



const showNotification = (message, explanation) => {
    const notificationDialog = document.getElementById('notification-dialog');
    if (notificationDialog) {
        notificationDialog.showNotification(message, explanation);
    }

    console.log(message, explanation);
}



const showError = () => {
    const notificationDialog = document.getElementById('notification-dialog');
    if (notificationDialog) {
        notificationDialog.showError(message, explanation);
    }

    console.error(message, explanation);
}



const login = () => {
    const loginUser = (userName, password, emailCheck = true) => {
        const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
            Username: userName,
            Password: password
        });
        const userData = {
            Username: userName,
            Pool: userPool
        };
        cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (result) => {
                location.reload();
            },
            onFailure: (error) => {
                switch (error.name) {
                    case 'UserNotFoundException':
                        if (!emailCheck || !userName.includes('@')) {
                            showNotification(_('login-failed'), _('username-not-existing'));
                        }
                        else {
                            makeLambdaRequest('account/login-mail-check', 'GET', {email: userName}, null, false).then(
                                (result) => {
                                    if (result.username) {
                                        loginUser(result.username, password, false);
                                    }
                                    else if (result.licenseExists) {
                                        document.getElementById('register-with-email-dialog').show();
                                    }
                                    else {
                                        showNotification(_('login-failed'), _('username-not-existing'));
                                    }
                                }
                            )
                        }
                        break;
    
                    case 'NotAuthorizedException':
                        showNotification(_('login-failed'), _('password-incorrect'));
                        break;
    
                    case 'UserNotConfirmedException': {
                        const dialog = document.getElementById('send-activation-again-dialog');
                        // As the login page is not rerendered when the cognitoUser is updated, we need to set it manually
                        dialog.cognitoUser = cognitoUser;
                        dialog.show();
                        break;
                    }
    
                    default:
                        showError(_('login-failed'), error);
                        break;
                }
            }
        });
    };

    const userName = document.getElementById('username').value.trim();
    const password = document.getElementById('password').value;

    if (userName === '') {
        showNotification(_('username-missing'));
        return;
    }

    if (/\s/.test(userName)) {
        showNotification(_('username-invalid'));
        return;
    }

    if (password === '') {
        showNotification(_('password-missing'));
        return;
    }

    loginUser(userName, password);
}



const onKeyPress = (event) => {
    if (event.key === 'Enter') {
        login();
    }
}



const submit = (event) => {
    event.preventDefault();

    new FormData(document.getElementById('form'));
}



const generateForgotPassword = () => {
    return () => {
        let target = location.protocol + '//' + location.host + _('forgot-password-link');

        if (['/konto/forum', '/account/forum'].includes(location.pathname)) {
            const parameters = querystring.parse(location.search.substr(1));
            target += `?forward=forum&sso=${parameters.sso}&sig=${parameters.sig}`;
        }

        location.replace(target);
    }
}



const generateRegister = (email) => {
    return () => {
        let target = location.protocol + '//' + location.host + _('register-link');
        if (email) {
            target += '?email=' + encodeURIComponent(document.getElementById('username').value);
        };

        if (['/konto/forum', '/account/forum'].includes(location.pathname)) {
            const parameters = querystring.parse(location.search.substr(1));
            if (email) {
                target += '&';
            }
            else {
                target += '?';
            }
            target += `forward=forum&sso=${parameters.sso}&sig=${parameters.sig}`;
        }

        location.replace(target);
    }
}



export const getLoginPageHTML = (element) => {
    // Don't need to include a notification dialog here, account-body has one by itself
    return html`
        <style>
            ${commonStyles}

            .white {
                color: white;
            }

            .description-text {
                padding-bottom: 8px;
                font-size: 14px;
            }
    
            .mdl-textfield {
                width: 100% !important;
            }

            .mdl-textfield__input {
                color: #ffffff !important;    
                border-bottom-color: #808080 !important;      
            }
            
            .mdl-textfield__label {
                color: #808080 !important;
                font-weight: 400;
                font-size: 16px;
            }
    
            .mdl-textfield--floating-label.is-focused .mdl-textfield__label {
                color: lightgrey;
            }
    
            .mdl-textfield__label:after {
                background-color: lightgrey !important;
            }

            .mdl-textfield__input {
                box-shadow: none !important;
            }

            /* Resetting consequence style */
            input {
                max-width: inherit !important;
            }

            input:focus.mdl-textfield__input {
                box-shadow: none !important;
            }

            label {
                margin-bottom: 0 !important;
            }
        </style>

        <link rel="stylesheet" href="/mdl/material.min.css">

        <send-activation-again-dialog id="send-activation-again-dialog" .cognitoUser=${cognitoUser}></send-activation-again-dialog>

        <mwc-dialog id="register-with-email-dialog" class="left-column thin-dialog" scrimClickAction="">
            <div class="side-margin top-text">${_('license-without-account')}</div>
            <mwc-button slot="secondaryAction" dialogAction="cancel">${_('cancel')}</mwc-button>
            <mwc-button slot="primaryAction" @click=${generateRegister(true)}>${_('register')}</mwc-button>
        </mwc-dialog>

        <form id="login-form" class="center-column" @submit=${submit} @formdata=${login}>
            <symcon-card hidePrimary>
                <div slot="title" class="white description-text">${_('login-description')}</div>
                <div id="username-div" slot="title" class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                    <input id="username" class="mdl-textfield__input" type="text" @keypress=${onKeyPress} autocomplete="username">
                    <label class="mdl-textfield__label" for="username">${_('username')}</label>
                </div>
                <div id="password-div" slot="title" class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                    <input id="password" class="mdl-textfield__input" type="password" @keypress=${onKeyPress} autocomplete="current-password">
                    <label class="mdl-textfield__label" for="username">${_('password')}</label>
                </div>
                <mwc-button slot="actions" label=${_('forgot-password')} @click=${generateForgotPassword()}></mwc-button>
                <mwc-button slot="actions" label=${_('register')} @click=${generateRegister(false)}></mwc-button>
                <mwc-button slot="actions" label=${_('login')} type="submit" @click=${login}></mwc-button>
            </symcon-card>
        </form>
    `;
}