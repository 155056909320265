import { LitElement, html } from 'lit-element';
import {Octokit} from '@octokit/rest';
import '@material/mwc-button';
import '@material/mwc-textfield';

import {TranslateMixin} from '../../../mixins/translate-mixin';
import {UserPoolMixin} from '../../../mixins/user-pool-mixin';
import {CommonStyles} from '../../../mixins/common-styles';
import {LambdaRequestMixin} from '../../../mixins/lambda-request-mixin';
import {ShowErrorMixin} from '../../../mixins/show-error-mixin';
import '../../../components/symcon-card';
import '../../../components/loading-card';
import './project-display';

class Builds extends ShowErrorMixin(LambdaRequestMixin(CommonStyles(UserPoolMixin(TranslateMixin(LitElement))))) {

    render() {
        return html`
            <div class="outer-container">
                ${(this._octokit ? html`
                    <div class="center-column">
                        ${this.projects.map((project) => {
                            return html`<project-display .project=${project} .octokit=${this._octokit}></project-display>`;
                        })}
                    </div>
                ` : html`
                    <loading-card></loading-card>
                `)}
            </div>
        `;
    }



    static get properties() {
        return {
            _reviewUser: {
                type: Boolean
            },

            _octokit: {
                type: Object
            },

            projects: {
                type: Array
            },

            resources: {
                type: Object
            }
        };
    }



    constructor() {
        super();

        this.projects = ['core', 'visualization', 'webfront', 'workflows', 'console', 'console-pro', 'symupd', 'build', 'site'];
        this._octokit = null;
        this.resources = {
            'en': {
                'symcon-employees-only': 'This page can only be accessed by Symcon employees',
                'could-not-get-access-token': 'Could not get Access Token',
                'no-access-token': 'No access token set. Please configure an Access Token in the settings page'
            },
            'de': {
                'symcon-employees-only': 'Nur Symcon-Mitarbeiter können auf diese Seite zugreifen',
                'could-not-get-access-token': 'Konnte Access Token nicht abfragen',
                'no-access-token': 'Kein Access Token gesetzt. Bitte konfigurieren Sie einen Access Token auf der Einstellungs-Seite'
            }
        }
    }



    firstUpdated() {
        this.makeLambdaRequest('account/oauth-token', 'GET', {provider: 'github'}).then(
            (accessToken) => {
                if (accessToken) {
                    this._octokit = new Octokit({ auth: accessToken });
                }
                else {
                    this._showNotification(this._('no-access-token'));
                }
            },
            (error) => {
                this._showError(this._('could-not-get-access-token'), error);
            }
        );
    }
}

customElements.define('builds-page', Builds);
