import {LitElement, html} from 'lit-element';

const querystring = require('querystring');

import {UserPoolMixin} from '../../mixins/user-pool-mixin';
import {CommonStyles} from '../../mixins/common-styles';
import '../../components/loading-backdrop';
import '../../components/notification-dialog';
import '../../components/symcon-card';
import {ShowErrorMixin} from "../../mixins/show-error-mixin";
import {TranslateMixin} from "../../mixins/translate-mixin";
import {LambdaRequestMixin} from "../../mixins/lambda-request-mixin";

class ForumLinkMailPage extends CommonStyles(LambdaRequestMixin(TranslateMixin(ShowErrorMixin(UserPoolMixin(LitElement))))) {

    render() {
        const getContent = () => {
            if (!this._userLoggedIn) {
                return html`
                    <symcon-card class="regular-card text-card">
                        <div class="regular-text">${this._('need-to-login')}</div>
                    </symcon-card>
                `;
            }
            else if (this._error) {
                return html`
                    <symcon-card class="regular-card text-card">
                        <div class="regular-text">${this._('error')}</div>
                    </symcon-card>
                `;
            }
            else {
                return html`
                    <symcon-card class="regular-card text-card">
                        <div class="regular-text">${this._('linking-mail')}</div>
                    </symcon-card>
                `;
            }
        }

        return html`
            <notification-dialog id="notification-dialog"></notification-dialog>
            
            <div class="outer-container">
                <div class="card-container">
                    ${getContent()}
                </div>
            </div>
        `;
    }



    static get properties() {
        return {
            _error: {
                type: Boolean
            },
            
            resources: {
                type: Object
            }
        };
    }



    constructor() {
        super();

        this._error = false;
        this.resources = {
            en: {
                'need-to-login': 'You need to be logged into your account to confirm your email',
                'linking-mail': 'Your old forum account is being linked to your current account',
                'error': 'Something went wrong while confirming the email',
                'could-not-confirm-mail': 'Could not confirm mail'
            },
            de: {
                'need-to-login': 'Sie müssen eingeloggt sein um Ihre E-Mail zu bestätigen',
                'linking-mail': 'Ihr altes Forenkonto wird mit Ihrem aktuellen Konto verküpft',
                'error': 'Während der Bestätigung Ihrer E-Mail ist ein Fehler aufgetreten',
                'could-not-confirm-mail': 'Konnte E-Mail nicht bestätigen'
            }
        }
    }
    
    
    
    firstUpdated() {        
        const parameters = querystring.parse(location.search.substr(1));
        if (parameters.code && this._userLoggedIn) {
            this.makeLambdaRequest('account/forum-confirm-mail', 'GET', {code: parameters.code}).then(
                () => {
                    location.replace('//community.symcon.de');
                },
                (error) => {
                    this.set('_error', true);
                    this._showError(this._('could-not-confirm-mail'), error);
                }
            );
        }
    }
}

customElements.define('forum-link-mail-page', ForumLinkMailPage);
