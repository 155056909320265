export const currentTermsVersion = 3;

export const TermsConstants = (superClass => {
    class TermsConstants extends superClass {

        static get properties() {
            return {
                CURRENT_TERMS_VERSION: {
                    type: Number
                }
            };
        }

        constructor() {
            super();

            this.CURRENT_TERMS_VERSION = currentTermsVersion;
        }
    }

    return TermsConstants;
});
