import {LitElement, html} from 'lit-element';
import '@material/mwc-textfield';
import '@material/mwc-button';

const querystring = require('querystring');

import {CommonStyles} from '../../mixins/common-styles';
import '../../components/loading-backdrop';
import '../../components/notification-dialog';
import '../../components/symcon-card';
import {ShowErrorMixin} from "../../mixins/show-error-mixin";
import {TranslateMixin} from "../../mixins/translate-mixin";
import {LambdaRequestMixin} from '../../mixins/lambda-request-mixin';

class TestInvitation extends LambdaRequestMixin(CommonStyles(TranslateMixin(ShowErrorMixin(LitElement)))) {

    render() {
        return html`      
            <notification-dialog id="notification-dialog"></notification-dialog>
            
            <div class="outer-container">
                <div class="card-container">
                    <symcon-card class="regular-card">
                        <loading-backdrop ?hidden=${!this._loading}></loading-backdrop>
                        <div class="right-column side-margin" ?hidden=${this._joinConfirmed}>
                            <div class="regular-text top-text">${this._('join-test-description', 'name', this._moduleName)}</div>
                            <mwc-button class="action-buttons" @click=${this._join}>${this._('join-test')}</mwc-button>
                        </div>
                        <div class="right-column side-margin" ?hidden=${!this._joinConfirmed}>
                            <div class="regular-text top-text">${this._('join-test-confirmation', 'name', this._moduleName)}</div>
                        </div>
                    </symcon-card>
                </div>
            </div>
        `;
    }



    static get properties() {
        return {
            _moduleName: {
                type: String
            },

            _loading: {
                type: Boolean
            },

            _joinConfirmed: {
                type: Boolean
            },

            _code: {
                type: String
            },

            _bundle: {
                type: String
            },

            resources: {
                type: Object
            }
        };
    }



    constructor() {
        super();

        this._moduleName = '';
        this._loading = true;
        this._joinConfirmed = false;
        this._code = '';
        this.resources = {
            en: {
                'join-test': 'Join Test',
                'join-test-description': 'You have been invited to the internal testing channel of the module {name}. Click "Join Test" to receive access to the testing channel.',
                'join-test-confirmation': 'You have joined the testing channel of the module {name}. It will now be available in the Module Store for all your registered licenses.',
                'parameters-not-valid': 'The parameters of this call are not valid. Did you maybe only copy part of a link that was sent to you?',
                'could-not-get-module-name': 'Could not get module name',
                'could-not-join-test': 'Could not join test'
            },
            de: {
                'join-test': 'Test beitreten',
                'join-test-description': 'Sie wurden zum internen Testkanal des Moduls {name} eingeladen. Klicken Sie auf "Test beitreten" um Zugriff zum Testkanal zu erhalten.',
                'join-test-confirmation': 'Sie sind dem Testing-Kanal des Moduls {name} beigetreten. Er wird nun für all Ihre registrierten Lizenzen im Module Store zur Verfügung stehen.',
                'parameters-not-valid': 'Die Parameter Ihres Aufrufs sind nicht gültig. Haben Sie vielleicht nur einen Teil eines Links kopiert, welchen Ihnen geschickt wurde?',
                'could-not-get-module-name': 'Konnte Modulnamen nicht abfragen',
                'could-not-join-test': 'Konnte Test nicht beitreten'
            }
        };
    }



    _join() {
        this._loading = true;
        this.makeLambdaRequest('publish/testing-code', 'POST', {
            code: this._code,
            bundle: this._bundle
        }).then(
            () => {
                this._joinConfirmed = true;
            },
            (error) => {
                this._showError(this._('could-not-join-test'), error);
            }
        ).finally(() => {
            this._loading = false;
        });
    }
    
    
    
    firstUpdated() {
        const parameters = querystring.parse(location.search.substr(1));

        if (!parameters || !parameters.code || !parameters.bundle) {
            this._showError(this._('parameters-not-valid'));
            return;
        }

        this._code = parameters.code;
        this._bundle = parameters.bundle;
        this.makeLambdaRequest('publish/test-module-name', 'GET', {
            code: this._code,
            bundle: this._bundle
        }).then(
            (result) => {
                let localization = result.find((localization) => {
                    return localization.language === this.language;
                });

                if (!localization) {
                    localization = result.find((localization) => {
                        return localization.language === 'en';
                    });
                }

                if (!localization) {
                    localization = result[0];
                }

                this._moduleName = localization.name;
                this._loading = false;
            },
            (error) => {
                this._showError(this._('could-not-get-module-name'), error)
            }
        );
    }
}

customElements.define('test-invitation', TestInvitation);
