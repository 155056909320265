import {LitElement, html, css} from 'lit-element';
import '@material/mwc-button';
import '@material/mwc-textfield';
import '@material/mwc-dialog';

const querystring = require('querystring');

import {UserPoolMixin} from '../../mixins/user-pool-mixin';
import {CommonStyles} from '../../mixins/common-styles';
import '../../components/loading-backdrop';
import '../../components/symcon-card';
import '../../components/notification-dialog';
import {ShowErrorMixin} from "../../mixins/show-error-mixin";
import {TranslateMixin} from "../../mixins/translate-mixin";
import {LambdaRequestMixin} from "../../mixins/lambda-request-mixin";

class ForumLoginPage extends CommonStyles(LambdaRequestMixin(TranslateMixin(ShowErrorMixin(UserPoolMixin(LitElement))))) {

    static get styles() {
        return [ super.styles, css`
            .pre {
                white-space: pre-line;
            }
        `];
    }

    render() {
        const getContent = () => {
            if (!this._userLoggedIn) {
                return html`
                    <symcon-card>
                        <div class="regular-text">${this._('need-to-login')}</div>
                    </symcon-card>
                `;
            }
            else if (this._active) {
                return html`
                    <symcon-card>
                        <div class="regular-text">${this._('forwarding-to-forum')}</div>
                    </symcon-card>
                `;
            }
            else if (this._mailSent) {
                return html`
                    <symcon-card>
                        <loading-backdrop ?hidden=${!this._loading}></loading-backdrop>
                        <div class="right-column side-margin">
                            <div class="regular-text">${this._('email-sent')}</div>
                            <mwc-textfield id="code-input" class="full-width" label=${this._('confirmation-code')} @keypress=${this._handleConfirmCodePress}></mwc-textfield>
                            <mwc-button class="right-element action-buttons" @click=${this._confirmCode}>${this._('confirm-code')}</mwc-button>
                        </div>
                    </symcon-card>
                `;
            }
            else if (this._selectLinkingOption) {
                return html`
                    <symcon-card>
                        <loading-backdrop ?hidden=${!this._loading}></loading-backdrop>
                        <div class="right-column side-margin">
                            <div class="regular-text top-text">${this._('link-account-description')}</div>
                            <mwc-textfield id="email-input" class="full-width" label=${this._('e-mail')} @keypress=${this._handleLinkingPress}></mwc-textfield>
                            <mwc-button class="right-element action-buttons" @click=${this._sendLinkingMail}>${this._('send-email')}</mwc-button>
                        </div>
                    </symcon-card>
                `;
            }
            else if (this._linking) {
                return html`
                    <symcon-card>
                        <loading-backdrop ?hidden=${!this._loading}></loading-backdrop>
                        <div class="right-column side-margin">
                            <div class="regular-text top-text pre">${this._('currently-linking-description', 'email', this._linkingEmail)}</div>
                            <div class="center-row right-element">
                                <mwc-button class="action-buttons" @click=${this._createNewAccount}>${this._('continue-without-legacy')}</mwc-button>
                                <mwc-button class="action-buttons" @click=${this._linkToLegacy}>${this._('use-another-email')}</mwc-button>
                                <mwc-button class="action-buttons" @click=${this._sendLinkingMailAgain}>${this._('send-mail-again')}</mwc-button>
                                <mwc-button class="action-buttons" @click=${this._enterCode}>${this._('enter-code')}</mwc-button>
                            </div>
                        </div>
                    </symcon-card>
                `;
            }
            else {
                return html`
                    <symcon-card>
                        <loading-backdrop ?hidden=${!this._loading}></loading-backdrop>
                        <div class="right-column side-margin">
                            <div class="regular-text top-text">${this._('create-new-account-description')}</div>
                            <div class="center-row right-element">
                                <mwc-button class="action-buttons" @click=${this._linkToLegacy}>${this._('link-to-legacy-account')}</mwc-button>
                                <mwc-button class="action-buttons" @click=${/*this._createNewAccount*/ () => { this.shadowRoot.getElementById('link-check').show()}}>${this._('no-legacy-account')}</mwc-button>
                            </div>
                        </div>
                    </symcon-card>
                `
            }
        }

        return html`      
            <notification-dialog id="notification-dialog"></notification-dialog>

            <mwc-dialog id="link-check" scrimClickAction="">
                <div>${this._('link-check')}</div>
                <mwc-button slot="secondaryAction" dialogAction="cancel">${this._('no')}</mwc-button>
                <mwc-button slot="primaryAction" dialogAction="confirm" @click=${this._createNewAccount}>${this._('yes')}</mwc-button>
            </mwc-dialog>
            
            <div class="outer-container">
                ${getContent()}
            </div>
        `;
    }



    static get properties() {
        return {
            _loading: {
                type: Boolean
            },

            _ssoSecret: {
                type: String
            },

            _parameters: {
                type: Object
            },

            _linkingEmail: {
                type: String
            },

            _email: {
                type: String
            },

            _mailSent: {
                type: Boolean
            },

            _active: {
                type: Boolean
            },

            // User is currently in the process of linking: An email was sent but not confirmed yet
            _linking: {
                type: Boolean
            },

            // User clicked on "I want to link to a legacy account" just now
            _selectLinkingOption: {
                type: Boolean
            },
            
            resources: {
                type: Object
            }
        };
    }



    constructor() {
        super();

        this._loading = false;
        this._ssoSecret = '';
        this._parameters = null;
        this._linkingEmail = '';
        this._email = '';
        this._mailSent = false;
        this._active = true;
        // User is currently in the process of linking: An email was sent but not confirmed yet
        this._linking = false;
        // User clicked on "I want to link to a legacy account" just now
        this._selectLinkingOption = false;
        this.resources =  {
            en: {
                'need-to-login': 'Please login to the personal area before retrying to login at the forum. This should never be visible as this site should be inaccessible in that case. Please inform us how you got here!',
                'forwarding-to-forum': 'Please wait shortly, you will be logged into the community forum',
                'could-not-login-to-community': 'Could not log in to community',
                'create-new-account-description': 'You have not used the community forum before. If you have used the previous forum, you can link your account to your old forum account. Do you want to do this? Afterwards, linking is not possible any more!',
                'link-to-legacy-account': 'Link to old forum account',
                'no-legacy-account': 'Create new forum account',
                'link-account-description': 'Please enter the e-mail of your old forum account. We will send you an activation code. Please enter it in the next step to verify that it is your account.',
                'e-mail': 'E-Mail',
                'send-email': 'Send E-Mail',
                'email-sent': 'An e-mail with an activation code was sent. Please check your inbox and enter the code from the e-mail here.',
                'confirmation-code': 'Activation Code',
                'confirm-code': 'Confirm Code',
                'could-not-send-mail': 'Could not send mail',
                'currently-linking-description': 'You have received an activation code for linking your old forum account to the e-mail address {email}.\n\nIf you have received the mail, please click "Enter Code". If you want to resend the mail with the activation code, select another e-mail, or just want to create a new forum account without linking to the old account, click the corresponding button.',
                'continue-without-legacy': 'Create new forum account without linking',
                'use-another-email': 'Use another e-mail address',
                'send-mail-again': 'Resend Activation code',
                'enter-code': 'Enter Activation Code',
                'login-address': 'account/login.html',
                'sso-token-missing': 'SSO Token is missing',
                'link-check': 'If you create a new forum account, it will not be possible to link it with an old forum account later on. Are you sure you want to create a new forum account?',
                'yes': 'Yes',
                'no': 'No',
                'could-not-confirm-mail': 'Could not confirm mail'
            },
            de: {
                'need-to-login': 'Bitte loggen Sie sich im persönlichen Bereich ein bevor Sie sich erneut im Forum einloggen. Diese Seite sollte in diesem Falle allerdings nicht erreichbar sein. Bitte teilen Sie uns mit, wie Sie hierhin gelangt sind!',
                'forwarding-to-forum': 'Bitte warten Sie kurz, Sie werden ins Community-Forum eingeloggt',
                'could-not-login-to-community': 'Konnte nicht in Community einloggen',
                'create-new-account-description': 'Sie haben das Community Forum bisher nicht verwendet. Wenn Sie das alte Forum verwendet haben, können Sie Ihr Konto mit dem alten Forenkonto verknüpfen. Wollen Sie das tun? Eine Verknüpfung ist im Nachhinein nicht mehr möglich!',
                'link-to-legacy-account': 'Mit altem Forenkonto verknüpfen',
                'no-legacy-account': 'Neues Forenkonto erstellen',
                'link-account-description': 'Bitte geben Sie die E-Mail-Adresse Ihres alten Forenkontos ein. Wir werden Ihnen dorthin einen Aktivierungsscode schicken. Geben Sie diesen im nächsten Schritt ein um zu bestätigen, dass es sich um Ihr Konto handelt.',
                'e-mail': 'E-Mail',
                'send-email': 'E-Mail abschicken',
                'email-sent': 'Die E-Mail mit dem Aktivierungsscode wurde verschickt. Bitte prüfen Sie Ihren Posteingang und geben den Code aus der E-Mail hier ein.',
                'confirmation-code': 'Aktivierungscode',
                'confirm-code': 'Code bestätigen',
                'could-not-send-mail': 'Konnte E-Mail nicht verschicken',
                'currently-linking-description': 'Sie haben bereits eine E-Mail mit einem Aktivierungscode erhalten um Ihr altes Forenkonto zu verknüpfen. Die E-Mail wurde an {email} verschickt.\n\nHaben Sie diesen erhalten, klicken Sie bitte auf "Code eingeben". Wenn Sie die E-Mail mit dem Aktivierungscode erneut verschicken wollen, ein andere E-Mail-Adresse verwenden wollen oder einfach ein neues Forenkonto ohne eine Verknüpfung erstellen wollen, klicken Sie auf den entsprechenden Button.',
                'continue-without-legacy': 'Neues Forenkonto ohne Verknüpfung erstellen',
                'use-another-email': 'Andere E-Mail-Adresse verwenden',
                'send-mail-again': 'Aktivierungscode erneut verschicken',
                'enter-code': 'Aktivierungscode eingeben',
                'login-address': 'konto/anmelden.html',
                'sso-token-missing': 'SSO Token fehlt',
                'link-check': 'Wenn Sie ein neues Forenkonto erstellen, ist es nicht möglich, dies im Nachhinein mit einem alten Forenkonto zu verknüpfen. Sind Sie sicher, dass Sie ein neues Forenkonto erstellen wollen?',
                'yes': 'Ja',
                'no': 'Nein',
                'could-not-confirm-mail': 'Konnte E-Mail nicht bestätigen'
            }
        };
    }



    _createNewAccount() {
        this._loading = true;
        this.makeLambdaRequest('account/forum-login', 'GET', {sso: this._sso, sig: this._sig, createNew: true}).then(
            (result) => {
                location.replace(result.loginURL);
            },
            (error) => {
                this._loading = false;
                this._showError(this._('could-not-login-to-community'), error);
            }
        );
    }



    _linkToLegacy() {
        this._selectLinkingOption = true;
    }



    _sendActivationCode(email) {
        this._loading = true;
        this.makeLambdaRequest('account/forum-link-mail', 'GET', {mail: email}).then(
            () => {
                this._mailSent = true;
            },
            (error) => {
                this._showError(this._('could-not-send-mail'), error);
            }
        ).finally(() => {
            this._loading = false;
        });
    }



    _handleConfirmCodePress(event) {
        if (event.key === 'Enter') {
            this._confirmCode();
        }
    }



    _confirmCode() {
        const code = this.shadowRoot.getElementById('code-input').value;
        this._loading = true;
        this.makeLambdaRequest('account/forum-confirm-mail', 'GET', {code: code, sso: this._sso, sig: this._sig}).then(
            (loginURL) => {
                location.replace(loginURL);
            },
            (error) => {
                // TODO: Nice error messages
                this._showError(this._('could-not-confirm-mail'), error);
                this._loading = false;
            }
        );

    }



    _handleLinkingPress(event) {
        if (event.key === 'Enter') {
            this._sendLinkingMail();
        }
    }



    _sendLinkingMail() {
        const email = this.shadowRoot.getElementById('email-input').value;
        this._sendActivationCode(email);
    }



    _sendLinkingMailAgain() {
        this._sendActivationCode(this._linkingEmail);
    }



    _enterCode() {
        this._mailSent = true;
    }
    
    
    
    firstUpdated() {
        const parameters = querystring.parse(location.search.substr(1));
        if (parameters.sso && this._userLoggedIn) {
            this.makeLambdaRequest('account/forum-login', 'GET', JSON.parse(JSON.stringify(parameters))).then(
                (result) => {
                    if (result.active) {
                        location.replace(result.loginURL);
                    }
                    else {
                        if (result.linking) {
                            this._linkingEmail = result.linkingEmail;
                        }
                        this._active = result.active;
                        this._linking = result.linking;
                        this._sso = parameters.sso;
                        this._sig = parameters.sig;
                    }
                },
                (error) => {
                    this._showError(this._('could-not-login-to-community'), error);
                }
            );
        }
        else if (!parameters.sso) {
            this._showError(this._('could-not-login-to-community'), this._('sso-token-missing'));
        }
        // TODO: With the rebuild, this should not triggerable
        else if (!this._userLoggedIn) {
            let href = location.protocol + '//' + location.host;
            if (href[href.length - 1] !== '/') {
                href += '/';
            }
            location.replace(href + this._('login-address') + `?forward=forum&sso=${encodeURIComponent(parameters.sso)}&sig=${encodeURIComponent(parameters.sig)}`);
        }
    }
}

customElements.define('forum-login-page', ForumLoginPage);
