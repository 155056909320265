import {render, html} from 'lit-html';

import './components/notification-dialog';
import './pages/licenses-page';
import {getLoginPageHTML} from './pages/login-page';
import './pages/index-page';
import './pages/account/register-page';
import './pages/account/forgot-password-page';
import './pages/account/account-settings-page';
import './pages/account/forum-login-page';
import './pages/account/forum-link-mail-page';
import './pages/account/test-invitation';
import './pages/developer/modules-list';
import './pages/developer/reviews-list';
import './pages/developer/module';
import './pages/developer/terms-and-conditions';
import './pages/developer/builds/builds';
import './pages/developer/releases';
import {getUserPool} from "./mixins/user-pool-mixin";
import {currentTermsVersion} from "./mixins/terms-constants";
import {makeLambdaRequest} from "./mixins/lambda-request-mixin";

const userPool = getUserPool();

export const hideMenu = () => {
    document.getElementById('menu').hidden = true;
    document.getElementById('mobile-nav-open').hidden = true;
    document.getElementById('mobile-nav-close').hidden = true;
}

const updatePage = async() => {
    const getElement = async() => {

        const logout = () => {
            const cognitoUser = userPool.getCurrentUser();
            if (cognitoUser !== null) {
                cognitoUser.signOut();
                updatePage();
            }
        }
        let currentUser = userPool.getCurrentUser();

        const getLoggedInElement = (splitPath) => {
            if (splitPath.length === 0) {
                return html`<index-page @logout=${logout}></index-page>`;
            }

            switch (splitPath[0]) {
                case 'lizenzen':
                case 'licenses': {
                    return html`<licenses-page></licenses-page>`;
                }

                case 'account':
                case 'konto':
                    switch (splitPath[1]) {
                        case 'login':
                        case 'anmelden':
                            // Go to the index-page so old links are still working properly
                            return html`<index-page @logout=${logout}></index-page>`;

                        case 'einstellungen':
                        case 'settings':
                            return html`<account-settings-page></account-settings-page>`;

                        case 'forum':
                            return html`<forum-login-page></forum-login-page>`;

                        case 'verknuepfe-forum':
                        case 'link-forum':
                            return html`<forum-link-mail-page></forum-link-mail-page>`;

                        case 'test-einladung':
                        case 'test-invitation':
                            return html`<test-invitation></test-invitation>`;
                    }
                    break;

                case 'entwickler':
                case 'developer':
                    switch (splitPath[1]) {
                        case 'module':
                        case 'modules':
                            switch (splitPath[2]) {
                                case undefined:
                                    return html`<my-modules-list></my-modules-list>`;

                                default:
                                    return html`<my-module bundleID=${splitPath[2].replace(/-/g, '.')}></my-module>`;
                            }

                        case 'reviews':
                            return html`<reviews-list></reviews-list>`;

                        case 'builds':
                            return html`<builds-page></builds-page>`;

                        case 'releases':
                            return html`<releases-page></releases-page>`;
                    }
                    break;
            }

            return html`<div>TODO: Fancy 404</div>`;
        };

        let splitPath = window.location.pathname.split('/');

        // Remove "empty" elements from splitPath
        for (let i = splitPath.length - 1; i >= 0; i--) {
            if (splitPath[i] === '') {
                splitPath.splice(i, 1);
            }
        }

        if (['/konto/forum', '/account/forum', '/konto/verknuepfe-forum', '/account/link-forum'].includes(location.pathname)) {
            hideMenu();
        }

        if (currentUser) {
            // Check if user is properly logged in
            try {
                const session = await new Promise((resolve, reject) => {
                    currentUser.getSession((error, session) => {
                        if (error) {
                            reject(error);
                        }
                        else {
                            resolve(session);
                        }
                    })
                });
            }
            catch (error) {
                currentUser.signOut();
                currentUser = null;
            }
        }

        // Pages that are accessible for users that are not logged in, otherwise fall through and check if user is logged in
        switch (splitPath[0]) {
            case 'account':
            case 'konto':
                switch (splitPath[1]) {
                    case 'register':
                    case 'registrieren':
                        return html`<register-page></register-page>`;

                    case 'passwort-vergessen':
                    case 'forgot-password':
                        return html`<forgot-password-page></forgot-password-page>`;

                    case 'confirm':
                    case 'bestaetigen':
                        return html`<register-page registerDone></register-page>`;

                    case 'reset-password':
                    case 'passwort-zuruecksetzen':
                        return html`<forgot-password-page passwordSent></forgot-password-page>`;
                }
                break;
        }

        if (currentUser) {
            if (['developer', 'entwickler'].includes(splitPath[0])){
                const acceptedTerms = await makeLambdaRequest('publish/terms', 'GET', {}, currentUser);
                if (acceptedTerms !== currentTermsVersion) {
                    return html`<terms-and-conditions @reload=${updatePage}></terms-and-conditions>`;
                }
                else {
                    return getLoggedInElement(splitPath);
                }
            }
            else {
                return getLoggedInElement(splitPath);
            }
        }
        else {
            return getLoginPageHTML();
        }
    }

    render([html`<notification-dialog id="notification-dialog"></notification-dialog>`, await getElement()], document.getElementById('account-placeholder'));

    if (document.getElementById('username-div')) {
        componentHandler.upgradeElement(document.getElementById('username-div'));
        componentHandler.upgradeElement(document.getElementById('password-div'));
    }
};

window.onload = updatePage;