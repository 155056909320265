import {LitElement, html, css} from 'lit-element';
import '@material/mwc-dialog';
import '@material/mwc-button';
import '@material/mwc-icon';
import '@material/mwc-select';
import '@material/mwc-list/mwc-list-item';
import '@material/mwc-textfield';
import '@material/mwc-radio';
import '@material/mwc-formfield';
import '@material/mwc-textarea';
import '@material/mwc-icon-button';

import {UserPoolMixin} from "../../mixins/user-pool-mixin";
import '../../components/account-selection';
import '../../components/loading-backdrop';
import '../../components/loading-card';
import '../../components/notification-dialog';
import '../../components/symcon-expansion-panel';
import '../../components/symcon-card';
import {CommonStyles} from '../../mixins/common-styles';
import {LambdaRequestMixin} from "../../mixins/lambda-request-mixin";
import { XMLRequestMixin } from '../../mixins/xml-request-mixin';
import {ShowErrorMixin} from "../../mixins/show-error-mixin";
import {TranslateMixin} from "../../mixins/translate-mixin";
import {DynamicTextareaResizeMixin} from '../../mixins/dynamic-textarea-resize-mixin';
import {OrderReleasesMixin} from '../../mixins/order-releases-mixin'

class ModuleCard extends OrderReleasesMixin(XMLRequestMixin(DynamicTextareaResizeMixin(CommonStyles(TranslateMixin(ShowErrorMixin(LambdaRequestMixin(UserPoolMixin(LitElement)))))))) {

    static get styles() {
        return [super.styles, css`
            :host {
                width: 100%;
            }
            
            #select-commit-dialog {
                --mdc-dialog-min-width: 80vw;
                --mdc-dialog-max-width: 80vw;
                top: 100px;
            }
            
            .select-commit-body {
                max-height: calc(100vh - 240px);
                overflow: auto;
            }

            .select-commit-body>:not(:first-child) {
                margin-top: 20px;
            }
            
            .dialog-expansion-panel {
                --paper-expansion-panel-header: {
                    @apply --expansion-panel-header-style;
                    color: black;
                }
            }
            
            .simple-selection-note {
                max-width: 700px;
                background-color: var(--warning-on-dark);
                border: 5pt solid var(--warning-on-dark);
                border-radius: 5px;
            }
            
            .selection-note-icon {
                width: 48px;
                height: 48px;
                margin-right: 12px;
            }
    
            .remark-icon {
                font-size: 50px;
                margin-right: 10px;
            }
    
            .remark-block {
                border-width: 1pt;
                border-style: solid;
                padding: 10px 30px 10px 18px;
                margin-top: 20px;
            }
    
            .declined-icon {
                color: var(--warning-on-dark);
            }
    
            .declined-block {
                border-color: var(--warning-on-dark);
            }
    
            .accepted-icon {
                color: var(--mdc-theme-primary);
            }
    
            .accepted-block {
                border-color: var(--mdc-theme-primary);
            }
    
            .clone-failed-icon {
                color: var(--error-on-dark);
            }
    
            .clone-failed-block {
                border-color: var(--error-on-dark);
            }

            .localization-panel {
                margin-bottom: 20px;
            }

            .select-commit-button {
                margin-top: 10px;
            }
        `];
    }

    render() {
        const getModuleButtons = () => {
            if (!this.module) {
                return html``;
            }

            switch (this.module.status) {
                case 'uploaded':
                    return html`
                        <mwc-button @click=${this._openRemoveUploadedDialog}>${this._('discard')}</mwc-button>
                        <mwc-button @click=${this._updateModule}>${this._('save')}</mwc-button>
                        <mwc-button @click=${this._publishModule}>${this._('submit')}</mwc-button>
                    `;

                case 'declined':
                    return html`
                        <mwc-button @click=${this._useAsNewTemplate}>${this._('use-as-template-again')}</mwc-button>
                    `;

                case 'review':
                    return html`
                        <mwc-button @click=${this._openRemoveReviewDialog}>${this._('withdraw')}</mwc-button>
                    `;

                case 'released': {
                    const result = [];
                    if (['beta', 'testing'].includes(this.module.channel)) {
                        if (this.releases.find((release) => {
                            const higherChannels = ['stable'];
                            if (this.module.channel === 'testing') {
                                higherChannels.push('beta');
                            }
                            return (higherChannels.includes(release.channel) && (release.status === 'released'));
                        })) {
                            result.push(html`
                                <mwc-button @click=${this._endChannel}>${this._('end-channel-button')}</mwc-button>
                            `);
                        }
                        result.push(html`
                            <mwc-button @click=${this._forwardToStable}>${this._('use-as-template-in-stable')}</mwc-button>
                        `);
                        if (this.module.channel === 'testing') {
                            result.push(html`
                                <mwc-button @click=${this._forwardToBeta}>${this._('use-as-template-in-beta')}</mwc-button>
                            `);
                        }
                    }
                    return result;
                }

                default:
                    return html``;
            }
        };

        const getCategoryDialogContent = () => {
            let result = [];
            for (let availableCategoryID in this.availableCategories) {
                let category = this.availableCategories[availableCategoryID];
                result.push(html`
                    <mwc-formfield label=${this._generateParentPrefix(category.id) + category.name}>
                        <mwc-radio name="new-category" @change=${(event) => { this._newCategory = category.id; }} ?disabled=${this._categoriesInput.includes(category.id)}></mwc-radio>
                    </mwc-formfield>
                `)
            }
            return result;
        }

        const renderSubmissionSettings = () => {
            let result = html``;

            let lowerChannels = ['stable', 'beta', 'testing'];
            const index = lowerChannels.indexOf(this.module.channel);
            lowerChannels = lowerChannels.splice(index + 1, 999);
            for (const lowerChannel of lowerChannels) {
                const orderedReleases = this._orderReleases(this.releases, lowerChannel);
                if (orderedReleases.released && (orderedReleases.released.status === 'released')) {
                    result = html`
                        ${result}
                        <div class="center-row action-buttons between">
                            <div class="accept-text regular-text">${(this.module.channel === 'stable') ? this._('close-lower-channels-stable') : this._('close-lower-channels-beta')}</div>
                            <mwc-switch id="close-lower-channels" ?disabled=${!this._isUploaded()} .selected=${this.module.close_lower_channels}></mwc-switch>
                        </div>
                    `;
                    break;
                }
            }

            if (result.values.length > 0) {
                result = html`
                    <h4 class="text-color">${this._('submission')}</h4>
                    ${result}
                `;
            }

            return result;
        }

        return html`
            <notification-dialog id="notification-dialog"></notification-dialog>

            <mwc-dialog id="remove-localization-dialog" class="left-column thin-dialog" scrimClickAction="">
                <loading-backdrop ?hidden=${!this._loadingDialog}></loading-backdrop>
                <div>${this._('remove-localization-check')}</div>
                <mwc-button slot="secondaryAction" dialogAction="cancel">${this._('no')}</mwc-button>
                <mwc-button slot="primaryAction" dialogAction="confirm" @click=${(event) => {
                    const oldLocalizationsInput = JSON.parse(JSON.stringify(this._localizationsInput));
                    this._localizationsInput.splice(this._localizationIndexToRemove, 1);
                    this.requestUpdate('_localizationsInput', oldLocalizationsInput);
                }}>${this._('yes')}</mwc-button>
            </mwc-dialog>

            <mwc-dialog id="preparing-submission-dialog" class="left-column thin-dialog" scrimClickAction="" hideActions>
                <div class="top-text">${this._('preparing-submission')}</div>
                <loading-card></loading-card>
            </mwc-dialog>

            <mwc-dialog id="remove-uploaded-dialog" class="left-column thin-dialog" scrimClickAction="">
                <loading-backdrop ?hidden=${!this._loadingDialog}></loading-backdrop>
                <div>${this._('remove-uploaded-check')}</div>
                <mwc-button slot="secondaryAction" dialogAction="cancel">${this._('no')}</mwc-button>
                <mwc-button slot="primaryAction" @click=${this._removeUploaded}>${this._('yes')}</mwc-button>
            </mwc-dialog>

            <mwc-dialog id="remove-review-dialog" class="left-column thin-dialog" scrimClickAction="">
                <loading-backdrop ?hidden=${!this._loadingDialog}></loading-backdrop>
                <div>${this._removeReviewText}</div>
                <mwc-button slot="secondaryAction" dialogAction="cancel">${this._('no')}</mwc-button>
                <mwc-button slot="primaryAction" @click=${this._removeReview}>${this._('yes')}</mwc-button>
            </mwc-dialog>

            <mwc-dialog id="add-category-dialog" class="left-column" scrimClickAction="">
                <loading-backdrop ?hidden=${this.availableCategories}></loading-backdrop>
                <div class="left-column">
                    ${getCategoryDialogContent()}
                </div>
                <mwc-button slot="secondaryAction" dialogAction="cancel">${this._('abort')}</mwc-button>
                <mwc-button slot="primaryAction" @click=${this._addCategory}>${this._('select')}</mwc-button>
            </mwc-dialog>

            <mwc-dialog id="add-localization-dialog" class="left-column thin-dialog" scrimClickAction="">
                <div class="left-column">
                    ${this._getAvailableLanguages().map((language) => {
                        return html`
                            <mwc-formfield label=${this._languageToNiceWord(language)}>
                                <mwc-radio name="new-localization" @change=${(event) => { this._newLocalization = language; }}></mwc-radio>
                            </mwc-formfield>
                        `;
                    })}
                </div>
                <mwc-button slot="secondaryAction" dialogAction="cancel">${this._('abort')}</mwc-button>
                <mwc-button slot="primaryAction" @click=${this._addLocalization}>${this._('ok')}</mwc-button>
            </mwc-dialog>

            <mwc-dialog id="select-simple-commit-dialog" class="left-column" scrimClickAction="">
                <loading-backdrop ?hidden=${!this._loadingDialog}></loading-backdrop>
                <div>
                    <div class="simple-selection-note center-row">
                        <mwc-icon class="selection-note-icon">info</mwc-icon>
                        <div class="remaining">${this._('only-simple-selection')}</div>
                    </div>
                </div>
                ${this._branchCommitsSimple.map((branch) => {
                    return html`
                        <mwc-formfield label=${branch.branch}>
                            <mwc-radio name="new-commit" @change=${(event) => { this._simpleCommitSelected = branch.commit; }}></mwc-radio>
                        </mwc-formfield>
                    `;
                })}
                <mwc-button slot="secondaryAction" dialogAction="cancel">${this._('abort')}</mwc-button>
                <mwc-button slot="primaryAction" @click=${this._confirmSimpleCommit}>${this._('select')}</mwc-button>
            </mwc-dialog>
            
            <mwc-dialog id="select-commit-dialog" class="left-column" scrimClickAction="">
                <loading-backdrop ?hidden=${!this._loadingDialog}></loading-backdrop>
                ${this._displayCommitNotFoundHint ? html`
                    <div>${this._('current-commit-could-not-be-detected')}</div>
                ` : html``}
                <div class="select-commit-body">
                    ${this._branches.map((branch) => {
                        return html`
                            <symcon-expansion-panel class="dialog-expansion-panel full-width" header=${branch.branch} ?opened=${branch.opened}>
                                ${branch.commits.map((commit) => {
                                    return html`
                                        <mwc-formfield label=${this._toDate(commit.commit.committer.date) + ': ' + commit.commit.message}>
                                            <mwc-radio name="new-commit" ?checked=${commit.sha === branch.selected} @change=${(event) => {
                                                this._commitSelect = commit.sha;
                                                this._newCommitMessage = commit.commit.message;
                                            }}></mwc-radio>
                                        </mwc-formfield>
                                    `;
                                })}
                                ${branch.showLoadMore ? html`
                                    <mwc-button class="action-buttons right-element" raised @click=${this._generateLoadMoreCommits(branch)}>${this._('load-more')}</mwc-button>
                                ` : html``}
                            </symcon-expansion-panel>
                        `;
                    })}
                </div>
                <mwc-button slot="secondaryAction" dialogAction="cancel">${this._('abort')}</mwc-button>
                <mwc-button slot="primaryAction" dialogAction="confirm" @click=${this._confirmCommit}>${this._('select')}</mwc-button>
            </mwc-dialog>
            
            ${this.module ? html`
                <symcon-card class="regular-card">
                    <div class="side-margin left-column">
                        <loading-backdrop ?hidden=${!this._loading}></loading-backdrop>
                        <h2 class="text-color top-text no-margin">${this._statusToNiceWord(this.module.status)}</h2>
                        <div class="regular-text">${this._statusToDescription(this.module.status)}</div>
                        ${this.module.status !== 'endChannel' ? html`
                            ${this.module.remark ? html`
                                <div class=${'full-width center-row remark-block ' + this._getRemarkClass(this.module.status)}>
                                    <mwc-icon class=${'remark-icon ' + this._getRemarkIconClass(this.module.status)}>${this._getRemarkIcon(this.module.status)}</mwc-icon>
                                    <mwc-textarea class="remaining" label=${this._getRemarkLabel(this.module.status)} value=${this.module.remark} readOnly></mwc-textarea>
                                </div>
                            ` : html``}
                            <h4 class="text-color">${this._('repository-settings')}</h4>
                            <mwc-textfield id="git-url-input" class="full-width" label=${this._('git-url')} ?disabled=${!this._isUploaded()} value=${this._gitURLInput} @change=${(event) => { this._gitURLInput = event.target.value;}}></mwc-textfield>
                            <mwc-textfield id="git-commit-input" class="full-width" label=${this._('git-commit')} ?disabled=${!this._isUploaded()} value=${this._gitCommitInput} @change=${(event) => { this._gitCommitInput = event.target.value;  this._commitMessage = '';}}></mwc-textfield>
                            <mwc-button class="right-element select-commit-button" raised @click=${this._selectCommit} ?hidden=${!this._isUploaded()}>${this._('select-commit-button')}</mwc-button>
                            ${this._commitMessage ? html`
                                <mwc-textarea class="full-width" label=${this._('commit-message')} value=${this._commitMessage} disabled></mwc-textarea>
                            ` : html``}
                            <h4 class="text-color">${this._('localizations')}</h4>
                            ${this._localizationsInput.map((localization) => {
                                return html`
                                    <div class="full-width">
                                        <symcon-expansion-panel class="full-width localization-panel" @open=${this._generateOpenPanelCallback(localization.language)}>
                                            <div slot="header" class="center-row">
                                                <div class="text-color">${this._languageToNiceWord(localization.language)}</div>
                                                ${this._isUploaded() ? html`
                                                    <mwc-icon-button class="text-color" @click=${(event) => {
                                                        this._localizationIndexToRemove = this._localizationsInput.indexOf(localization);
                                                        this.shadowRoot.getElementById('remove-localization-dialog').show();
                                                    }} icon="cancel"></mwc-icon-button>
                                                ` : html``}
                                            </div>
                                            <div class="side-margin left-column full-width">
                                                <mwc-textfield id=${'localization-name-input-' + localization.language} class="full-width" label=${this._('name')} value=${localization.name} @change=${(event) => { localization.name = event.target.value; }} ?disabled=${!this._isUploaded()}></mwc-textfield>
                                                <mwc-textarea id=${'localization-description-input-' + localization.language} class="full-width" label=${this._('description')} value=${localization.description} @change=${(event) => { localization.description = event.target.value; }} ?disabled=${!this._isUploaded()} @input=${this._resizeTextarea}></mwc-textarea>
                                                <mwc-textarea id=${'localization-release-notes-input-' + localization.language} class="full-width" label=${this._('release-notes')} value=${localization.release_notes} @change=${(event) => { localization.release_notes = event.target.value; }} ?disabled=${!this._isUploaded()} @input=${this._resizeTextarea}></mwc-textarea>
                                                <mwc-textarea id=${'localization-documentation-link-input-' + localization.language} class="full-width" label=${this._('documentation-link')} value=${localization.documentation} @change=${(event) => { localization.documentation = event.target.value; }} ?disabled=${!this._isUploaded()} @input=${this._resizeTextarea}></mwc-textarea>
                                            </div>
                                        </symcon-expansion-panel>
                                    </div>
                                `;
                            })}
                            ${(this._localizationsInput.length === 0) ? html`
                                <div class="regular-text">${this._('currently-no-localizations')}</div>
                            ` : html``}
                            ${this._displayAddLocalizations() ? html`
                                <mwc-button class="right-element no-side-margin" raised @click=${this._openAddLocalizationDialog}>${this._('add-localization')}</mwc-button>
                            ` : html``}
                            <h4 class="text-color">${this._('categories')}</h4>
                            <div class="center-row">
                                ${this._categoriesInput.map((categoryID) => {
                                    return html`
                                        <div style="height: 40px; padding-right: 3px;" class="center-row">
                                            <span class="text-color">${this._categoryToName(categoryID)}</span>
                                            <mwc-icon-button class="text-color" @click=${() => {
                                                const oldCategoriesInput = JSON.parse(JSON.stringify(this._categoriesInput));
                                                this._categoriesInput.splice(this._categoriesInput.indexOf(categoryID), 1);
                                                this.requestUpdate('_categoriesInput', oldCategoriesInput);
                                            }} ?hidden=${!this._isUploaded()} icon="cancel"></mwc-icon-button>
                                        </div>
                                    `;
                                })}
                                ${ (this._categoriesInput.length === 0) ? html`
                                    <div class="regular-text">${this._('currently-no-categories')}</div>
                                ` : html``}
                            </div>
                            <mwc-button class="right-element no-side-margin right-element" raised @click=${this._openAddCategoryDialog} ?hidden=${!this._isUploaded()}>${this._('add-category')}</mwc-button>
                            ${renderSubmissionSettings()}
                            <div class="center-row right-element action-buttons">
                                ${getModuleButtons()}
                            </div>
                        ` : html``}
                    </div>
                </symcon-card>
            ` : html`
                <loading-card></loading-card>
            `}
        `;
    }



    static get properties() {
        return {
            AVAILABLE_LANGUAGES: {
                type: Array
            },
            
            _branches: {
                type: Array
            },

            _branchCommitsSimple: {
                type: Array
            },
            
            _gitURLInput: {
                type: String
            },
            
            _gitCommitInput: {
                type: String,
            },
            
            _localizationsInput: {
                type: Array
            },
            
            _commitSelect: {
                type: String
            },

            _simpleCommitSelected: {
                type: String
            },
            
            _newCommitMessage: {
                type: String
            },
            
            _commitMessage: {
                type: String
            },

            _displayCommitNotFoundHint: {
                type: Boolean
            },

            _newLocalization: {
                type: String
            },

            _categoriesInput: {
                type: Array
            },

            _newCategory: {
                type: Number
            },

            _removeReviewText: {
                type: String
            },
            
            _loadingDialog: {
                type: Boolean
            },
            
            _loading: {
                type: Boolean
            },
            
            _localizationIndexToRemove: {
                type: Number
            },

            module: {
                type: Object
            },

            account: {
                type: String
            },

            bundle: {
                type: String
            },

            releases: {
                type: Array
            },

            availableCategories: {
                type: Object
            },
            
            resources: {
                type: Object
            }
        };
    }



    constructor() {
        super();
        
        this.AVAILABLE_LANGUAGES = [ 'de', 'en' ];
        this._branches = [];
        this._branchCommitsSimple = [];
        this._gitURLInput = '';
        this._gitCommitInput = '',
        this._localizationsInput = [];
        this._commitSelect = '';
        this._simpleCommitSelected = '';
        this._newCommitMessage = '';
        this._commitMessage = '';
        this._displayCommitNotFoundHint = false;
        this._newLocalization = '';
        this._categoriesInput = [];
        this._newCategory = 0;
        this._removeReviewText = '';
        this._loadingDialog = false;
        this._loading = false;
        this._localizationIndexToRemove = -1;
        this.module = null;
        this.account = '';
        this.bundle = '';
        this.releases = [];
        this.availableCategories = {};
        this.resources = {
            en: {
                'remove-localization-check': 'Are you sure you want to remove this localization? All texts will be lost.',
                'remove-uploaded-check': 'Are you sure you want to delete this template? All settings will be lost.',
                'no': 'No',
                'yes': 'Yes',
                'abort': 'Abort',
                'select': 'Select',
                'ok': 'OK',
                'select-commit': 'Please select a commit',
                'select-commit-button': 'Select commit',
                'only-simple-selection': 'Currently, commit specific selection is only possible for public GitHub repositories. For other repositories, you can only select the top commits of each branch via dialog. If you want to select another commit, you need to enter its ID manually. Consider that you will select the current top commit and the selection will not update if new commits are pushed to the selected branch.',
                'current-commit-could-not-be-detected': 'The currently selected Commit could not be assigned to a branch. Thus, no branch was expanded.',
                'load-more': 'Load more',
                'remove-localization': 'Remove localization',
                'add-localization': 'Add localization',
                'categories': 'Categories',
                'add-category': 'Add Category',
                'discard': 'Discard',
                'save': 'Save',
                'submit': 'Submit',
                'withdraw': 'Withdraw',
                'end-channel-button': 'Finish Channel',
                'use-as-template-in-stable': 'Use as template in Stable',
                'use-as-template-in-beta': 'Use as template in Beta',
                'use-as-template-again': 'Use version as new template',
                'unknown-category': 'Unknown Category',
                'remove-review-to-uploaded': 'Are you sure you want to withdraw your submission? In this case, the IP-Symcon team will not check your module any more and you can continue editing the submission.',
                'remove-review-completely': 'Are you sure you want to withdraw your submission? Since you are already preparing a new submission, the current one will be lost.',
                'update-succesful': 'Update done',
                'update-failed': 'Update failed',
                'updates-not-applied-yet': 'Updates were not confirmed yet. Please click \'Update\' to confirm changes.',
                'submit-complete': 'Submission complete',
                'submit-failed': 'Submission failed',
                'loading-commits-failed': 'Failed to load commits',
                'invalid-url': 'Invalid Git-URL',
                'selecting-requires-github': 'A public Github repository is required to select a commit. For other repositories, please enter the commit ID into the input field.',
                'could-not-load-commits': 'Could not load commits. Does your URL correspond to a valid and public Github repository?',
                'loading-more-commits-failed': 'Failed to load more commits',
                'already-template-in-channel': 'There is a template at the new channel already. It needs to be discarded before using this version.',
                'creation-of-new-template-failed': 'Creation of the new template failed',
                'deletion-failed': 'Deletion failed',
                'language': 'Language',
                'remark-from-symcon': 'Remark from Symcon',
                'repository-settings': 'Repository Settings',
                'git-url': 'GIT-URL',
                'git-commit': 'GIT-Commit',
                'commit-message': 'Commit Message',
                'localizations': 'Localizations',
                'name': 'Name',
                'description': 'Description',
                'release-notes': 'Release Notes',
                'documentation-link': 'Link to documentation',
                'german': 'German',
                'english': 'English',
                'in-review': 'In Review',
                'current-release': 'Current Release',
                'current-template': 'Current Template',
                'declined-template': 'Declined Template',
                'end-channel': 'Channel finished',
                'released-description': 'This is your current release. Other users that access your module on this branch will receive this version.',
                'review-description': 'This version is currently being reviewed by the Symcon team. If the module passes the review it will become available to other users. The review can take some time, depending on the complexity and the changes to your module.',
                'uploaded-description': 'This is your current template for the next version. You can edit it until the release is fully prepared and then submit it.',
                'declined-description': 'This version was rejected by Symcon. Please check your version again, especially in regard of the remarks from Symcon. After updating your version accordingly, you can submit it again.',
                'end-channel-description': 'This channel was finished. All requests to this channel will be forwarded to the next highest channel, e.g., from beta to stable',
                'currently-no-localizations': 'This version does not have any localizations yet. At least one localization is required for a release.',
                'currently-no-categories': 'This version does not have any categories yet. At least one category is required for a release.',
                'git-url-empty': 'Please enter a GIT URL',
                'git-commit-empty': 'Please select a GIT commit',
                'invalid-git-url': 'Your entered GIT URL is not a valid URL.',
                'invalid-commit-id': 'Your entered commit ID is not a valid commit ID.',
                'name-empty': 'Please enter a name for the localization {language}',
                'name-too-long': 'Your selected name for the localization {language} is too long. It must not exceed 30 letters.',
                'description-empty': 'Please enter a description for the localization {language}',
                'description-too-long': 'Your description for the localization {language} is too long. It must not exceed 3000 letters.',
                'release-notes-empty': 'Please enter release notes for the localization {language}',
                'release-notes-too-long': 'Your release notes for the localization {language} is too long. It must not exceed 3000 letters.',
                'documentation-too-long': 'Your documentation link for the localization {language} is too long. It must not exceed 500 letters.',
                'at-least-one-localization': 'A release requires at least one localization',
                'at-least-one-category': 'A release requires at least one category',
                'localizations-from-released-are-required': 'All localizations that are provided by the current release are also required in a new release. Missing language: {missingLanguage}',
                'timeout-repository': 'Downloading your repository timed out. Please use a smaller repository.',
                'library-missing-library-json': 'Your library is missing a library.json',
                'library-invalid-json': 'library.json is invalid JSON',
                'library-no-id': 'ID was not defined in library.json or the ID is not String',
                'library-id-not-guid': 'The ID of the library is no valid GUID',
                'library-no-author': 'Author was not defined in library.json or the author is not String',
                'library-no-name': 'Name was not defined in library.json or the name is not String',
                'library-no-url': 'URL was not defined in library.json or the URL is not String',
                'library-no-version': 'Version was not defined in library.json or the version is not String',
                'library-no-build': 'Build was not defined in library.json or the build is not Integer',
                'library-no-date': 'Date was not defined in library.json or the date is not Integer',
                'library-too-many-properties': 'Too many properties in library.json',
                'library-compatibility-no-object': 'The compatibility in library is no object',
                'library-compatibility-version-no-string': 'The version in compatibility of library.json is no string',
                'library-compatibility-date-no-integer': 'The date in compatibility of library.json is no integer',
                'could-not-clone': 'Cloning the repository failed. Is the URL correct?',
                'could-not-checkout': 'Checking out the commit failed. Is the commit ID correct?',
                'could-not-update-submodules': 'Loading the submodules failed. Are the submodules correct?',
                'module-missing-module-json': 'The module {module} has no module.json',
                'module-invalid-json': 'module.json for module {module} is invalid JSON',
                'module-no-id': 'ID for module {module} was not defined in module.json or the ID is not String',
                'module-id-not-guid': 'The ID for module {module} is no valid GUID',
                'module-no-name': 'Name for module {module} was not defined in module.json or the name is not String',
                'module-invalid-type': 'The type for module {module} is invalid',
                'module-no-vendor': 'Vendor for module {module} was not defined in module.json or the vendor is not String',
                'module-no-aliases': 'Aliases for module {module} was not defined in module.json or the aliases is no Array',
                'module-alias-no-string': 'The elements of aliases in module {module} need to be String',
                'module-alias-not-empty': 'The elements of aliases in module {module} must not be empty Strings',
                'module-no-parent-requirements': 'Parent requirements for module {module} were not defined in module.json or the parent requirements are no Array',
                'module-parent-requirement-no-string': 'The elements of parent requirements need to be String',
                'module-parent-requirement-invalid-guid': 'The elements of parent requirements need to be valid GUIDs',
                'module-no-child-requirements': 'Child requirements for module {module} were not defined in module.json or the child requirements are no Array',
                'module-child-requirement-no-string': 'The elements of child requirements need to be String',
                'module-child-requirement-invalid-guid': 'The elements of child requirements need to be valid GUIDs',
                'module-no-implemented': 'Implemented for module {module} were not defined in module.json or implemented is no Array',
                'module-implemented-no-string': 'The elements of implemented need to be String',
                'module-implemented-invalid-guid': 'The elements of implemented need to be valid GUIDs',
                'module-no-prefix': 'Prefix for module {module} was not defined in module.json or the prefix is not String',
                'module-invalid-prefix': 'Prefix for module {module} is invalid',
                'module-invalid-form-json': 'form.json for module {module} is invalid JSON',
                'module-invalid-locale-json': 'locale.json for module {module} is invalid JSON',
                'module-no-module-php': 'module.php for module {module} is missing',
                'module-module-php-short-tag': 'module.php for module {module} uses the short php tag (<?). Please use the long php tag (<?php) instead',
                'guid-taken': 'The used GUID {guid} is already used by another module',
                'no-category-selected': 'No category selected',
                'no-documentation': 'No documentation link for the localization {language}',
                'modules-address': '/developer/modules',
                'repository-not-existing': 'The entered GIT-URL does not correspond to a repository. Please check if the URL is correct.',
                'error-last-cloning': 'Something went wrong while uploading your module',
                'uploading-taking-too-long': 'Uploading your module is taking too long... Please contact Symcon to handle this issue',
                'reloading-module-failed': 'Reloading the module failed',
                'status-not-existing': 'Current status does not exist',
                'invalid-status': 'Invalid status',
                'selecting-commit-failed': 'Selecting a commit failed',
                'localization-missing': 'Please select a language',
                'ending-channel-failed': 'Ending channel failed',
                'preparing-submission': 'The updated submission is currently being prepared. This could take some time, especially if the Git commit was changed and a new version is downloaded.',
                'close-lower-channels-stable': 'Close beta and testing channels after succesful review',
                'close-lower-channels-beta': 'Close testing channel after submission',
                'submission': 'Submission',
                'no-functional-changes': 'Update includes no functional changes'
            },
            de: {
                'remove-localization-check': 'Sind Sie sicher, dass Sie diese Lokalisierung löschen wollen? Alle Texte gehen dabei verloren.',
                'remove-uploaded-check': 'Wollen Sie Ihre aktuelle Vorlage wirklich löschen? Hierbei gehen alle Einstellungen verloren.',
                'no': 'Nein',
                'yes': 'Ja',
                'abort': 'Abbrechen',
                'select': 'Auswählen',
                'ok': 'OK',
                'select-commit': 'Bitte wählen Sie einen Commit',
                'select-commit-button': 'Commit auswählen',
                'only-simple-selection': 'Aktuell ist eine Commit-genaue Auswahl nur für öffentliche GitHub-Repositories möglich. Bei anderen Repositories kann über den Dialog nur der aktuellste Commit jedes Branches ausgewählt werden. Um andere Commits auszuwählen, muss die Commit ID manuell eingegeben werden. Beachten Sie, dass der im Moment aktuellste Commit des Branches ausgewählt wird. Dieser wird nicht aktualisiert, wenn weitere Commits gepusht werden.',
                'current-commit-could-not-be-detected': 'Der aktuell gewählte Commit konnte leider keinem Branch zugewiesen werden. Daher wurde kein Branch ausgeklappt.',
                'load-more': 'Mehr laden',
                'remove-localization': 'Lokalisierung entfernen',
                'add-localization': 'Lokalisierung hinzufügen',
                'categories': 'Kategorien',
                'add-category': 'Kategorie hinzufügen',
                'discard': 'Verwerfen',
                'save': 'Speichern',
                'submit': 'Einreichen',
                'withdraw': 'Zurückziehen',
                'end-channel-button': 'Kanal abschließen',
                'use-as-template-in-stable': 'Als Vorlage in Stable verwenden',
                'use-as-template-in-beta': 'Als Vorlage in Beta verwenden',
                'use-as-template-again': 'Als neue Vorlage verwenden',
                'unknown-category': 'Unbekannte Kategorie',
                'remove-review-to-uploaded': 'Wollen Sie Ihre Einreichung zurückziehen? In diesem Fall wird das IP-Symcon Team ihr Modul nicht überprüfen und Sie können Ihre Einreichung weiter bearbeiten.',
                'remove-review-completely': 'Wollen Sie Ihre Einreichung zurückziehen? Da Sie bereits an einer neuen Vorlage arbeiten, geht Ihre Einreichung dabei verloren.',
                'update-succesful': 'Aktualisierung abgeschlossen',
                'update-failed': 'Aktualisierung fehlgeschlagen',
                'updates-not-applied-yet': 'Aktualisierungen wurden noch nicht bestätigt. Bitte klicken Sie auf "Aktualisieren" um die Änderungen zu bestätigen.',
                'submit-complete': 'Einreichung abgeschlossen',
                'submit-failed': 'Einreichung fehlgeschlagen',
                'loading-commits-failed': 'Commits laden fehlgeschlagen',
                'invalid-url': 'Ungültige Git-URL',
                'selecting-requires-github': 'Ein öffentliches Github Repository wird benötigt um einen Commit auszuwählen. Bei anderen Repositories geben Sie bitte die Commit-ID in dieses Eingabefeld ein.',
                'could-not-load-commits': 'Konnte Commits nicht laden. Zeigt Ihre URL auf ein gültiges und öffentliches Github Repository?',
                'loading-more-commits-failed': 'Laden weiterer Commits ist fehlgeschlagen',
                'already-template-in-channel': 'Es gibt auf dem neuen Kanal bereits eine Vorlage. Diese muss verworfen werden, bevor diese Version verwendet werden kann',
                'creation-of-new-template-failed': 'Erstellung des neuen Templates ist fehlgeschlagen',
                'deletion-failed': 'Löschen fehlgeschlagen',
                'language': 'Sprache',
                'remark-from-symcon': 'Anmerkungen von Symcon',
                'repository-settings': 'Repository Einstellungen',
                'git-url': 'GIT-URL',
                'git-commit': 'GIT-Commit',
                'commit-message': 'Commit Nachricht',
                'localizations': 'Lokalisierungen',
                'name': 'Name',
                'description': 'Beschreibung',
                'release-notes': 'Versionsinformationen',
                'documentation-link': 'Link zur Dokumentation',
                'german': 'Deutsch',
                'english': 'Englisch',
                'in-review': 'Wird geprüft',
                'current-release': 'Aktuelle Veröffentlichung',
                'current-template': 'Aktuelle Vorlage',
                'declined-template': 'Abgelehnte Version',
                'end-channel': 'Kanal abgeschlossen',
                'released-description': 'Dies ist Ihre aktuell veröffentlichte Version. Wenn andere Benutzer auf diesen Kanal Ihres Modules zugreifen, erhalten Sie diese Version.',
                'review-description': 'Diese Version wird aktuell vom Symcon Team geprüft. Nach Abschluss der Prüfung wird diese Version für andere Benutzer verfügbar. Die Prüfung kann, abhängig von der Komplexität und dem Umfang der Änderungen an Ihrem Modul eine Weile dauern.',
                'uploaded-description': 'Dies ist Ihre aktuelle Vorlage für die nächste Version. Sie können die Vorlage bearbeiten bis die Veröffentlichung komplett vorbereitet ist und diese dann einreichen.',
                'declined-description': 'Diese Version wurde von Symcon abgelehnt. Bitte prüfen Sie Ihre Version noch einmal, insbesondere bezüglich der Anmerkung von Symcon. Nachdem Sie die Version entsprechend überarbeitet haben, können Sie diese erneut einreichen.',
                'end-channel-description': 'Dieser Kanal wurde abgeschlossen. Anfragen an diesen Kanal werden an den nächsthöheren Kanal, also beispielsweise von Beta nach Stable, weitergeleitet.',
                'currently-no-localizations': 'Diese Version hat noch keine Lokalisierungen. Mindestens eine Lokalisierung ist erforderlich für eine Veröffentlichung.',
                'currently-no-categories': 'Diese Version hat noch keine Kategorien. Mindestens eine Kategorie ist erforderlich für eine Veröffentlichung.',
                'git-url-empty': 'Bitte geben Sie eine GIT URL ein',
                'git-commit-empty': 'Bitte wählen Sie einen GIT Commit aus',
                'invalid-git-url': 'Ihre eingegebene GIT URL ist leider nicht gültig.',
                'invalid-commit-id': 'Ihre eingegebene Commit-ID ist leider nicht gültig.',
                'name-empty': 'Bitte geben Sie einen Namen in der Lokalisierung {language} ein',
                'name-too-long': 'Ihr gewählter Name in der Lokalisierung {language} ist zu lang. Er darf nicht mehr als 30 Zeichen lang sein.',
                'description-empty': 'Bitte geben Sie eine Beschreibung in der Lokalisierung {language} ein',
                'description-too-long': 'Ihre Beschreibung in der Lokalisierung {language} ist zu lang. Sie darf nicht mehr als 3000 Zeichen lang sein.',
                'release-notes-empty': 'Bitte geben Sie Versionsinformationen in der Lokalisierung {language} ein',
                'release-notes-too-long': 'Ihre Versionsinformationen in der Lokalisierung {language} sind zu lang. Sie dürfen nicht mehr als 3000 Zeichen lang sein.',
                'documentation-too-long': 'Der Link zu Ihrer Dokumentation in der Lokalisierung {language} ist zu lang. Er darf nicht mehr als 500 Zeichen lang sein.',
                'at-least-one-localization': 'Eine Veröffentlichung benötigt mindestens eine Lokalisierung',
                'at-least-one-category': 'Eine Veröffentlichung benötigt mindestens eine Kategorie',
                'localizations-from-released-are-required': 'Alle Lokalisierungen, die in der aktuellen Veröffentlichung angeboten werden, müssen auch in neuen Veröffentlichungen angeboten werden. Fehlende Lokalisierung: {missingLanguage}',
                'timeout-repository': 'Der Download Ihres Repositories dauert zu lange. Bitte verwenden Sie ein kleineres Repository.',
                'library-missing-library-json': 'Der Bibliothek fehlt eine library.json',
                'library-invalid-json': 'library.json ist ungültiges JSON',
                'library-no-id': 'ID wurde in der library.json nicht definiert oder ist kein String',
                'library-id-not-guid': 'Die ID in der library.json ist keine gültige GUID',
                'library-no-author': 'Autor wurde in der library.json nicht definiert oder ist kein String',
                'library-no-name': 'Name wurde in der library.json nicht definiert oder ist kein String',
                'library-no-url': 'URL wurde in der library.json nicht definiert oder ist kein String',
                'library-no-version': 'Version wurde in der library.json nicht definiert oder ist kein String',
                'library-no-build': 'Build wurde in der library.json nicht definiert oder ist kein Integer',
                'library-no-date': 'Datum wurde in der library.json nicht definiert oder ist kein Integer',
                'library-too-many-properties': 'Zu viele Eigenschaften in der library.json',
                'library-compatibility-no-object': 'Kompatibilität in der library.json ist kein Objekt',
                'library-compatibility-version-no-string': 'Die Version der Kompatibilität in der library.json ist kein String',
                'library-compatibility-date-no-integer': 'Das Datum der Kompatibilität in der library.json ist kein Integer',
                'could-not-clone': 'Das Klonen des Repositories ist fehlgeschlagen. Ist die URL korrekt?',
                'could-not-checkout': 'Der Checkout des Commits ist fehlgeschlagen. Ist die Commit-ID korrekt?',
                'could-not-update-submodules': 'Das Laden der Submodule ist fehlgeschlagen. Sind die Submodule korrekt?',
                'module-missing-module-json': 'Das Modul {module} hat keine module.json',
                'module-invalid-json': 'Die module.json des Moduls {module} ist ungültiges JSON',
                'module-no-id': 'Die ID des Moduls {module} wurde in der module.json nicht definiert oder ist kein String',
                'module-id-not-guid': 'Die ID des Moduls {module} ist keine gültige GUID',
                'module-no-name': 'Der Name des Moduls {module} wurde in der module.json nicht definiert oder ist kein String',
                'module-invalid-type': 'Der Typ des Moduls {module} ist ungültig',
                'module-no-vendor': 'Der Hersteller des Moduls {module} wurde in der module.json nicht definiert oder ist kein String',
                'module-no-aliases': 'Aliases des Moduls {module} wurde in der module.json nicht definiert oder sind kein Array',
                'module-alias-no-string': 'Die Elemente von aliases des Moduls {module} müssen Strings sein',
                'module-alias-not-empty': 'Die Elemente von aliases des Moduls {module} dürfen keine leeren Strings sein',
                'module-no-parent-requirements': 'Parent Requirements des Moduls {module} wurde in der module.json nicht definiert oder sind kein Array',
                'module-parent-requirement-no-string': 'Die Elemente von parentRequirements des Moduls {module} müssen Strings sein',
                'module-parent-requirement-invalid-guid': 'Die Elemente von parentRequirements des Moduls {module} müssen gültige GUIDs sein',
                'module-no-child-requirements': 'Child Requirements des Moduls {module} wurde in der module.json nicht definiert oder sind kein Array',
                'module-child-requirement-no-string': 'Die Elemente von childRequirements des Moduls {module} müssen Strings sein',
                'module-child-requirement-invalid-guid': 'Die Elemente von childRequirements des Moduls {module} müssen gültige GUIDs sein',
                'module-no-implemented': 'Implemented des Moduls {module} wurde in der module.json nicht definiert oder ist kein Array',
                'module-implemented-no-string': 'Die Elemente von implemented des Moduls {module} müssen Strings sein',
                'module-implemented-invalid-guid': 'Die Elemente von implemented des Moduls {module} müssen gültige GUIDs sein',
                'module-no-prefix': 'Der Prefix des Moduls {module} wurde in der module.json nicht definiert oder ist kein String',
                'module-invalid-prefix': 'Der Prefix des Moduls {module} ist ungültig',
                'module-invalid-form-json': 'Die form.json des Moduls {module} ist ungültiges JSON',
                'module-invalid-locale-json': 'Die locale.json des Moduls {module} ist ungültiges JSON',
                'module-no-module-php': 'Die module.php des Moduls {module} fehlt',
                'module-module-php-short-tag': 'Die module.php des Moduls {module} verwendet den kurzen PHP Tag (<?). Bitte verwenden Sie stattdessen den langen PHP Tag (<?php)',
                'guid-taken': 'Die verwendete GUID {guid} wird bereits von einem anderen Modul verwendet',
                'no-category-selected': 'Keine Kategorie ausgewählt',
                'no-documentation': 'Kein Link zur Dokumentation für die Lokalisierung {language}',
                'modules-address': '/entwickler/module',
                'repository-not-existing': 'Die angegebene GIT-URL zeigt nicht auf ein Repository. Bitte prüfen Sie, ob die URL korrekt ist.',
                'error-last-cloning': 'Beim letzten Upload des Moduls ist etwas schief gegangen',
                'uploading-taking-too-long': 'Der Upload Ihres Moduls dauert zu lange... Bitte kontaktieren Sie Symcon um dieses Problem zu beheben',
                'reloading-module-failed': 'Neu laden des Moduls fehlgeschlagen',
                'status-not-existing': 'Aktueller Status existiert nicht',
                'invalid-status': 'Ungültiger Status',
                'selecting-commit-failed': 'Die Auswahl eines Commits ist fehlgeschlagen',
                'localization-missing': 'Bitte wählen Sie eine Sprache',
                'ending-channel-failed': 'Kanal beenden fehlgeschlagen',
                'preparing-submission': 'Die aktualisierte Einreichung wird gerade vorbereitet. Dies könnte einige Zeit dauern, insbesondere wenn der Git Commit geändert wurde und eine neue Version heruntergeladen wird.',
                'close-lower-channels-stable': 'Schließe Beta- und Testing-Kanal nach erfolgreichem Review',
                'close-lower-channels-beta': 'Schließe Testing-Kanal nach Einreichung',
                'submission': 'Einreichung',
                'no-functional-changes': 'Aktualisierung beinhaltet keine funktionalen Änderungen'
            }
        }
    }



    _isEmpty(arrayOperation) {
        return !arrayOperation.base || (arrayOperation.base.length === 0);
    }
    
    
    
    _languageToNiceWord(language) {
        switch (language) {
            case 'de':
                return this._('german');
                
            case 'en':
                return this._('english');
                
            default:
                return language;
        }
    }
    
    
    
    _statusToNiceWord(status) {
        switch (status) {
            case 'released':
                return this._('current-release');
                
            case 'review':
                return this._('in-review');
                
            case 'uploaded':
                return this._('current-template');
                
            case 'declined':
                return this._('declined-template');

            case 'endChannel':
                return this._('end-channel');
                
            default:
                return status;
        }
    }



    _statusToDescription(status) {
        switch (status) {
            case 'released':
                return this._('released-description');

            case 'review':
                return this._('review-description');

            case 'uploaded':
                return this._('uploaded-description');

            case 'declined':
                return this._('declined-description');

            case 'endChannel':
                return this._('end-channel-description');

            default:
                return '';
        }
    }



    _getRemarkIcon(status) {
        switch (status) {
            case 'uploaded':
                return 'error';

            case 'declined':
                return 'info';

            case 'released':
                return 'check_circle';

            default:
                this._showError(this._('invalid-status'));
                throw 'Invalid status';
        }
    }



    _getRemarkIconClass(status) {
        switch (status) {
            case 'uploaded':
                return 'clone-failed-icon';

            case 'declined':
                return 'declined-icon';

            case 'released':
                return 'accepted-icon';

            default:
                this._showError(this._('invalid-status'));
                throw 'Invalid status';
        }
    }



    _getRemarkClass(status) {
        switch (status) {
            case 'uploaded':
                return 'clone-failed-block';

            case 'declined':
                return 'declined-block';

            case 'released':
                return 'accepted-block';

            default:
                this._showError(this._('invalid-status'));
                throw 'Invalid status';
        }
    }



    _getRemarkLabel(status) {
        switch (status) {
            case 'uploaded':
                return this._('error-last-cloning');

            case 'declined':
            case 'released':
                return this._('remark-from-symcon');

            default:
                this._showError(this._('invalid-status'));
                throw 'Invalid status';
        }
    }



    _generateDialogCategories(availableCategories) {
        let getChildrenRecursive = (categoryID) => {
            let children = [];
            for (let childCategoryID in this.availableCategories) {
                let childCategory = this.availableCategories[childCategoryID];
                if (childCategory.parent === categoryID) {
                    children.push(childCategory.id);
                    children.push(...getChildrenRecursive(childCategory.id));
                }
            }
            return children;
        };
        
        if (!this.availableCategories) {
            return [];
        }
        
        // 0 = Top Level
        return getChildrenRecursive(0);
    }



    _categoryToName(categoryID) {
        let name = this._('unknown-category');
        
        if (this.availableCategories) {
            let category = this.availableCategories[categoryID];
            if (category) {
                name = category.name;
            }
        }
        
        
        if ((!this._isUploaded()) && (categoryID !== this._categoriesInput[this._categoriesInput.length - 1])) {
            return name + ',';
        }
        else {
            return name;
        }
    }



    _generateParentPrefix(categoryID) {
        let category = this.availableCategories[categoryID];
        if (!category || (category.parent === 0)) {
            return '';
        }
        else {
            // FIXME: Loop danger! If categories are defined in a circle this will become an infinite loop
            // While that would be an erroneous category specification, we should detect it
            return '- - ' + this._generateParentPrefix(category.parent);
        }
    }



    _openAddLocalizationDialog() {
        this._newLocalization = '';
        const addLocalizationDialog = this.shadowRoot.getElementById('add-localization-dialog');
        for (const radioButton of addLocalizationDialog.querySelectorAll('mwc-radio')) {
            radioButton.checked = false;
        }
        addLocalizationDialog.show();
    }



    _openAddCategoryDialog() {
        this._newCategory = 0;
        const addCategoryDialog = this.shadowRoot.getElementById('add-category-dialog');
        for (const radioButton of addCategoryDialog.querySelectorAll('mwc-radio')) {
            radioButton.checked = false;
        }
        addCategoryDialog.show();
    }



    _openRemoveReviewDialog() {
        // Check if the slot for uploaded is available for fallback
        this._removeReviewText = this._('remove-review-to-uploaded');
        for (let release of this.releases) {
            if ((release.channel === this.module.channel) && (release.status === 'uploaded')) {
                this._removeReviewText = this._('remove-review-completely');
                break;
            }
        }
        
        this.shadowRoot.getElementById('remove-review-dialog').show();
    }



    _openRemoveUploadedDialog() {
        this.shadowRoot.getElementById('remove-uploaded-dialog').show();
    }



    _toDate(isoString) {
        return new Date(isoString).toLocaleDateString();
    }



    _displayAddLocalizations() {
        return this._isUploaded() && (this._getAvailableLanguages().length > 0);
    }



    _getAvailableLanguages() {
        let languages = [];
        for (let language of this.AVAILABLE_LANGUAGES) {
            let languageExists = false;
            for (let localization of this._localizationsInput) {
                if (localization.language === language) {
                    languageExists = true;
                    break;
                }
            }
            
            if (!languageExists) {
                languages.push(language);
            }
        }
        
        return languages;
    }



    _isUploaded() {
        return (this.module && (this.module.status === 'uploaded'));
    }
    
    
    
    _isDeclined() {
        return (this.module && (this.module.status === 'declined'));        
    }



    _isReview() {
        return (this.module && (this.module.status === 'review'));
    }



    _isReleasedBetaOrTesting() {
        return (this.module && (this.module.status === 'released') && (['beta', 'testing'].includes(this.module.channel)));
    }



    _isReleasedTesting() {
        return (this.module && (this.module.status === 'released') && (this.module.channel === 'testing'));
    }



    _onModuleChanged() {
        if (!this.module) {
            return;
        }
        this._gitURLInput = this.module['git_url'];
        this._gitCommitInput = this.module['git_commit'];
        
        this._localizationsInput = JSON.parse(JSON.stringify(this.module.localizations));
        this._categoriesInput = JSON.parse(JSON.stringify(this.module.categories));

        if (this.module.clone_status === 'error') {
            const parsedMessage = this._parseErrorMessage(this.module.remark);
            if (parsedMessage !== '') {
                this.module.remark = parsedMessage;
            }
        }

        if (this.module.clone_status === 'uploading') {
            this._loading = true;
            let currentTimeout = 1000;
            const checkModule = () => {
                this.makeLambdaRequest('publish/module', 'GET', {
                    account: this.account,
                    bundle: this.bundle
                }).then(
                    (releases) => {
                        for (let release of releases) {
                            if (release.status === this.module.status) {
                                // Check if clone_status is still uploading
                                if (release.clone_status !== 'uploading') {
                                    this.module = release;
                                    this._loading = false;
                                }
                                else if (currentTimeout > 32000) {
                                    this._showError(this._('uploading-taking-too-long'), reloadError);
                                }
                                else {
                                    currentTimeout = 2 * currentTimeout;
                                    setTimeout(checkModule, currentTimeout);
                                }
                                return; // Skip showing error
                            }
                        }
                        this._showError(this._('status-not-existing'));
                    },
                    (reloadError) => {
                        this._showError(this._('reloading-module-failed'), reloadError);
                    }
                );
            };
            
            setTimeout(checkModule, currentTimeout);
        }
    }



    _parseErrorMessage(message) {
        switch (message) {
            case 'Timeout while loading repository':
                return this._('timeout-repository');
            
            case 'Library is missing library.json':
                return this._('library-missing-library-json');
                
            case 'library.json is invalid JSON':
                return this._('library-invalid-json');
                
            case 'No ID defined in library.json or ID is not String':
                return this._('library-no-id');

            case 'ID in library.json is no valid GUID':
                return this._('library-id-not-guid');

            case 'No Author defined in library.json or Author is not String':
                return this._('library-no-author');

            case 'No Name defined in library.json or Name is not String':
                return this._('library-no-name');

            case 'No URL defined in library.json or URL is not String':
                return this._('library-no-url');

            case 'No Version defined in library.json or Version is not String':
                return this._('library-no-version');

            case 'No Build defined in library.json or Build is not Integer':
                return this._('library-no-build');

            case 'No Date defined in library.json or Date is not Integer':
                return this._('library-no-date');

            case 'Too many properties in library.json':
                return this._('library-too-many-properties');

            case 'Compatibility in library.json is no Object':
                return this._('library-compatibility-no-object');

            case 'Compatibility version is not String in library.json':
                return this._('library-compatibility-version-no-string');

            case 'Compatibility date is not Integer in library.json':
                return this._('library-compatibility-date-no-integer');
                
            default: {
                let splitMessageDash = message.split(' - ');
                switch (splitMessageDash[0]) {
                    case 'Could not clone repository':
                        return this._('could-not-clone') + ' - ' + splitMessageDash[1];

                    case 'Could not checkout commit':
                        return this._('could-not-checkout') + ' - ' + splitMessageDash[1];

                    case 'Could not update submodules':
                        return this._('could-not-update-submodules') + ' - ' + splitMessageDash[1];
                }
                
                let splitMessage = message.split(': ');
                if (splitMessage.length === 2) {
                    switch (splitMessage[1]) {
                        case 'Module is missing module.json':
                            return this._('module-missing-module-json', 'module', splitMessage[0]) ;

                        case 'module.json is invalid JSON':
                            return this._('module-invalid-json', 'module', splitMessage[0]) ;

                        case 'No ID defined in module.json or ID is not String':
                            return this._('module-no-id', 'module', splitMessage[0]) ;

                        case 'ID in module.json is no valid GUID':
                            return this._('module-id-not-guid', 'module', splitMessage[0]) ;

                        case 'No Name defined in module.json or Name is not String':
                            return this._('module-no-name', 'module', splitMessage[0]) ;

                        case 'Type in module.json is invalid':
                            return this._('module-invalid-type', 'module', splitMessage[0]) ;

                        case 'No Vendor defined in module.json or Vendor is not String':
                            return this._('module-no-vendor', 'module', splitMessage[0]) ;

                        case 'No aliases defined in module.json or aliases is not Array':
                            return this._('module-no-module-aliases', 'module', splitMessage[0]) ;

                        case 'Alias needs to be String':
                            return this._('module-alias-no-string', 'module', splitMessage[0]) ;

                        case 'Alias must not be empty':
                            return this._('module-alias-not-empty', 'module', splitMessage[0]) ;

                        case 'No parentRequirements defined in module.json or parentRequirements is not Array':
                            return this._('module-no-parent-requirements', 'module', splitMessage[0]) ;

                        case 'parentRequirement needs to be String':
                            return this._('module-parent-requirement-no-string', 'module', splitMessage[0]) ;

                        case 'parentRequirement must be valid GUID':
                            return this._('module-parent-requirement-invalid-guid', 'module', splitMessage[0]) ;

                        case 'No childRequirements defined in module.json or childRequirements is not Array':
                            return this._('module-no-child-requirements', 'module', splitMessage[0]) ;

                        case 'childRequirement needs to be String':
                            return this._('module-child-requirement-no-string', 'module', splitMessage[0]) ;

                        case 'childRequirement must be valid GUID':
                            return this._('module-child-requirement-invalid-guid', 'module', splitMessage[0]) ;

                        case 'No implemented defined in module.json or implemented is not Array':
                            return this._('module-no-implemented', 'module', splitMessage[0]) ;

                        case 'implement needs to be String':
                            return this._('module-implemented-no-string', 'module', splitMessage[0]) ;

                        case 'implement must be valid GUID':
                            return this._('module-implemented-invalid-guid', 'module', splitMessage[0]) ;

                        case 'No prefix defined in module.json or prefix is not String':
                            return this._('module-no-prefix', 'module', splitMessage[0]) ;

                        case 'prefix in module.json is invalid':
                            return this._('module-invalid-prefix', 'module', splitMessage[0]) ;

                        case 'form.json is invalid JSON':
                            return this._('module-invalid-form-json', 'module', splitMessage[0]) ;

                        case 'locale.json is invalid JSON':
                            return this._('module-invalid-locale-json', 'module', splitMessage[0]) ;

                        case 'module.php is missing':
                            return this._('module-no-module-php', 'module', splitMessage[0]) ;

                        case 'module.php starts with short PHP tag':
                            return this._('module-module-php-short-tag', 'module', splitMessage[0]) ;
                            
                        default:
                            return '';
                    }
                }
                else if (/GUID (.*) is already used by another library/.test(message)) {
                    let match = /GUID (.*) is already used by another library/.exec(message);
                    return this._('guid-taken', 'guid', match[1]);
                }
                else {
                    return '';
                }
            }
        }
    }



    _generateOpenPanelCallback(language) {
        return () => {
            this._resizeTextareaByElement(this.shadowRoot.getElementById('localization-description-input-' + language));
            this._resizeTextareaByElement(this.shadowRoot.getElementById('localization-release-notes-input-' + language));
            this._resizeTextareaByElement(this.shadowRoot.getElementById('localization-documentation-link-input-' + language));
        }
    }
    
    
    
    _generateUpdateModulePromise() {
        return new Promise((accept, reject) => {
            let updatePromises = [];
            let updateGit = false;

            if ((this._gitURLInput !== this.module['git_url']) ||
                (this._gitCommitInput !== this.module['git_commit']) ||
                (this.module.clone_status === 'error')) {
                if (this._gitURLInput === '') {
                    this._showNotification(this._('git-url-empty'));
                    reject(this._('git-url-empty'));
                    return;
                }
                
                if (this._gitCommitInput === '') {
                    this._showNotification(this._('git-commit-empty'));
                    reject(this._('git-commit-empty'));
                    return;
                }

                if (!/^(https?|git|ssh):\/\/[^\s/$.?#].[^\s]*$/.test(this._gitURLInput.trim())) {
                    this._showNotification(this._('invalid-git-url'));
                    reject(this._('invalid-git-url'));
                    return;
                }

                if (!/^[0-9a-f]{40}$/.test(this._gitCommitInput)) {
                    this._showNotification(this._('invalid-commit-id'));
                    reject(this._('invalid-commit-id'));
                    return;
                }

                updatePromises.push(this.makeLambdaRequest('publish/module-git', 'POST', {
                    account: this.account,
                    bundle: this.bundle,
                    channel: this.module.channel,
                    URL: this._gitURLInput.trim(),
                    commit: this._gitCommitInput.trim()
                }));

                updateGit = true;
            }

            // Regular != instead of !== as close_lower_channels is saved as 0 or 1 and not as boolean like selected of mwc-switch
            // Due to rendering, the switch could not be there at all
            const newCloseLowerSwitch = this.shadowRoot.getElementById('close-lower-channels');
            if (newCloseLowerSwitch && (this.module.close_lower_channels != newCloseLowerSwitch.selected)) {
                updatePromises.push(this.makeLambdaRequest('publish/module-close-lower-channels', 'POST', {
                    account: this.account,
                    bundle: this.bundle,
                    channel: this.module.channel,
                    closeLower: newCloseLowerSwitch.selected ? '1' : '0'
                }));
            }

            if (this.module.no_functional_changes) {
                updatePromises.push(this.makeLambdaRequest('publish/module-no-functional-changes', 'POST', {
                    account: this.account,
                    bundle: this.bundle,
                    channel: this.module.channel,
                    noFunctionalChanges: '0'
                }));
            }
            
            let checkLocalization = (localization) => {
                if (localization.name === '') {
                    return this._('name-empty', 'language', this._languageToNiceWord(localization.language));
                }
                else if (localization.name.length > 30) {
                    return this._('name-too-long', 'language', this._languageToNiceWord(localization.language));                    
                }
                else if (localization.description === '') {
                    return this._('description-empty', 'language', this._languageToNiceWord(localization.language));
                }
                else if (localization.description.length > 3000) {
                    return this._('description-too-long', 'language', this._languageToNiceWord(localization.language));
                }
                else if (localization['release_notes'] === '') {
                    return this._('release-notes-empty', 'language', this._languageToNiceWord(localization.language));                    
                }
                else if (localization['release_notes'].length > 3000) {
                    return this._('release-notes-too-long', 'language', this._languageToNiceWord(localization.language));
                }
                else if (localization.documentation.length > 500) {
                    return this._('documentation-too-long', 'language', this._languageToNiceWord(localization.language));
                }
                else {
                    return '';
                }
            };

            for (let moduleLocalization of this.module.localizations) {
                let localizationFound = false;
                for (let inputLocalization of this._localizationsInput) {
                    if (moduleLocalization.language === inputLocalization.language) {
                        localizationFound = true;
                        if ((moduleLocalization.name !== inputLocalization.name) ||
                            (moduleLocalization.description !== inputLocalization.description) ||
                            (moduleLocalization['release_notes'] !== inputLocalization['release_notes']) ||
                            (moduleLocalization.documentation !== inputLocalization.documentation)) {
                            let errorMessage = checkLocalization(inputLocalization);
                            if (errorMessage !== '') {
                                this._showNotification(errorMessage);
                                reject(errorMessage);
                                return;
                            }
                            updatePromises.push(this.makeLambdaRequest('publish/module-localization', 'POST', {
                                account: this.account,
                                bundle: this.bundle,
                                channel: this.module.channel,
                                language: inputLocalization.language,
                                name: inputLocalization.name,
                                description: inputLocalization.description,
                                releaseNotes: inputLocalization['release_notes'],
                                documentation: inputLocalization.documentation.trim()
                            }));
                        }
                        break;
                    }
                }

                if (!localizationFound) {
                    updatePromises.push(this.makeLambdaRequest('publish/module-localization', 'DELETE', {
                        account: this.account,
                        bundle: this.bundle,
                        channel: this.module.channel,
                        language: moduleLocalization.language
                    }));
                }
            }

            for (let inputLocalization of this._localizationsInput) {
                let localizationFound = false;
                for (let moduleLocalization of this.module.localizations) {
                    if (moduleLocalization.language === inputLocalization.language) {
                        localizationFound = true;
                        break;
                    }
                }

                if (!localizationFound) {
                    let errorMessage = checkLocalization(inputLocalization);
                    if (errorMessage !== '') {
                        this._showNotification(errorMessage);
                        reject(errorMessage);
                        return;
                    }
                    
                    updatePromises.push(this.makeLambdaRequest('publish/module-localization', 'POST', {
                        account: this.account,
                        bundle: this.bundle,
                        channel: this.module.channel,
                        language: inputLocalization.language,
                        name: inputLocalization.name,
                        description: inputLocalization.description,
                        releaseNotes: inputLocalization['release_notes'],
                        documentation: inputLocalization.documentation.trim()
                    }));
                }
            }

            {
                let addCategories = [];
                let removeCategories = [];

                for (let newCategory of this._categoriesInput) {
                    if (!this.module.categories.includes(newCategory)) {
                        addCategories.push(newCategory);
                    }
                }

                for (let oldCategory of this.module.categories) {
                    if (!this._categoriesInput.includes(oldCategory)) {
                        removeCategories.push(oldCategory);
                    }
                }

                if (addCategories.length + removeCategories.length > 0) {
                    updatePromises.push(this.makeLambdaRequest('publish/module-categories', 'POST', {
                        account: this.account,
                        bundle: this.bundle,
                        channel: this.module.channel,
                        addCategories: JSON.stringify(addCategories),
                        removeCategories: JSON.stringify(removeCategories)
                    }));
                }
            }

            Promise.all(updatePromises).then(
                () => {
                    const finalizeUpdate = () => {
                        this.module.remark = '';
                        this.module.git_url = this._gitURLInput.trim();
                        this.module.git_commit = this._gitCommitInput.trim();
                        this.module.localizations = JSON.parse(JSON.stringify(this._localizationsInput));
                        this.module.categories = JSON.parse(JSON.stringify(this._categoriesInput));
                        if (newCloseLowerSwitch) {
                            this.module.close_lower_channels = newCloseLowerSwitch.selected ? 1 : 0;
                        }
                        accept();
                    }

                    if (!updateGit) {
                        finalizeUpdate();
                    }
                    else {
                        let currentTimeout = 1000;
                        const checkModule = () => {
                            this.makeLambdaRequest('publish/module', 'GET', {
                                account: this.account,
                                bundle: this.bundle
                            }).then(
                                (releases) => {
                                    let statusFound = false;
                                    for (let release of releases) {
                                        if (release.status === this.module.status) {
                                            statusFound = true;
                                            this.module.clone_status = release.clone_status;
                                            switch (release.clone_status) {
                                                case 'done':
                                                    this.module.clone_status = 'done';
                                                    finalizeUpdate();
                                                    break;

                                                case 'error': {
                                                    const parsedMessage = this._parseErrorMessage(release.remark);
                                                    if (parsedMessage) {
                                                        this._showNotification(parsedMessage);
                                                        this.module.remark = parsedMessage;
                                                    }
                                                    else {
                                                        this._showError(this._('update-failed'), release.remark);
                                                        this.module.remark = release.remark;
                                                    }
                                                    reject(release.remark);
                                                    break;
                                                }
                                                    
                                                case 'uploading':
                                                    if (currentTimeout > 300000) {
                                                        this._showError(this._('uploading-taking-too-long'));
                                                    }
                                                    else {
                                                        currentTimeout = 2 * currentTimeout;
                                                        setTimeout(checkModule, currentTimeout);
                                                    }  
                                            }
                                        }
                                    }
                                    if (!statusFound) {
                                        this._showError(this._('status-not-existing'));
                                    }
                                },
                                (reloadError) => {
                                    this._showError(this._('reloading-module-failed'), reloadError);
                                }
                            );
                        };
                        
                        setTimeout(checkModule, currentTimeout);
                    }
                },
                (error) => {
                    this._showError(this._('update-failed'), error);
                    
                    this.makeLambdaRequest('publish/module', 'GET', {
                        account: this.account,
                        bundle: this.bundle
                    }).then(
                        (releases) => {
                            for (let release of releases) {
                                if (release.status === this.module.status) {
                                    // Update categories as different categories can cause errors
                                    this.module.categories = release.categories;
                                }
                                reject(error);
                                this._loading = false;
                            }
                        },
                        (reloadError) => {
                            this._showError(this._('reloading-module-failed'), reloadError);
                            reject(error);
                            this._loading = false;
                        }
                    );
                }
            )
        });
    }



    _updateModule() {
        this._loading = true;
        this.shadowRoot.getElementById('preparing-submission-dialog').show();
        this._generateUpdateModulePromise().then(
            () => {
                this._showNotification(this._('update-succesful'));
                this._loading = false;                
            },
            (error) => {
                // Showing errors is handled within the update promise
                this._loading = false;
            }
        ).finally(
            () => {
                this.shadowRoot.getElementById('preparing-submission-dialog').close();
            }
        )
    }
    
    
    
    _hasUpdates() {
        if ((this.module['git_url'] !== this._gitURLInput.trim()) ||
            (this.module['git_commit'] !== this._gitCommitInput.trim())) {
            return true;
        }

        if (this.module.localizations.length !== this._localizationsInput.length) {
            return true;
        }

        for (let i = 0; i < this.module.localizations.length; i++) {
            if ((this.module.localizations[i].name !== this._localizationsInput[i].name) ||
                (this.module.localizations[i].description !== this._localizationsInput[i].description) ||
                (this.module.localizations[i]['release_notes'] !== this._localizationsInput[i]['release_notes'])) {
                return true;
            }
        }

        if (this.module.categories.length !== this._categoriesInput.length) {
            return true;
        }

        for (let category of this._categoriesInput) {
            if (!this.module.categories.includes(category)) {
                return true;
            }
        }
        
        return false;
    }



    _requestUpdate(channel) {
        const updateCardsEvent = new CustomEvent('update', {
            detail: {
                channel: channel
            },
            bubbles: true,
            cancelable: true,
            composed: true // Has to be true for the event to be able to escape from a shadow dom
        });
        this.dispatchEvent(updateCardsEvent);
    }
    
    
    
    _publishModule() {
        this._loading = true;
        this.shadowRoot.getElementById('preparing-submission-dialog').show();
        
        this._generateUpdateModulePromise().then(
            () => {
                if (this.module['git_url'] === '') {
                    this._showNotification(this._('git-url-empty'));
                    this._loading = false;
                    return;
                }
                
                if (this.module['git_commit'] === '') {
                    this._showNotification(this._('git-commit-empty'));
                    this._loading = false;
                    return;
                }

                if (this.module.localizations.length === 0) {
                    this._showNotification(this._('at-least-one-localization'));
                    this._loading = false;
                    return;
                }
                
                for (let localization of this.module.localizations) {
                    if (!/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.%]+$/.test(localization.documentation.trim())) {
                        this._showNotification(this._('no-documentation', 'language', this._languageToNiceWord(localization.language)));
                        this._loading = false;
                        return;
                    }
                }                

                for (let release of this.releases) {
                    if ((release.status === 'released') && (release.channel === this.module.channel)) {
                        for (let releaseLocalization of release.localizations) {
                            let localizationFound = false;
                            for (let currentLocalization of this.module.localizations) {
                                if (releaseLocalization.language === currentLocalization.language) {
                                    localizationFound = true;
                                    break;
                                }
                            }

                            if (!localizationFound) {
                                this._showNotification(this._('localizations-from-released-are-required', 'missingLanguage', this._languageToNiceWord(releaseLocalization.language)));
                                this._loading = false;
                                return;
                            }
                        }
                    }
                }

                if (this.module.categories.length === 0) {
                    this._showNotification(this._('at-least-one-category'));
                    this._loading = false;
                    return;
                }
                
                this.makeLambdaRequest('publish/publish-module', 'POST', {
                    account: this.account,
                    bundle: this.bundle,
                    channel: this.module.channel
                }).then(
                    () => {
                        this._loading = false;
                        this._requestUpdate();
                    },
                    (error) => {
                        this._showError(this._('submit-failed'), error);
                        this._loading = false;
                    }
                );
            },
            (error) => {
                // Showing errors is handled within the update promise
                this._loading = false;
            }
        ).finally(
            () => {
                this.shadowRoot.getElementById('preparing-submission-dialog').close();
            }
        );
    }
    
    
    
    _selectCommitSimple() {
        if (!/^(https?|git|ssh):\/\/[^\s/$.?#].[^\s]*$/.test(this._gitURLInput.trim())) {
            this._showNotification(this._('invalid-git-url'));
            return;
        }
        this._loadingDialog = true;
        
        let displayError = (message, error = false) => {
            this.shadowRoot.getElementById('select-simple-commit-dialog').close();
            this._loadingDialog = false;
            if (error) {
                this._showError(this._('selecting-commit-failed'), message);
            }
            else {
                this._showNotification(message);
            }
        };
        this.shadowRoot.getElementById('select-simple-commit-dialog').show();

        this.makeLambdaRequest('publish/commits', 'GET', {
            URL: this._gitURLInput.trim()
        }).then(
            (commits) => {
                commits.sort((branch1, branch2) => {
                    if (['main', 'master'].includes(branch1.branch)) {
                        return -1;
                    }
                    else if (['main', 'master'].includes(branch2.branch)) {
                        return 1;
                    }
                    else if (branch1.branch.toLowerCase() < branch2.branch.toLowerCase()) {
                        return -1;
                    }
                    else if (branch1.branch.toLowerCase() > branch2.branch.toLowerCase()) {
                        return 1;
                    }
                    else {
                        return 0;
                    }
                });
                this._branchCommitsSimple = commits;
                this._simpleCommitSelected = '';
                for (let commit of commits) {
                    if (commit.commit === this._gitCommitInput.trim()) {
                        this._simpleCommitSelected = this._gitCommitInput.trim();
                        break;
                    }
                }
                this._loadingDialog = false;
            },
            (error) => {
                displayError(this._('could-not-load-commits'), true);
                console.error(error);
            }
        )
    }



    _selectCommit() {
        let splitURL = this._gitURLInput.trim().split('github.com/');
        if (splitURL.length !== 2) {
            this._selectCommitSimple();
            return;
        }
        
        this._loadingDialog = true;
        this._branches = [];
        
        let displayError = (message, error = false) => {
            this.shadowRoot.getElementById('select-commit-dialog').close();
            this._loadingDialog = false;
            if (error) {
                this._showError(this._('selecting-commit-failed'), message);
            }
            else {
                this._showNotification(message);
            }
        };
        this.shadowRoot.getElementById('select-commit-dialog').show();
        
        let repositoryName = splitURL[1];
        if (/.*\.git$/.test(repositoryName)) {
            repositoryName = repositoryName.substr(0, repositoryName.length - 4);
        }

        const headers = {};
        if (this.accessToken) {
            headers.Authorization = `token ${this.accessToken}`;
        }

        this.makeXMLRequest(`https://api.github.com/repos/${repositoryName}/branches`, 'GET', headers).then(
            (response) => {
                const promises = [ this.makeXMLRequest(`https://api.github.com/repos/${repositoryName}`, 'GET', headers) ];
                for (let branch of response) {
                    promises.push(this.makeXMLRequest(`https://api.github.com/repos/${repositoryName}/commits?per_page=10&sha=${branch.commit.sha}`, 'GET', headers).then(
                        (response) => {
                            return {
                                branch: branch.name,
                                commits: response
                            };
                        },
                        (error) => {
                            throw error.response;
                        }
                    ));
                }

                Promise.all(promises).then(
                    (results) => {
                        const defaultBranch = results.shift()['default_branch'];
                        let foundCommit = (this._gitCommitInput === '');
                        results.sort((branch1, branch2) => {
                            if (branch1.branch === defaultBranch) {
                                return -1;
                            }
                            else if (branch2.branch === defaultBranch) {
                                return 1;
                            }
                            else if (branch1.branch.toLowerCase() < branch2.branch.toLowerCase()) {
                                return -1;
                            }
                            else if (branch1.branch.toLowerCase() > branch2.branch.toLowerCase()) {
                                return 1;
                            }
                            else {
                                return 0;
                            }
                        });
                        for (let result of results) {
                            result.selected = '';
                            result.opened = false;
                            result.showLoadMore = (result.commits.length === 10);
                            // A commit could be in multiple branches. In that case, we choose the first one
                            if (!foundCommit) {
                                for (let commit of result.commits) {
                                    if (commit.sha === this._gitCommitInput) {
                                        foundCommit = true;
                                        result.selected = this._gitCommitInput;
                                        result.opened = true;
                                        this._commitMessage = commit.commit.message;
                                        this._newCommitMessage = commit.commit.message;
                                        break;
                                    }
                                }
                            }
                            this._branches.push(result);
                        }
                        
                        this._displayCommitNotFoundHint = !foundCommit;
                        
                        this._commitSelect = this._gitCommitInput;
                        this._loadingDialog = false;
                    },
                    (error) => {
                        displayError(error);
                    }
                )
            },
            (error) => {
                switch (error.response.message) {
                    case 'Not Found':
                        displayError(this._('repository-not-existing'));
                        break;

                    case undefined:
                        displayError(this._('could-not-load-commits'));
                        break;

                    default:
                        displayError(error.response.message, true);
                        break;

                }
            }
        );
    }



    _generateLoadMoreCommits(branch) {
        return (event) => {
            let currentCommits = branch.commits;
            
            let splitURL = this._gitURLInput.trim().split('github.com/');
            if (splitURL.length !== 2) {
                this._showError(this._('invalid-url'));
                return;
            }

            let repositoryName = splitURL[1];
            if (/.*\.git$/.test(repositoryName)) {
                repositoryName = repositoryName.substr(0, repositoryName.length - 4);
            }

         
            const headers = {};
            if (this.accessToken) {
                headers.Authorization = `token ${this.accessToken}`;
            }

            this.makeXMLRequest(`https://api.github.com/repos/${repositoryName}/commits?per_page=11&sha=${currentCommits[currentCommits.length - 1].sha}`, 'GET', headers).then(
                (newCommits) => {
                    const oldBranches = JSON.parse(JSON.stringify(this._branches));
                    newCommits.splice(0, 1);
                    branch.commits.push(...newCommits);
                    branch.showLoadMore = (newCommits.length === 10);
                    // Update Branches -> Display the new content
                    this.requestUpdate('_branches', oldBranches);
                },
                (error) => {
                    this._showError(this._('loading-more-commits-failed'), error.response);
                }
            );
        };
    }
    
    
    
    _confirmSimpleCommit() {
        if (this._simpleCommitSelected === '') {
            this._showNotification(this._('select-commit'));
            return;
        }
        
        this._gitCommitInput = this._simpleCommitSelected;
        this.shadowRoot.getElementById('select-simple-commit-dialog').close();
    }
    
    
    
    _confirmCommit() {
        this._gitCommitInput = this._commitSelect;
        this._commitMessage = this._newCommitMessage;
    }

    
    
    _addLocalization() {
        if (!this._newLocalization) {
            this._showNotification(this._('localization-missing'));
            return;
        }
        const oldLocalizationsInput = JSON.parse(JSON.stringify(this._localizationsInput));
        this._localizationsInput.push({
            language: this._newLocalization,
            name: '',
            description: '',
            release_notes: '',
            documentation: ''
        });
        this.requestUpdate('_localizationsInput', oldLocalizationsInput);
        this.shadowRoot.getElementById('add-localization-dialog').close();
    }
    
    
    
    _forwardTo(channel, status = 'released') {
        for (let release of this.releases) {
            if ((release.channel === channel) && (release.status === 'uploaded')) {
                this._showError(this._('already-template-in-channel'));
                return;
            }
        }
        
        this._loading = true;
        
        this.makeLambdaRequest('publish/module-channel', 'POST', {account: this.account, bundle: this.bundle, channel: this.module.channel, newChannel: channel, status: status}).then(
            () => {
                this._requestUpdate(channel);
            },
            (error) => {
                this._showError(this._('creation-of-new-template-failed'), error);
            }
        ).finally(() => {
            this._loading = false;
        });
    }



    _endChannel() {
        this._loading = true;
        
        this.makeLambdaRequest('publish/end-channel', 'POST', {account: this.account, bundle: this.bundle, channel: this.module.channel}).then(
            () => {
                this._requestUpdate();
            },
            (error) => {
                this._showError(this._('ending-channel-failed'), error);
            }
        ).finally(() => {
            this._loading = false;
        });

    }



    _forwardToStable() {
        this._forwardTo('stable');
    }
    
    
    
    _forwardToBeta() {
        this._forwardTo('beta');
    }



    _useAsNewTemplate() {
        this._forwardTo(this.module.channel, 'declined');
    }



    _addCategory() {
        if (this._newCategory === 0) {
            this._showNotification(this._('no-category-selected'));
            return;
        }

        const oldCategoriesInput = JSON.parse(JSON.stringify(this._categoriesInput));
        this._categoriesInput.push(this._newCategory);
        this.requestUpdate('_categoriesInput', oldCategoriesInput);
        this.shadowRoot.getElementById('add-category-dialog').close();
    }
    
    
    
    _removeStatus(status) {
        this.makeLambdaRequest('publish/module', 'DELETE', {account: this.account, bundle: this.bundle, channel: this.module.channel, status: status}).then(
            () => {
                // While the dialog is open, scrolling is appearantly disabled.
                // Requesting an update before closing the dialog will disable scrolling
                // Thus: 1. Close Dialog, 2. Request Update
                switch (status) {
                    case 'uploaded':
                        this.shadowRoot.getElementById('remove-uploaded-dialog').close();
                        // Was the last release just deleted?
                        if (this.releases.length === 1) {
                            location.replace(`${this._('modules-address')}?account=${this.account}`);
                        }
                        else {
                            this._requestUpdate();
                        }
                        break;
                        
                    case 'review':
                        this.shadowRoot.getElementById('remove-review-dialog').close();
                        this._requestUpdate();
                        break;
                }
            },
            (error) => {
                this._showError(this._('deletion-failed'), error);
            }
        )
    }



    _removeUploaded() {
        this._removeStatus('uploaded');
    }
    
    
    
    _removeReview() {
        this._removeStatus('review');
    }



    update(changedProperties) {
        // As module is an object, attributeChangedCallback does not seem to work...
        if (changedProperties.has('module')) {
            this._onModuleChanged();
        }

        super.update(changedProperties);
    }



    connectedCallback() {
        super.connectedCallback();

        this._onModuleChanged();
    }
}

customElements.define('my-module-card', ModuleCard);
