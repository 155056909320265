import { LitElement, html, css } from 'lit-element';
import '@material/mwc-button';

import './workflow-display';
import '../../../components/symcon-card';
import '../../../components/symcon-expansion-panel';
import '../../../components/loading-backdrop';
import {CommonStyles} from '../../../mixins/common-styles';

class ProjectDisplay extends CommonStyles(LitElement) {

    static get styles() {
        return [super.styles, css`
            .title {
                font-size: 24px;
            }
            
            .bottom-space {
                padding-bottom: 20px;
            }
        `];
    }

    static get properties() {
        return {
            octokit: {
                type: Object
            },

            project: {
                type: String
            },

            _workflows: {
                type: Array
            },

            _versions: {
                type: Array
            },

            _branch: {
                type: String
            },

            _newestCommitSHA: {
                type: String
            },

            _inProgress: {
                type: Number
            },

            _pending: {
                type: Number
            },

            _error: {
                type: Number
            },

            _allRunsStarted: {
                type: Boolean
            },

            _loading: {
                type: Boolean
            }
        };
    }

    constructor() {
        super();

        this._workflows = [];
        this._versions = [];
        this._branch = 'master';
        this._inProgress = 0;
        this._pending = 0;
        this._error = 0;
        this._newestCommitSHA = '';
        this._allRunsStarted = false;
        this._loading = true;
    }

    _buildAll() {
        this._allRunsStarted = true;

        for (const workflowDisplay of this.shadowRoot.querySelectorAll('workflow-display')) {
            workflowDisplay.runBuild();
        }
    }

    _selectVersion() {
        this._branch = this.shadowRoot.getElementById('version-select').value;
    }

    async _updateBranch() {
        this._loading = true;
        const newestCommitData = await this.octokit.repos.listCommits({
            owner: 'symcon',
            repo: this.project,
            per_page: 1,
            sha: this._branch
        });

        this._newestCommitSHA = newestCommitData.data[0].sha;

        let inProgress = 0;
        let error = 0;
        let pending = 0;

        let allRunning = true;
        for (const workflow of this._workflows) {
            if (!workflow.path.includes('build')) {
                continue;
            }

            if (workflow.state === 'disabled_manually') {
                continue;
            }

            const runsData = await this.octokit.actions.listWorkflowRuns({
                owner: 'symcon',
                repo: this.project,
                workflow_id: workflow.id,
                branch: this._branch,
                per_page: 1
            });

            if (runsData.data.workflow_runs.length > 0) {
                const lastRun = runsData.data.workflow_runs[0];
                if (['in_progress', 'queued'].includes(lastRun.status)) {
                    inProgress++;
                }
                else {
                    allRunning = false;
                }

                if ((lastRun.status === 'completed') && (lastRun.conclusion !== 'success')) {
                    error++;
                }

                if (lastRun.head_commit.id !== this._newestCommitSHA) {
                    pending++;
                }
            }
            else {
                allRunning = false
            }
        }

        this._allRunsStarted = allRunning;
        this._inProgress = inProgress;
        this._error = error;
        this._pending = pending;
        this._loading = false;
    }

    render() {
        const getInfo = () => {
            if (this._inProgress > 0) {
                return html`${this._inProgress} building...`
            }
            else if (this._error > 0) {
                return html`${this._error} runs failed`
            }
            else if (this._pending > 0) {
                return html`${this._pending} pending`
            }
            else {
                return html``;
            }
        }

        return html`
            ${this._projectTitle ? html`
                            <div slot="header" class="text-color center-row between title">
                                <span><a href=${'//github.com/symcon/' + this.project} target="_blank" class="no-link-style"><b>${this._projectTitle}</b></a></span>
                            </div>` : html``
            }
            <div class="center-column bottom-space">
                <symcon-card>
                    <loading-backdrop ?hidden=${!this._loading}></loading-backdrop>
                    <symcon-expansion-panel>
                        ${this._projectTitle ? html`
                            <div slot="header" class="text-color center-row between title">
                                <mwc-select id="version-select" fixedMenuPosition @closed=${this._selectVersion}>
                                    ${this._versions.map((version) => {
                                        if (this._versions[0] === version) {
                                            return html`
                                                <mwc-list-item value=${version} selected>${version}</mwc-list-item>
                                            `;
                                        } else {
                                            return html`
                                                <mwc-list-item value=${version}>${version}</mwc-list-item>
                                            `;
                                        }
                                    })}
                                </mwc-select>
                                <span>${getInfo()}</span>
                                <mwc-button @click=${this._buildAll} raised ?disabled=${this._allRunsStarted}>${this._allRunsStarted ? html`All builds running` : html`Run All Builds`}</mwc-button>
                            </div>` : html``
                        }

                        ${this._workflows.filter((workflow) => {
                            return workflow && workflow.path && workflow.path.includes('build');
                        }).map((workflow) => {
                            return html`<workflow-display .workflow=${workflow} .branch=${this._branch} .newestCommitSHA=${this._newestCommitSHA} .octokit=${this.octokit} .project=${this.project}></workflow-display>`;
                        })}
                    </symcon-expansion-panel>
                </symcon-card>
            </div>
        `;
    }

    updated(changedProperties) {
        if (changedProperties.has('_branch') && !this._loading) {
            this._updateBranch();
        }
    }

    connectedCallback() {
        super.connectedCallback();

        const loadData = async () => {

            const [workflowData, repoData, branchesData] = await Promise.all([this.octokit.actions.listRepoWorkflows({
                owner: 'symcon',
                repo: this.project
            }), this.octokit.repos.get({
                owner: 'symcon',
                repo: this.project
            }), this.octokit.repos.listBranches({
                owner: 'symcon',
                repo: this.project,
                per_page: 100
            })]);

            this._branch = repoData.data['default_branch'];

            this._workflows = workflowData.data.workflows;

            let versions = [];
            for (const workflow of branchesData.data) {
                if (workflow.name.startsWith('IPS-') || workflow.name.startsWith('SYMOS-')) {
                    versions.push(workflow.name);
                }
            }
            versions.push(repoData.data['default_branch']);
            this._versions = versions.reverse();

            this._projectTitle = (repoData.data.description ? repoData.data.description : this.project);

            this._updateBranch();
        }

        loadData();
    }
}

customElements.define('project-display', ProjectDisplay);