export const DynamicTextareaResizeMixin = (superClass => {
    class DynamicTextareaResizeMixin extends superClass {
        async _resizeTextareaByElement(textarea) {
            const inner = textarea.shadowRoot.querySelector('textarea');
            if (inner === null) {
                setTimeout(() => {
                    this._resizeTextareaByElement(textarea);
                }, 100);
                return;
            }
            textarea.rows = 1;
            await null;
            while ((inner.scrollHeight > inner.offsetHeight) && (textarea.rows < 10)) {
                textarea.rows = textarea.rows + 1;
                await null;
            }

        }



        async _resizeTextarea(event) {
            this._resizeTextareaByElement(event.target);
        }



        updated() {
            if (super.updated) {
                super.updated();
            }

            for (let textarea of this.shadowRoot.querySelectorAll('mwc-textarea')) {
                this._resizeTextareaByElement(textarea);
            };
        }
    }

    return DynamicTextareaResizeMixin;
});
