import {LitElement, html} from 'lit-element';
import '@material/mwc-textfield';
import '@material/mwc-button';

const querystring = require('querystring');
const AmazonCognitoIdentity = require('amazon-cognito-identity-js');

import {UserPoolMixin} from '../../mixins/user-pool-mixin';
import {CommonStyles} from '../../mixins/common-styles';
import '../../components/loading-backdrop';
import '../../components/notification-dialog';
import '../../components/symcon-card';
import {ShowErrorMixin} from "../../mixins/show-error-mixin";
import {TranslateMixin} from "../../mixins/translate-mixin";
import {hideMenu} from '../../account-body';

class RegisterPage extends CommonStyles(TranslateMixin(ShowErrorMixin(UserPoolMixin(LitElement)))) {

    render() {
        const getContent = () => {
            if (this._userLoggedIn) {
                return html`
                    <symcon-card class="regular-card text-card center-column">
                        <div class="regular-text">${this._('not-while-logged-in')}</div>
                    </symcon-card>
                `;
            }
            else if (!this.registerDone) {
                return html`
                    <symcon-card class="regular-card">
                        <loading-backdrop ?hidden=${!this._loading}></loading-backdrop>
                        <div class="right-column side-margin">
                            <div class="regular-text top-text">${this._('register-description')}</div>
                            <mwc-textfield id="email-input" class="full-width" label=${this._('e-mail')} @keypress=${this._handleRegisterPress}></mwc-textfield>
                            <mwc-textfield id="username-input" class="full-width" label=${this._('username')} @keypress=${this._handleRegisterPress}></mwc-textfield>
                            <mwc-textfield id="password-input" class="full-width" label=${this._('password')} @keypress=${this._handleRegisterPress} type="password"></mwc-textfield>
                            <mwc-textfield id="confirm-password-input" class="full-width" label=${this._('confirm-password')} @keypress=${this._handleRegisterPress} type="password"></mwc-textfield>
                            <mwc-button class="action-buttons" @click=${this._register}>${this._('register')}</mwc-button>
                        </div>
                    </symcon-card>
                `;
            }
            else if (this._activationDone) {
                return html`
                    <symcon-card class="regular-card text-card center-column">
                        <div class="bottom-padding regular-text">
                            ${this._('account-succesfully-activated')}
                        </div>
                        <mwc-button class="action-buttons" @click=${this._openLogin} raised>${this._('to-login')}</mwc-button>
                    </symcon-card>
                `;
            }
            else {
                return html`
                    <symcon-card class="regular-card">
                        <loading-backdrop ?hidden=${!this._loading}></loading-backdrop>
                        <div class="right-column side-margin">
                            <div class="regular-text top-text">${this._('code-received', 'toemail', this._displayEmail ? this._('to-email', 'email', this._displayEmail) : '')}</div>
                            <mwc-textfield id="username-input" class="full-width" label=${this._('username')} @keypress=${this._handleConfirmCodePress} value=${this._username} ?disabled=${!!this._username}></mwc-textfield>
                            <mwc-textfield id="activation-code-input" class="full-width" label=${this._('activation-code')} @keypress=${this._handleConfirmCodePress}></mwc-textfield>
                            <mwc-button class="action-buttons" @click=${this._confirmCode}>${this._('confirm')}</mwc-button>
                        </div>
                    </symcon-card>
                `;
            }
        }

        return html`      
            <notification-dialog id="notification-dialog"></notification-dialog>
            
            <div class="outer-container">
                <div class="card-container">
                    ${getContent()}
                </div>
            </div>
        `;
    }



    static get properties() {
        return {
            _username: {
                type: String
            },

            _email: {
                type: String
            },

            _displayEmail: {
                type: String
            },

            _password: {
                type: String
            },

            _activationCode: {
                type: String
            },

            _passwordConfirm: {
                type: String
            },

            registerDone: {
                type: Boolean
            },

            _activationDone: {
                type: Boolean
            },

            _loading: {
                type: Boolean
            },

            resources: {
                type: Object
            }
        };
    }



    constructor() {
        super();

        this._username = '';
        this._email = '';
        this._displayEmail = '';
        this._password = '';
        this._activationCode = '';
        this._passwordConfirm = '';
        this.registerDone = false;
        this._activationDone = false;
        this._loading = false;
        this.resources = {
            en: {
                'not-while-logged-in': 'You cannot register while you are logged in. If you want to create a new account, please log out before.',
                'account-succesfully-activated': 'Your account was succesfully activated. You can login now.',
                'to-login': 'To Login',
                'code-received': 'You have received a code via email{toemail}. Please enter the code here and confirm to activate your account.',
                'to-email': ' to {email}',
                'username': 'Username',
                'activation-code': 'Activation Code',
                'confirm': 'Confirm',
                'e-mail': 'E-Mail',
                'password': 'Password',
                'confirm-password': 'Confirm Password',
                'register': 'Register',
                'passwords-do-not-match': 'The entered passwords do not match. Thus, the registration cannot be continued.',
                'could-not-sign-up': 'Registration was erroneous',
                'could-not-confirm': 'Registration could not be confirmed',
                'register-description': 'Please select username, email address and password for your new account. The password needs to be at least 8 letters long and requires an upper case letter, a lower case letter, and a number.',
                'username-is-empty': 'Please enter a username',
                'invalid-username': 'The entered username is invalid. It probably contains spaces.',
                'no-at-in-username': 'The username must not include an @ symbol. This means that email adresses may not be chosen as username. Even though, you can use your email address for login later on.',
                'username-is-too-long': 'Your selected username is too long. It must not exceed 20 letters.',
                'password-is-empty': 'Please enter a password',
                'email-is-empty': 'Please enter an email address',
                'no-registration-with-plus-notation': 'The email you entered uses the notation for additional licenses on a single email address. Please register with the main email without the plus notation. All sublicenses will be added automatically to the account. Later on, new licenses can be added via the License Management.',
                'invalid-email': 'Please enter a valid email address',
                'activation-code-is-empty': 'Please enter an activation code',
                'email-is-taken': 'The email address is already used by another account',
                'username-is-taken': 'The username already exists',
                'password-does-not-fulfill-requirements': 'The chosen password does not fulfill the security requirements',
                'password-requirements-info': '- At least 8 letters\n- Needs to contain uppercase and lowercase letters\n- Needs to contain numbers',
                'incorrect-activation-code': 'Activation code is incorrect',
                'forum-link': '/account/forum'
            },
            de: {
                'not-while-logged-in': 'Sie können sich nicht registrieren während Sie eingeloggt sind. Wenn Sie ein neues Konto erstellen wollen, loggen Sie sich bitte vorher aus.',
                'account-succesfully-activated': 'Ihr Konto wurde erfolgreich aktiviert. Sie können sich nun anmelden.',
                'this-way-to-login': 'Hier geht es weiter zur Anmeldung:',
                'to-login': 'Zur Anmeldung',
                'code-received': 'Sie haben einen Code per E-Mail{toemail} erhalten, bitte geben Sie ihn hier ein und bestätigen Sie ihn um Ihr Konto zu aktivieren.',
                'to-email': ' an {email}',
                'username': 'Benutzername',
                'activation-code': 'Aktivierungscode',
                'confirm': 'Bestätigen',
                'e-mail': 'E-Mail',
                'password': 'Passwort',
                'confirm-password': 'Passwort bestätigen',
                'register': 'Registrieren',
                'passwords-do-not-match': 'Die eingegebenen Passwörter sind unterschiedlich, daher kann die Registrierung nicht fortgesetzt werden.',
                'could-not-sign-up': 'Registrierung war fehlerhaft',
                'could-not-confirm': 'Registrierung konnte nicht bestätigt werden',
                'register-description': 'Bitte wählen Sie Benutzername, E-Mail-Adresse und Passwort für Ihr neues Konto. Das Passwort muss mindestens 8 Zeichen lang sein und muss Kleinbuchstaben, Großbuchstaben und Zahlen beinhalten.',
                'username-is-empty': 'Bitte geben Sie einen Benutzernamen ein',
                'invalid-username': 'Der eingegebene Benutzername ist ungültig. Er enthält wahrscheinlich Leerzeichen.',
                'no-at-in-username': 'Der Benutzername darf kein @-Symbol beinhalten. Dies bedeutet auch, dass E-Mail-Adressen nicht als Benutzername gewählt werden dürfen. Dennoch können Sie sich später mit Ihrer E-Mail-Adresse anmelden.',
                'username-is-too-long': 'Ihr gewählter Benutzername ist zu lang. Er darf nicht länger als 20 Buchstaben sein.',
                'password-is-empty': 'Bitte geben Sie ein Passwort ein',
                'email-is-empty': 'Bitte geben Sie eine E-Mail-Adresse ein',
                'no-registration-with-plus-notation': 'Die angegebene E-Mail-Adresse verwendet die Notation für zusätzliche Lizenzen auf einer einzelnen Adresse. Bitte registrieren Sie sich mit der Haupt-Email ohne Plusnotation. Alle Unterlizenzen werden bei der Registrierung automatisch Ihrem Konto hinzugefügt. Auch später können weitere Lizenzen über die Lizenzverwaltung hinzugefügt werden.',
                'invalid-email': 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
                'activation-code-is-empty': 'Bitte geben Sie einen Aktivierungscode ein',
                'email-is-taken': 'Die E-Mail-Adresse wird bereits von einem anderen Konto benutzt',
                'username-is-taken': 'Der Benutzername existiert bereits',
                'password-does-not-fulfill-requirements': 'Das gewählte Passwort erfüllt nicht die Sicherheitsanforderungen',
                'password-requirements-info': '- Mindestens 8 Zeichen\n- Muss Groß- und Kleinbuchstaben enthalten\n- Muss Zahlen enthalten',
                'incorrect-activation-code': 'Falscher Aktivierungscode',
                'forum-link': '/konto/forum'
            }
        };
    }



    _handleRegisterPress(event) {
        if (event.key === 'Enter') {
            this._register();
        }
    }



    _register() {
        const username = this.shadowRoot.getElementById('username-input').value.trim();

        if (username === '') {
            this._showNotification(this._('username-is-empty'));
            return;
        }
        
        if (username.length > 20) {
            this._showNotification(this._('username-is-too-long'));
            return;
        }

        if (/\s/.test(username)) {
            this._showNotification(this._('invalid-username'));
            return;
        }

        if (username.includes('@')) {
            this._showNotification(this._('no-at-in-username'));
            return
        }
        
        const email = this.shadowRoot.getElementById('email-input').value.trim();
        if (email === '') {
            this._showNotification(this._('email-is-empty'));
            return;
        }

        const password = this.shadowRoot.getElementById('password-input').value;
        if (password === '') {
            this._showNotification(this._('password-is-empty'));
            return;
        }
        
        if (/^[A-Z0-9._%+-]+\+[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
            this._showNotification(this._('no-registration-with-plus-notation'));
            return;
        }
        
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
            this._showNotification(this._('invalid-email'));
            return;
        }

        if (password !== this.shadowRoot.getElementById('confirm-password-input').value) {
            this._showNotification(this._('passwords-do-not-match'));
            return;
        }

        if ((password.length < 8) || !/.*[a-z].*/.test(password) || !/.*[A-Z].*/.test(password) || !/.*[0-9].*/.test(password)) {
            this._showNotification(this._('password-does-not-fulfill-requirements'), this._('password-requirements-info'));
            return;
        }

        this._loading =  true;
        let attributeList = [];

        let dataEmail = {
            Name: 'email',
            Value: email
        };

        let attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(dataEmail);

        attributeList.push(attributeEmail);

        this.USER_POOL.signUp(username, password, attributeList, null, (error, result) => {
            if (error) {
                switch (error.name) {
                    case 'UserLambdaValidationException':
                        if (error.message.includes('E-Mail is already taken')) {
                            this._showNotification(this._('email-is-taken'));
                        }
                        else if (error.message.includes('Username is already taken')) {
                            this._showNotification(this._('username-is-taken'));
                        }
                        else {
                            this._showError(this._('could-not-sign-up'), error);
                        }
                        break;
                    
                    case 'UsernameExistsException':
                        this._showNotification(this._('username-is-taken'));
                        break;
                        
                    default:
                        this._showError(this._('could-not-sign-up'), error);
                        break;
                }
                this._loading =  false;
            }
            else {
                this.registerDone =  true;
                this._loading =  false;
                this._username = username;
            }
        });
    }



    _handleConfirmCodePress(event) {
        if (event.key === 'Enter') {
            this._confirmCode();
        }
    }



    _confirmCode() {
        const username = this.shadowRoot.getElementById('username-input').value.trim();
        if (username === '') {
            this._showNotification(this._('username-is-empty'));
            return;
        }

        if (username.length > 20) {
            this._showNotification(this._('username-is-too-long'));
            return;
        }

        if (/\s/.test(username)) {
            this._showNotification(this._('invalid-username'));
            return;
        }
        
        const activationCode = this.shadowRoot.getElementById('activation-code-input').value.trim();
        if (activationCode === '') {
            this._showNotification(this._('activation-code-is-empty'));
            return;
        }
        
        this._loading =  true;
        let userData = {
            Username : username,
            Pool : this.USER_POOL
        };
        let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        cognitoUser.confirmRegistration(activationCode, true, (error, result) => {
            if (error) {
                switch (error.name) {
                    case 'CodeMismatchException':
                        this._showNotification(this._('incorrect-activation-code'));
                        break;
                        
                    default:
                        this._showError(this._('could-not-confirm'), error);
                        break;                        
                }
                this._loading =  false;
            }
            else {
                this._activationDone =  true;
                this._loading =  false;
            }
        });
    }



    _openLogin() {
        const parameters = querystring.parse(location.search.substr(1));
        if (parameters && parameters.forward) {
            switch (parameters.forward) {
                case 'forum':
                    location.replace(location.protocol + '//' + location.host + this._('forum-link') + '?sso=' + parameters.sso + '&sig=' + parameters.sig);
                    break;

                default:
                    location.replace(location.protocol + '//' + location.host);
                    break;
            }
        }
        else {
            location.replace(location.protocol + '//' + location.host);
        }
    }
    
    
    
    firstUpdated() {
        const parameters = querystring.parse(location.search.substr(1));
        if (parameters) {
            if (parameters.forward === 'forum') {
                hideMenu();
            }
            if (parameters.email) {
                const emailInput = this.shadowRoot.getElementById('email-input');
                if (emailInput) {
                    emailInput.value= parameters.email;
                }
            }

            if (parameters.username) {
                this._username =  parameters.username;
            }

            if (parameters.displayemail) {
                this._displayEmail =  parameters.displayemail;
            }
        }
    }
}

customElements.define('register-page', RegisterPage);
