export const determineLanguage = () => {
    if (navigator.languages) {
        for (let language of navigator.languages) {
            if (language.substr(0, 2) === 'de') {
                return 'de';
            }
            else if (language.substr(0, 2) === 'en') {
                return 'en';
            }
        }
    }

    return 'en';
}

export const DetermineLanguageMixin = (superClass => {
    class DetermineLanguageMixin extends superClass {

        constructor(...args) {
            super(...args);

            this.language = determineLanguage();
        }

    }

    return DetermineLanguageMixin;
});
