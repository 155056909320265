import {LitElement, html, css} from 'lit-element';
import '@material/mwc-dialog';

import './notification-dialog';
import {CommonStyles} from '../mixins/common-styles.js';
import {TranslateMixin} from '../mixins/translate-mixin';
import {ShowErrorMixin} from '../mixins/show-error-mixin';

class NotificationDialog extends ShowErrorMixin(TranslateMixin(CommonStyles(LitElement))) {
    static get is() {
        return 'send-activation-again-dialog';
    }



    static get properties() {
        return {
            cognitoUser: {
                type: Object
            },

            resources: {
                type: Object
            }
        };
    }



    constructor() {
        super();

        this.cognitoUser = null;

        this.resources = {
            en: {
                'account-not-activated': 'Your account was not activated yet. Do you want us to send the activation key again via email to activate it now?',
                'yes': 'Yes',
                'no': 'No',
                'confirm-link': '/account/confirm',
                'cognito-user-missing': 'Cognito User was not set',
                'failed-sending-activation-code': 'Resending the activation code has failed'
            },
            de: {
                'account-not-activated': 'Ihr Konto wurde noch nicht aktiviert. Sollen wir Ihnen erneut einen Aktivierungscode per E-Mail schicken um ihn jetzt zu aktivieren?',
                'yes': 'Ja',
                'no': 'Nein',
                'confirm-link': '/konto/bestaetigen',
                'cognito-user-missing': 'Cognito User wurde nicht gesetzt',
                'failed-sending-activation-code': 'Erneutes Verschicken des Aktivierungscodes ist fehlgeschlagen'
            }
        };
    }



    render() {
        return html`
            <notification-dialog id="notification-dialog"></notification-dialog>

            <mwc-dialog id="send-activation-again-dialog" class="left-column thin-dialog" scrimClickAction="">
                <div class="side-margin top-text">${this._('account-not-activated')}</div>
                <mwc-button slot="secondaryAction" dialogAction="cancel">${this._('no')}</mwc-button>
                <mwc-button slot="primaryAction" @click=${this._sendActivationAgain}>${this._('yes')}</mwc-button>
            </mwc-dialog>
        `;
    }



    show() {
        this.shadowRoot.getElementById('send-activation-again-dialog').show();
    }



    _sendActivationAgain() {
        if (this.cognitoUser === null) {
            this._showError(this._('cognito-user-missing'));
            return;
        }

        this.cognitoUser.resendConfirmationCode((error, response) => {
            if (error) {
                this._showError(this._('failed-sending-activation-code'), error);
            }
            else {
                location.replace(location.protocol + '//' + location.host + this._('confirm-link') + '?username=' + encodeURIComponent(this.cognitoUser.username) + '&displayemail=' + encodeURIComponent(response.CodeDeliveryDetails.Destination));
            }
        });
    }
}

customElements.define(NotificationDialog.is, NotificationDialog);
