import {UserPoolMixin} from "./user-pool-mixin";
import {TranslateMixin} from "./translate-mixin";
import { makeXMLRequest } from "./xml-request-mixin";

export const makeLambdaRequest = (address, mode, parameters, currentUser, authorization = true, body) => {
    return new Promise((accept, reject) => {
                
        let sendRequest = (session) => {
            // Use prod API for main page but dev API otherwise
            let url = (location.hostname === 'account.symcon.de') ? 'https://api.symcon.de/' : 'https://api-dev.symcon.de/';
            
            url += address;

            let headers = undefined;
            if (session) {
                headers = {
                    Authorization: session.getIdToken().getJwtToken()
                };
            }

            if (parameters) {
                const codedParameters = [];
                for (const parameter in parameters) {
                    if (parameters.hasOwnProperty(parameter)) {
                        codedParameters.push(`${parameter}=${encodeURIComponent(parameters[parameter])}`);
                    }
                }
                if (codedParameters.length > 0) {
                    url = `${url}?${codedParameters.join('&')}`;
                }
            }

            makeXMLRequest(url, mode, headers, body).then(
                accept,
                (error) => {
                    reject({
                        configured: true,
                        message: error.response,
                        status: error.status
                    });
                }
            )
        };
        
        if (authorization) {
            if (!currentUser) {
                reject({
                    configured: false,
                    message: ''//this._('lambda-request-mixin-no-current-user')
                });
                return;
            }

            currentUser.getSession((error, session) => {
                if (error) {
                    reject({
                        configured: true,
                        message: error
                    });
                    return;
                }

                if (!session.isValid()) {
                    reject({
                        configured: true,
                        message: ''//this._('lambda-request-mixin-invalid-session')
                    });
                    return;
                }
                
                // console.log(session.getIdToken().getJwtToken()); // Uncomment if you want the Jwt Token for some debugging

                sendRequest(session);
            });
        }
        else {
            sendRequest();
        }
    });
}

export const LambdaRequestMixin = (superClass => {
    class LambdaRequestMixin extends TranslateMixin(UserPoolMixin(superClass)) {

        constructor() {
            super();

            if (!this.resources) {
                this.resources = {
                    en: {},
                    de: {}
                }
            }
            
            this.resources.en['lambda-request-mixin-no-current-user'] = 'No current user';
            this.resources.en['lambda-request-mixin-invalid-session'] = 'Session is invalid';

            this.resources.de['lambda-request-mixin-no-current-user'] = 'Kein aktueller Benutzer';
            this.resources.de['lambda-request-mixin-invalid-session'] = 'Sitzung ist ungültig';
        }
        
        makeLambdaRequest(address, mode, parameters, authorization = true, body) {
            return makeLambdaRequest(address, mode, parameters, this.USER_POOL.getCurrentUser(), authorization, body);
        }
    }

    return LambdaRequestMixin;
});
