import {LitElement, html, css} from 'lit-element';
import '@material/mwc-textfield';
import '@material/mwc-button';
import '@material/mwc-textarea';
import '@material/mwc-switch';
import '@material/mwc-formfield';

import DiffMatchPatch from '../../util/diff-match-patch.js';
import {UserPoolMixin} from "../../mixins/user-pool-mixin";
import '../../components/loading-backdrop';
import '../../components/notification-dialog';
import '../../components/symcon-card';
import '../../components/symcon-expansion-panel';
import {CommonStyles} from '../../mixins/common-styles';
import {LambdaRequestMixin} from "../../mixins/lambda-request-mixin";
import {ShowErrorMixin} from "../../mixins/show-error-mixin";
import {TranslateMixin} from "../../mixins/translate-mixin";
import {DynamicTextareaResizeMixin} from '../../mixins/dynamic-textarea-resize-mixin';

class ReviewCard extends DynamicTextareaResizeMixin(CommonStyles(TranslateMixin(ShowErrorMixin(LambdaRequestMixin(UserPoolMixin(LitElement)))))) {

    static get styles() {
        return [super.styles, css`
            :host {
                width: 100%;
            }

            .diff-display {
                white-space: pre-wrap;
                display: table;
            }

            .line-breaks {
                white-space: pre-wrap;
                width: 500px;
            }

            mwc-switch {
                padding-bottom: 12px;
            }

            .red {
                color: red;
            }

            .insert {
                color: green;
            }

            .delete {
                color: red;
                text-decoration: line-through;
            }

            .button-row {
                padding-top: 10px;
            }

            mark {
                background: none;
            }

            mwc-formfield {
                --mdc-theme-text-primary-on-background: white;
            }
        `];
    }

    render() {
        const renderCheckboxes = () => {
            const result = [];
            for (let i = 0; i < this.NUMBER_OF_CHECKS; i++) {
                result.push(html`
                    <mwc-formfield label=${this._('review-check-' + i)}>
                        <mwc-switch id=${'review-check-' + i}></mwc-switch>
                    </mwc-formfield>
                `);
            }
            return result;
        }

        let previousGitURL = this.module.previous_git_url;
        let gitURL = this.module.git_url;

        if (previousGitURL && (previousGitURL.endsWith('.git'))) {
            previousGitURL = previousGitURL.substring(0, previousGitURL.length - 4);
        }

        if (gitURL.endsWith('.git')) {
            gitURL = gitURL.substring(0, gitURL.length - 4);
        }

        return html`        
            <notification-dialog id="notification-dialog"></notification-dialog>

            <symcon-card class="regular-card">
                <div class="side-margin left-column">
                    <loading-backdrop ?hidden=${!this._loading}></loading-backdrop>
                    <mwc-textfield class="full-width" label=${this._('bundle-id')} value=${this.module.bundle} disabled></mwc-textfield>
                    <mwc-textfield class="full-width" label=${this._('author')} value=${this.module.account} disabled></mwc-textfield>
                    <h4 class="text-color">${this._('current-release')}</h4>
                    <mwc-textfield class="full-width" label=${this._('git-url')} value=${gitURL} disabled></mwc-textfield>
                    <mwc-textfield class="full-width" label=${this._('git-commit')} value=${this.module.git_commit} disabled></mwc-textfield>
                    <div class="button-row row">
                        <a href=${gitURL + '/tree/' + this.module.git_commit} target="_blank" class="no-link-style">
                            <mwc-button raised>${this._('to-new-release')}</mwc-button>
                        </a>
                        <mwc-button @click=${this._download} raised>${this._('download')}</mwc-button>
                    </div>
                    <div class="full-width left-column" ?hidden=${this._decided}>
                        <h4 class="text-color">${this._('previous-release')}</h4>
                        <div class="text-color" ?hidden=${this._hasPreviousRelease(previousGitURL)}>${this._('no-previous-release')}</div>
                        <div class="full-width left-column" ?hidden=${!this._hasPreviousRelease(previousGitURL)}>
                            <mwc-textfield class="full-width" label=${this._('git-url')} value=${previousGitURL} disabled></mwc-textfield>
                            <mwc-textfield class="full-width" label=${this._('git-commit')} value=${this.module.previous_git_commit} disabled></mwc-textfield>
                            <mwc-textarea class="full-width" label=${this._('remarks')} value=${this.module.previous_remark} disabled ?hidden=${!this.module.previous_remark}></mwc-textarea>
                            <div class="button-row row" ?hidden=${this._isEqual(previousGitURL, gitURL, this.module.previous_git_commit, this.module.git_commit)}>
                                <a href=${previousGitURL + '/tree/' + this.module.previous_git_commit} target="_blank" class="no-link-style">
                                    <mwc-button raised>${this._('to-previous-release')}</mwc-button>
                                </a>
                                <a href=${previousGitURL + '/compare/' + this.module.previous_git_commit + '...' + this.module.git_commit} target="_blank" class="no-link-style">
                                    <mwc-button raised>${this._('to-diff')}</mwc-button>
                                </a>
                            </div>
                            <div class="red" ?hidden=${!this._isEqual(previousGitURL, gitURL, this.module.previous_git_commit, this.module.git_commit)}>${this._('same-as-previous')}</div>
                        </div>
                        <h4 class="text-color">${this._('previous-declined')}</h4>
                        <div class="text-color" ?hidden=${this._hasPreviousDeclined(this.module.declined_git_url, this.module.previous_id, this.module.declined_id)}>${this._('no-previous-declined')}</div>
                        <div class="full-width left-column" ?hidden=${!this._hasPreviousDeclined(this.module.declined_git_url, this.module.previous_id, this.module.declined_id)}>
                            <mwc-textfield class="full-width" label=${this._('git-url')} value=${this.module.declined_git_url} disabled></mwc-textfield>
                            <mwc-textfield class="full-width" label=${this._('git-commit')} value=${this.module.declined_git_commit} disabled></mwc-textfield>
                            <mwc-textarea class="full-width" label=${this._('remarks')} value=${this.module.declined_remark} disabled></mwc-textarea>
                            <div class="button-row row">
                                <a href=${this.module.declined_git_url + '/tree/' + this.module.declined_git_commit} target="_blank" class="no-link-style">
                                    <mwc-button raised>${this._('to-declined')}</mwc-button>
                                </a>
                                <a href=${this.module.declined_git_url + '/compare/' + this.module.declined_git_commit + '...' + this.module.git_commit} target="_blank" class="no-link-style">
                                    <mwc-button raised>${this._('to-diff')}</mwc-button>
                                </a>
                            </div>
                        </div>
                        <h4 class="text-color">${this._('localizations')}</h4>
                        ${this.module.localizations.map((localization) => {
                            return html`
                                <div class="full-width">
                                    <symcon-expansion-panel class="full-width">
                                        <div slot="header" class="center-row">
                                            <div class="text-color">${this._languageToNiceWord(localization.language)}</div>
                                        </div>
                                        <div class="side-margin left-column">
                                            <h4 class="text-color">${this._('name')}</h4>
                                            <div>
                                                ${this._computeDiff('name', this.module.localizations.indexOf(localization)).map((diffElement) => {
                                                    return html`<span class=${this._computeDiffClass(diffElement[0])}>${diffElement[1]}</span>`
                                                })}
                                            </div>
                                            <h4 class="text-color">${this._('description')}</h4>
                                            <div>
                                                ${this._computeDiff('description', this.module.localizations.indexOf(localization)).map((diffElement) => {
                                                    return html`<span class=${this._computeDiffClass(diffElement[0])}>${diffElement[1]}</span>`
                                                })}
                                            </div>
                                            <h4 class="text-color">${this._('release-notes')}</h4>
                                            <p class="diff-display">
                                                ${this._computeDiff('release_notes', this.module.localizations.indexOf(localization)).map((diffElement) => {
                                                    return html`<span class=${this._computeDiffClass(diffElement[0])}>${diffElement[1]}</span>`
                                                })}
                                            </p>
                                            <h4 class="text-color">${this._('documentation-link')}</h4>
                                            <a href=${localization.documentation} target="_blank">${localization.documentation}</a>
                                        </div>
                                    </symcon-expansion-panel>
                                </div>
                            `;
                        })}
                        <h4 class="text-color">${this._('categories')}</h4>
                        <div class="center-row">
                            ${this.module.categories.map((category) => {
                                return html`
                                    <div style="height: 40px; padding-right: 3px;">
                                        <span class=${'regular-text ' + this._getCategoryStyle(category)}>${this._categoryToName(category)}</span>
                                    </div>
                                `;
                            })}
                            ${this.module.categories.map((category) => {
                                if (this._isCategoryRemoved(category)) {
                                    return html`
                                        <div style="height: 40px; padding-right: 3px;">
                                            <span class="regular-text delete">${this._categoryToName(category)}</span>
                                        </div>
                                    `
                                }
                                else {
                                    return html``;
                                }
                            })}
                        </div>
                        ${renderCheckboxes()}
                    </div>
                    <mwc-textarea id="remark-input" class="full-width" label=${this._('remarks')} ?disabled=${this._decided} @input=${this._resizeTextarea}></mwc-textarea>
                    ${this._decided ? html`
                        <div class="text-color">${this._(this._accepted ? 'module-accepted' : 'module-declined')}</div>
                    ` : html`
                        <div class="row right-element">
                            <mwc-button @click=${this._reject}>${this._('reject')}</mwc-button>
                            <mwc-button @click=${this._accept}>${this._('accept')}</mwc-button>
                        </div>
                    `}
                </div>
            </symcon-card>
        `;
    }



    static get properties() {
        return {
            NUMBER_OF_CHECKS: {
                type: Number
            },

            DIFF_MATCH_PATCH: {
                type: Object
            },

            _accepted: {
                type: Boolean
            },
            
            _decided: {
                type: Boolean
            },
            
            _loading: {
                type: Boolean
            },

            module: {
                type: Object
            },

            availableCategories: {
                type: Object
            },
            
            resources: {
                type: Object
            }
        };
    }



    constructor() {
        super();

        this.NUMBER_OF_CHECKS = 14;
        this.DIFF_MATCH_PATCH = new DiffMatchPatch();
        this._accepted = false;
        this._decided = false;
        this._loading = false;
        this.module = null;
        this.availableCategories = {};
        this.resources = {
            en: {
                'bundle-id': 'Bundle ID',
                'author': 'Author',
                'categories': 'Categories',
                'unknown-category': 'Unknown Category',
                'language': 'Language',
                'git-url': 'GIT-URL',
                'git-commit': 'GIT-Commit',
                'localizations': 'Localizations',
                'name': 'Name',
                'description': 'Description',
                'release-notes': 'Release Notes',
                'documentation-link': 'Link to documentation',
                'german': 'German',
                'english': 'English',
                'current-release': 'Current Release',
                'previous-release': 'Previous Release',
                'previous-declined': 'Previous Declined',
                'to-new-release': 'To new release',
                'download': 'Download',
                'to-previous-release': 'To previous release',
                'to-declined': 'To declined submission',
                'to-diff': 'To diff',
                'remarks': 'Remarks',
                'accept': 'Accept',
                'reject': 'Reject',
                'module-accepted': 'The module was accepted',
                'module-declined': 'The module was declined',
                'could-not-post-status': 'Could not post status',
                'no-previous-release': 'No previous release',
                'no-previous-declined': 'No submission was declined since the last release',
                'same-as-previous': 'The current version is the same as the previous (The texts in localizations could be changed however)',
                'review-check-0': 'The module has no "IPS", "Symcon" or similar as part of its name:\n Check the localized names',
                'review-check-1': 'The categories and description fit the module:\n Check the localized description and the selected categories',
                'review-check-2': 'The module has a documentation:\nClick the documentation link and check if there is a documentation',
                'review-check-3': 'The module is localized for every localization:\n Check the form.json and the locale.json if multiple languages are properly handled\nIf a module only offers one localization, it is fine to skip the locale.json and use that language directly',
                'review-check-4': 'The module does not access local data to extract and/or manipulate personal data:\n Manual check for all PHP commands with "file_" and "curl"',
                'review-check-5': 'The module only uses command line commands only as expected by the user:\n Search for "exec" (including IPS_Execute)',
                'review-check-6': 'The module only accesses its own objects or objects that were assigned to it, e.g. via SelectVariable:\n Check all occurences of "IPS_Get"',
                'review-check-7': 'Communication with the Splitter/IO is properly done via Send/ReceiveData:\n Search for "IPS_Get" and check if parent instances are accessed directly',
                'review-check-8': 'Object properties that are in the sovereignty of the user are not changed by the module:\n Search for "IPS_Set"',
                'review-check-9': 'New instances are only created via configurator or similar, other objects are only created under the instance itself or as expected by the user:\n Search for "IPS_Create"',
                'review-check-10': 'The module does not delete external objects:\n Search for "IPS_Delete"',
                'review-check-11': 'Lists/Trees that use noValuesFromConfiguration must fill the list as expected by a user:\n Search for "noValuesFromConfiguration"',
                'review-check-12': 'Logging via IPS_LogMessage is forbidden:\n Search for "IPS_LogMessage"',
                'review-check-13': 'The instances can be created without error:\n Create each instance from the module, feel free to play around a bit and check that the localization is correct',
                'all-checks-required': 'All checks are required to accept a submission',
                'remark-required-for-decline': 'When declining a submission, a remark is required',
                'download-failed': 'Download failed'
            },
            de: {
                'bundle-id': 'Bundle ID',
                'author': 'Autor',
                'categories': 'Kategorien',
                'unknown-category': 'Unbekannte Kategorie',
                'language': 'Sprache',
                'git-url': 'GIT-URL',
                'git-commit': 'GIT-Commit',
                'localizations': 'Lokalisierungen',
                'name': 'Name',
                'description': 'Beschreibung',
                'release-notes': 'Versionsinformationen',
                'documentation-link': 'Link zur Dokumentation',
                'german': 'Deutsch',
                'english': 'Englisch',
                'current-release': 'Aktuelle Veröffentlichung',
                'previous-release': 'Vorherige Veröffentlichung',
                'previous-declined': 'Vorherige abgelehnte Einreichung',
                'to-new-release': 'Zu neuer Veröffentlichung',
                'download': 'Download',
                'to-previous-release': 'Zu vorherigen Veröffentlichung',
                'to-declined': 'Zu abgelehnter Einreichung',
                'to-diff': 'Zum Diff',
                'remarks': 'Anmerkungen',
                'accept': 'Akzeptieren',
                'reject': 'Ablehnen',
                'module-accepted': 'Das Modul wurde akzeptiert',
                'module-declined': 'Das Modul wurde abgelehnt',
                'could-not-post-status': 'Konnte Status nicht posten',
                'no-previous-release': 'Keine vorherige Veröffentlichung',
                'no-previous-declined': 'Seit der letzten Veröffentlichung wurde keine Einreichung abgelehnt',
                'same-as-previous': 'Die aktuelle Version ist die gleiche wie die vorherige (Es könnten allerdings Texte in den Lokalisierungen angepasst worden sein)',
                'review-check-0': 'Das Modul hat kein "IPS", "Symcon" oder ähnliches als Teil seines Namens:\nPrüfe die lokalisierten Namen des Moduls',
                'review-check-1': 'Die Kategorien und Beschreibungen passen zum Modul:\nPrüfe die lokalisierten Beschreibungen und ausgewählten Kategorien',
                'review-check-2': 'Das Modul hat eine Dokumentation:\nKlicke auf Dokumentations-Link und prüfe ob dort eine Dokumentation ist',
                'review-check-3': 'Das Modul ist für jede Lokalisierung lokalisiert:\nPrüfe die form.json und locale.json ob mehrere Sprachen korrekt gehändelt werden.\nBietet das Modul nur eine Lokalisierung, so ist es valide, wenn alle Texte direkt in dieser Sprache sind und die locale.json nicht verwendet wird',
                'review-check-4': 'Das Modul greift nicht auf persönliche Daten zu und/oder manipuliert diese:\nManuelle Prüfung aller Aufrufe mit "file_" und "curl"',
                'review-check-5': 'Das Modul verwendet Kommandozeilenbefehle nur wie vom Benutzer erwartet:\nSuche nach "exec" (dies beinhaltet IPS_Execute)',
                'review-check-6': 'Das Modul greift nur auf eigene Objekte oder Objekte die ihm zugewiesen wurden zu, z.B. via SelectVariable:\nPrüfe alle Vorkommnisse von "IPS_Get"',
                'review-check-7': 'Die Kommunikation mit dem Splitter/IO erfolgt wie vorgesehen über Send/ReceiveData:\nSuche nach "IPS_Get" und prüfe ob direkt auf übergeordnete Instanzen zugegriffen wird',
                'review-check-8': 'Objekteigenschaften, welche in der Hoheit des Benutzers liegen, werden nicht durch das Modul verändert:\n Suche nach "IPS_Set"',
                'review-check-9': 'Neue Instanzen werden nur über einen Konfigurator oder vergleichbares erstellt, andere Objekte werden nur unterhalb der Instanz oder wie vom Benutzer erwartet erstellt:\nSuche nach "IPS_Create"',
                'review-check-10': 'Das Modul löscht keine externen Objekte:\nSuche nach "IPS_Delete"',
                'review-check-11': 'Lists/Trees, die noValuesFromConfiguration verwenden, müssen wie vom Benutzer erwartet gefüllt werden:\n Suche nach "noValuesFromConfiguration"',
                'review-check-12': 'Logging über IPS_LogMessage ist verboten:\n Suche nach "IPS_LogMessage"',
                'review-check-13': 'Die Instanzen können ohne Fehler erstellt werden:\nErstelle jede Instanz des Moduls, spiele gerne damit herum und prüfe ob die Lokalisierung korrekt ist',
                'all-checks-required': 'Alle Prüfungen müssen bestätigt werden um eine Einreichung anzunehmen',
                'remark-required-for-decline': 'Bei einer Ablehnung ist eine Anmerkung erforderlich',
                'download-failed': 'Download fehlgeschlagen'
            }
        };
    }



    _isEqual() {
        for (let i = 0; i < arguments.length; i += 2) {
            if (arguments[i] !== arguments[i + 1]) {
                return false;
            }
        }

        return true;
    }



    _computeDiffClass(type) {
        switch (type) {
            case 1:
                return 'insert';

            case -1:
                return 'delete';

            default:
                return 'text-color';
        }
    }



    _getCategoryStyle(category) {
        if (this.module.previousCategories.includes(category)) {
            return '';
        }
        else {
            return 'insert';
        }
    }



    _isCategoryRemoved(category) {
        return !this.module.categories.includes(category);
    }



    _hasPreviousRelease(url) {
        return !!url;
    }
    
    
    
    _hasPreviousDeclined(url, previousID, declinedID) {
        return (declinedID > previousID) && this._hasPreviousRelease(url);
    }
    
    
    
    _languageToNiceWord(language) {
        switch (language) {
            case 'de':
                return this._('german');
                
            case 'en':
                return this._('english');
                
            default:
                return language;
        }
    }



    _categoryToName(categoryID) {
        let name = this._('unknown-category');
        
        if (this.availableCategories) {
            let category = this.availableCategories[categoryID];
            if (category) {
                name = category.name;
            }
        }
        
        
        if (categoryID !== this.module.categories[this.module.categories.length - 1]) {
            return name + ',';
        }
        else {
            return name;
        }
    }



    _download() {
        this._loading = true;
        this.makeLambdaRequest('publish/review-download', 'GET', {
            releaseID: this.module.id
        }).then(
            (url) => {
                window.open(url);
            },
            (error) => {
                this._showError(this._('download-failed'), error);
            }
        ).finally(() => {
            this._loading = false;
        })
    }
    
    
    
    _decide(accept) {
        this._loading = true;
        this.makeLambdaRequest('publish/status', 'POST', {
            releaseID: this.module.id,
            status: accept ? 'released' : 'declined',
            remark: this.shadowRoot.getElementById('remark-input').value
        }).then(
            () => {
                this._decided = true;
                this._accepted = accept;
                this._loading = false;
            },
            (error) => {
                this._showError(this._('could-not-post-status'), error);
                this._loading = false;
            }
        )
    }



    _computeDiff(fieldName, index, containerOld = 'previousLocalizations', containerNew = 'localizations') {
        let newText = this.module[containerNew][index][fieldName];
        let oldText = '';
        if (this.module[containerOld][index]) {
            oldText = this.module[containerOld][index][fieldName];
        }
        let diffs = this.DIFF_MATCH_PATCH.diff_main(oldText, newText);
        this.DIFF_MATCH_PATCH.diff_cleanupSemantic(diffs);
        return diffs;
    }
    
    
    
    _accept() {
        for (let i = 0; i < this.NUMBER_OF_CHECKS; i++) {
            if (!this.shadowRoot.getElementById('review-check-' + i).selected) {
                this._showNotification(this._('all-checks-required'));
                return;
            }
        }
        this._decide(true);
    }



    _reject() {
        if (!this.shadowRoot.getElementById('remark-input').value) {
            this._showNotification(this._('remark-required-for-decline'));
            return;
        }
        this._decide(false);
    }
}

customElements.define('review-card', ReviewCard);
