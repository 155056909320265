import {DetermineLanguageMixin} from "./determine-language-mixin";

// Using function instead of () => so we can access the arguments parameter
export const translate = function(resources, language) {
    if (resources) {
        let message = resources[language][arguments[2]];
        for (let i = 3; i < arguments.length; i += 2) {
            let newMessage = message.replace(`{${arguments[i]}}`, arguments[i + 1]);
            while (newMessage !== message) {
                message = newMessage;
                newMessage = message.replace(`{${arguments[i]}}`, arguments[i + 1]);
            }
        }
        return message;
    }
    else {
        return '';
    }
}

export const TranslateMixin = (superClass => {
    class TranslateMixin extends DetermineLanguageMixin(superClass) {

        _() {
            return translate(this.resources, this.language, ...arguments);
        }

    }

    return TranslateMixin;
});
