import {LitElement, html, css} from 'lit-element';
import '@material/mwc-dialog';
import '@material/mwc-button';
import '@material/mwc-select';
import '@material/mwc-list/mwc-list-item';
import '@material/mwc-radio';
import '@material/mwc-formfield';
import '@material/mwc-textfield';
import '@material/mwc-snackbar';

const querystring = require('querystring');

import {UserPoolMixin} from "../../mixins/user-pool-mixin";
import '../../components/account-selection';
import '../../components/loading-card';
import '../../components/loading-backdrop';
import '../../components/notification-dialog';
import '../../components/symcon-card';
import {CommonStyles} from '../../mixins/common-styles';
import './module-card';
import {LambdaRequestMixin} from "../../mixins/lambda-request-mixin";
import {ShowErrorMixin} from "../../mixins/show-error-mixin";
import {TranslateMixin} from "../../mixins/translate-mixin";
import {OrderReleasesMixin} from '../../mixins/order-releases-mixin';

class Module extends OrderReleasesMixin(CommonStyles(TranslateMixin(ShowErrorMixin(LambdaRequestMixin(UserPoolMixin(LitElement)))))) {

    static get styles() {
        return [
            super.styles,
            css`
                h1.no-uppercase {
                    text-transform: none;
                }
                
                .back-link-container {
                    font-weight: 300;
                    font-size: 16px;
                    padding: 25px 0 20px 0;
                    margin: 0 0 10px;
                }
                
                .back-link {
                    color: #32c9ac;
                    text-decoration: none !important;
                }

                .left-element {
                    align-self: flex-start;
                }

                .link-field {
                    max-width: 50%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                .use-field {
                    padding-left: 12px;
                }

                .sub-title {
                    padding: 5px 0;
                }
            `
        ];
    }

    render() {
        const onUpdate = (event) => {
            if (event.detail.channel) {
                this._channel = event.detail.channel;
            }

            this._updateReleases();
        };

        const getInviteHTML = (invites) => {
            if (invites.length === 0) {
                return html`
                    <div class="regular-text bottom-padding">${this._('no-open-invites')}</div>
                `;
            }

            const result = [];

            const emailInvites = invites.filter((invite) => {
                return invite.type === 'mail';
            });
            const accountInvites = invites.filter((invite) => {
                return invite.type === 'account';
            });
            const codeInvites = invites.filter((invite) => {
                return invite.type === 'code';
            });

            if (emailInvites.length > 0) {
                result.push(html`
                    <h3 class="text-color no-margin sub-title">${this._('email-invites')}</h3>
                `);
                result.push(emailInvites.map((item) => {
                    return html`
                        <div class="center-row">
                            <span class="regular-text">${item.target}</span>
                            <mwc-icon-button icon="close" @click=${this._generateOpenRemoveInvitationDialog(item)} class="text-color"></mwc-icon-button>
                        </div>
                    `;
                }));
            }

            if (accountInvites.length > 0) {
                result.push(html`
                    <h3 class="text-color no-margin sub-title">${this._('account-invites')}</h3>
                `);
                result.push(accountInvites.map((item) => {
                    return html`
                        <div class="center-row">
                            <span class="regular-text">${item.target}</span>
                            <mwc-icon-button icon="close" @click=${this._generateOpenRemoveInvitationDialog(item)} class="text-color"></mwc-icon-button>
                        </div>
                    `;
                }));
            }

            if (codeInvites.length > 0) {
                result.push(html`
                    <h3 class="text-color no-margin sub-title">${this._('code-invites')}</h3>
                `);
                result.push(codeInvites.map((item) => {
                    const link = this._('invite-link', 'bundle', this.bundleID, 'code', item.code);
                    return html`
                        <div class="center-row">
                            <span class="regular-text link-field">${link}</span>
                            <span class="regular-text use-field">${(item.uses === 0) ? this._('infinite-use-short') : this._('single-use-short')}</span>
                            <mwc-icon-button icon="content_copy" @click=${this._generateCopyInviteLink(link)} class="text-color"></mwc-icon-button>
                            <mwc-icon-button icon="close" @click=${this._generateOpenRemoveInvitationDialog(item)} class="text-color"></mwc-icon-button>
                        </div>
                    `;
                }));
            }

            return result;
        }

        return html`
      
            <notification-dialog id="notification-dialog"></notification-dialog>

            <mwc-dialog id="new-release-dialog" class="thin-dialog" modal>
                <div class="left-column no-bottom-margin">
                    <mwc-formfield label=${this._('use-stable-as-template')}>
                        <mwc-radio class="dialog-radio-button" name="template-selection" @change=${(event) => { this._createNewSelection = 'from-stable'; }} ?disabled=${!this._releaseExists('stable')}></mwc-radio>
                    </mwc-formfield>
                    <mwc-formfield label=${this._('use-beta-as-template')}>
                        <mwc-radio class="dialog-radio-button" name="template-selection" @change=${(event) => { this._createNewSelection = 'from-beta'; }} ?disabled=${!this._releaseExists('beta')}></mwc-radio>
                    </mwc-formfield>
                    <mwc-formfield label=${this._('use-testing-as-template')}>
                        <mwc-radio class="dialog-radio-button" name="template-selection" @change=${(event) => { this._createNewSelection = 'from-testing'; }} ?disabled=${!this._releaseExists('testing')}></mwc-radio>
                    </mwc-formfield>
                    <mwc-formfield id="from-scratch-radio" label=${this._('create-new-from-scratch')}>
                        <mwc-radio class="dialog-radio-button" name="template-selection" @change=${(event) => { this._createNewSelection = 'from-scratch'; }} ?checked=${this._createNewSelection === 'from-scratch'}></mwc-radio>
                    </mwc-formfield>
                </div>
                <mwc-button slot="secondaryAction" dialogAction="cancel">${this._('abort')}</mwc-button>
                <mwc-button slot="primaryAction" dialogAction="confirm" @click=${this._createNewRelease}>${this._('ok')}</mwc-button>
            </mwc-dialog>
            
            <mwc-dialog id="link-dialog" class="left-column thin-dialog" scrimClickAction="">
                <div class="row">
                    <mwc-textfield class="full-width dialog-input" label=${this._('invite-link')} disabled value=${this._inviteLink}></mwc-textfield>
                    <mwc-icon-button icon="content_copy" @click=${this._generateCopyInviteLink(this._inviteLink)}></mwc-icon-button>
                </div>
                <div>${this._('invite-link-description')}</div>
                <mwc-button slot="primaryAction" dialogAction="cancel">${this._('ok')}</mwc-button>
            </mwc-dialog>
            
            <mwc-dialog id="remove-tester-dialog" class="left-column thin-dialog" scrimClickAction="">
                <loading-backdrop ?hidden=${!this._loadingDialog}></loading-backdrop>
                <div>${this._('remove-tester-check', 'tester', this._removedTester)}</div>
                <mwc-button slot="secondaryAction" dialogAction="cancel">${this._('no')}</mwc-button>
                <mwc-button slot="primaryAction" @click=${this._removeTester}>${this._('yes')}</mwc-button>
            </mwc-dialog>
            
            <mwc-dialog id="remove-invite-dialog" class="left-column thin-dialog" scrimClickAction="">
                <loading-backdrop ?hidden=${!this._loadingDialog}></loading-backdrop>
                <div>${(this._removedInvite.type === 'code') ? this._('remove-invite-check') : this._('remove-invite-check-target', 'target', this._removedInvite.target)}</div>
                <mwc-button slot="secondaryAction" dialogAction="cancel">${this._('no')}</mwc-button>
                <mwc-button slot="primaryAction" @click=${this._removeInvite}>${this._('yes')}</mwc-button>
            </mwc-dialog>
            
            <mwc-dialog id="add-tester-dialog" class="thin-dialog" scrimClickAction="">
                <loading-backdrop ?hidden=${!this._loadingDialog}></loading-backdrop>
                <div class="left-column no-bottom-margin">
                    <mwc-formfield label=${this._('add-tester-via-mail')}>
                        <mwc-radio class="dialog-radio-button" name="tester-selection" @change=${(event) => { this._addTesterSelection = this.INVITE_TESTER_TYPE.MAIL; }} ?checked=${this._addTesterSelection === this.INVITE_TESTER_TYPE.MAIL}></mwc-radio>
                    </mwc-formfield>
                    <mwc-formfield label=${this._('add-tester-by-account')}>
                        <mwc-radio class="dialog-radio-button" name="tester-selection" @change=${(event) => { this._addTesterSelection = this.INVITE_TESTER_TYPE.ACCOUNT; }} ?checked=${this._addTesterSelection === this.INVITE_TESTER_TYPE.ACCOUNT}></mwc-radio>
                    </mwc-formfield>
                    <mwc-formfield label=${this._('add-tester-via-link')}>
                        <mwc-radio class="dialog-radio-button" name="tester-selection" @change=${(event) => { this._addTesterSelection = this.INVITE_TESTER_TYPE.LINK; }} ?checked=${this._addTesterSelection === this.INVITE_TESTER_TYPE.LINK}></mwc-radio>
                    </mwc-formfield>
                    <mwc-textfield id="invite-email-input" class="full-width dialog-input" label=${this._('e-mail')} ?hidden=${this._addTesterSelection !== this.INVITE_TESTER_TYPE.MAIL}></mwc-textfield>
                    <mwc-textfield id="invite-account-input" class="full-width dialog-input" label=${this._('username-of-account')} ?hidden=${this._addTesterSelection !== this.INVITE_TESTER_TYPE.ACCOUNT}></mwc-textfield>
                    <mwc-select class="dialog-select full-width" id="invite-link-single-use" fixedMenuPosition label=${this._('link-multi-use')} @selected=${(event) => { this._addTesterMultiUse = (event.target.value === 'infinite-use'); }} ?hidden=${this._addTesterSelection !== this.INVITE_TESTER_TYPE.LINK}>
                        <mwc-list-item value="single-use" ?selected=${!this._addTesterMultiUse}>${this._('single-use')}</mwc-list-item>
                        <mwc-list-item value="infinite-use" ?selected=${this._addTesterMultiUse}>${this._('infinite-use')}</mwc-list-item>
                    </mwc-select>
                </div>
                <mwc-button slot="secondaryAction" dialogAction="cancel">${this._('abort')}</mwc-button>
                <mwc-button slot="primaryAction" @click=${this._addTester}>${(this._addTesterSelection === this.INVITE_TESTER_TYPE.LINK) ? this._('generate-link'): this._('invite')}</mwc-button>
            </mwc-dialog>
            
            <mwc-snackbar id="link-copy-toast"
                labelText=${this._('link-copied')}>
            </mwc-snackbar>

            <div class="outer-container">
                <div class="card-container">
                    ${this._userLoggedIn ? html`
                        <div class="center-row between">
                            <h1 class="text-color no-uppercase">${this.bundleID}</h1>
                            <mwc-select id="channel-select" label=${this._('channel')} @selected=${(event) => { this._channel = event.target.value; this._updateModules(); }}>
                                <mwc-list-item value="stable" ?selected=${this._channel === 'stable'}>${this._('stable')}</mwc-list-item>
                                <mwc-list-item value="beta" ?selected=${this._channel === 'beta'}>${this._('beta')}</mwc-list-item>
                                <mwc-list-item value="testing" ?selected=${this._channel === 'testing'}>${this._('testing')}</mwc-list-item>
                            </mwc-select>
                        </div>
                        <p class="back-link-container left-element">
                            <a class="back-link" href=${this._('modules-link') + '?account=' + encodeURIComponent(this._account)}>${this._('back-to-modules')}</a>
                        </p>
                        ${(this._channel === 'testing') && (this._module.released) && (this._module.released.status === 'released') ? html`
                            <symcon-card>
                                <div class="left-column">
                                    <h2 class="text-color no-margin top-text">${this._('testers')}</h2>
                                    ${(this._module.released.testers.length === 0) ? html`
                                        <div class="regular-text bottom-padding">${this._('no-testers')}</div>
                                    `: this._module.released.testers.map((item) => {
                                        return html`
                                            <div class="center-row">
                                                <span class="regular-text">${item}</span>
                                                <mwc-icon-button account=${item} icon="close" @click=${this._generateOpenRemoveTesterDialog(item)} class="text-color"></mwc-icon-button>
                                            </div>
                                        `;
                                    })}
                                    <h2 class="text-color no-margin top-text">${this._('open-invites')}</h2>
                                    ${getInviteHTML(this._module.released.invites)}
                                    <mwc-button class="right-element action-buttons" @click=${this._openInviteTesterDialog}>${this._('invite-testers')}</mwc-button>
                                </div>
                            </symcon-card>
                        ` : html``}
                        ${this._noModules() ? html`
                            <symcon-card>
                                <div class="regular-text">${this._('no-releases-yet')}</div>
                            </symcon-card>
                        ` : html``}
                        ${this._showAddRelease(this._module.uploaded, this._loading) ? html`
                            <mwc-button class="right-element" raised @click=${this._openNewReleaseDialog}>${this._('prepare-new-version')}</mwc-button>
                        ` : html``}
                        ${this._loading ? html`
                            <loading-card></loading-card>
                        ` : html``}
                        ${[this._module.uploaded, this._module.review, this._module.declined, this._module.released].map((release) => {
                            if (!release) {
                                return html``;
                            }
                            else if ((release.status === 'declined') && this._module.released && (this._module.released.id > release.id)) {
                                return html``;
                            }
                            else {
                                return html`
                                    <my-module-card .module=${release}
                                                    account=${this._account}
                                                    bundle=${this.bundleID}
                                                    .releases=${this._releases}
                                                    .availableCategories=${this._availableCategories}
                                                    .accessToken=${this._accessToken}
                                                    @update=${onUpdate}>
                                    </my-module-card>
                                `;
                            }
                        })}
                    ` : html`
                        <symcon-card>
                            <div class="regular-text">${this._('not-logged-in')}</div>
                        </symcon-card>
                    `}
                </div>
            </div>
        `;
    }



    static get properties() {
        return {
            INVITE_TESTER_TYPE: {
                type: Object
            },

            _channel: {
                type: String
            },

            _account: {
                type: String
            },

            bundleID: {
                type: String
            },

            _releases: {
                type: Array
            },

            _module: {
                type: Object
            },

            _createNewSelection: {
                type: String
            },

            _loading: {
                type: Boolean
            },

            _loadingDialog: {
                type: Boolean
            },

            _availableCategories: {
                type: Object
            },

            _accessToken: {
                type: String
            },

            _removedTester: {
                type: String
            },

            _removedInvite: {
                type: Object
            },

            _addTesterSelection: {
                type: Number
            },

            resources: {
                type: Object
            }
        };
    }



    constructor() {
        super();
        
        this.INVITE_TESTER_TYPE = {
            MAIL: 0,
            ACCOUNT: 1,
            LINK: 2
        };

        this._channel = '';
        this._account = '';
        this.bundleID = '';
        this._releases = [];
        this._module = {
            uploaded: null,
            declined: null,
            review: null,
            released: null
        };
        this._createNewSelection = '';
        this._accessToken = '';
        this._loading = true;
        this._loadingDialog = false;
        this._availableCategories = {};
        this._removedTester = '';
        this._removedInvite = {}; // Empty object to avoid invalid access, but is not used before proper initializiation
        this._addTesterSelection = this.INVITE_TESTER_TYPE.MAIL;
        this.resources = {
            en: {
                'use-stable-as-template': 'Use Stable as template',
                'use-beta-as-template': 'Use Beta as template',
                'use-testing-as-template': 'Use Testing as template',
                'use-current-release-as-template': 'Use current release as template',
                'create-new-from-scratch': 'Create new from scratch',
                'abort': 'Abort',
                'ok': 'OK',
                'channel': 'Channel',
                'stable': 'Stable',
                'beta': 'Beta',
                'testing': 'Testing',
                'prepare-new-version': 'Prepare new version',
                'could-not-create-new-from-channel': 'Could not create a new release from a template',
                'could-not-create-new': 'Could not create a new release',
                'could-not-update-releases': 'Could not update releases',
                'could-not-load-available-categories': 'Could not load available categories',
                'not-logged-in': 'You need to log in before managing your modules.',
                'no-releases-yet': 'There are currently no versions of your module on this branch. Click \'Prepare new version\' to prepare an initial release.',
                'modules-link': '/developer/modules',
                'back-to-modules': '« Back to Modules',
                'could-not-get-access-token': 'Could not get Access Token',
                'testers': 'Testers',
                'no-testers': 'No testers yet',
                'invite-testers': 'Invite testers',
                'remove-tester-check': 'Are you sure you want to remove the tester {tester}?',
                'remove-invite-check': 'Are you sure you want to undo the invitation?',
                'remove-invite-check-target': 'Are you sure you want to undo the invitation to {target}?',
                'yes': 'Yes',
                'no': 'No',
                'add-tester-via-mail': 'Invite Tester via E-Mail',
                'add-tester-by-account': 'Invite Tester by Account Name',
                'add-tester-via-link': 'Create a link that testers can use to join',
                'e-mail': 'E-Mail',
                'username-of-account': 'Username of the invited account',
                'link-multi-use': 'Multiple uses of the generated link?',
                'single-use': 'Link can only be used once',
                'infinite-use': 'Link can be used any number of times',
                'invite': 'Invite',
                'generate-link': 'Generate Link',
                'invitation-was-sent': 'Invitation was sent',
                'invite-link': 'Link to Invitation',
                'invite-link-description': 'Please copy the copy and make it available to your potential testers',
                'could-not-invite': 'Could not invite tester',
                'link-copied': 'Link was copied to clipboard',
                'email-invites': 'Invitations via E-Mail',
                'account-invites': 'Invitations via Account',
                'code-invites': 'Invitations via Code',
                'no-open-invites': 'No open invitations',
                'open-invites': 'Open Invitations',
                'invite-link': 'https://account.symcon.de/account/test-invitation?code={code}&bundle={bundle}',
                'infinite-use-short': '(Infinite Use)',
                'single-use-short': '(Single Use)',
                'could-not-remove-tester': 'Could not remove tester',
                'could-not-remove-invite': 'Could not remove invitation',
                'invited-account-not-existing': 'The invited account does not exist'
            },
            de: {
                'use-stable-as-template': 'Stable als Vorlage verwenden',
                'use-beta-as-template': 'Beta als Vorlage verwenden',
                'use-testing-as-template': 'Testing als Vorlage verwenden',
                'use-current-release-as-template': 'Aktuellen Release als Vorlage verwenden',
                'create-new-from-scratch': 'Komplett neu erstellen',
                'abort': 'Abbrechen',
                'ok': 'OK',
                'channel': 'Kanal',
                'stable': 'Stable',
                'beta': 'Beta',
                'testing': 'Testing',
                'prepare-new-version': 'Neue Version vorbereiten',
                'could-not-create-new-from-channel': 'Konnte keinen neuen Release aus Vorlage erstellen',
                'could-not-create-new': 'Konnte keinen neuen Release erstellen',
                'could-not-update-releases': 'Konnte Releases nicht aktualisieren',
                'could-not-load-available-categories': 'Konnte vorhandene Kategorien nicht laden',
                'not-logged-in': 'Sie müssen sich einloggen, bevor Sie Ihre Module verwalten können.',
                'no-releases-yet': 'Auf diesem Branch existieren bisher keine Versionen Ihres Modules. Klicken Sie auf "Neue Version vorbereiten" um eine erste Veröffentlichung vorzubereiten.',
                'modules-link': '/entwickler/module',
                'back-to-modules': '« Zurück zu Module',
                'could-not-get-access-token': 'Konnte Access Token nicht abfragen',
                'testers': 'Tester',
                'no-testers': 'Noch keine Tester',
                'invite-testers': 'Tester einladen',
                'remove-tester-check': 'Sind Sie sicher, dass Sie den Tester {tester} entfernen wollen?',
                'remove-invite-check': 'Sind Sie sicher, dass Sie die Einladung zurückziehen wollen?',
                'remove-invite-check-target': 'Sind Sie sicher, dass Sie die Einladung an {target} zurückziehen wollen?',
                'yes': 'Ja',
                'no': 'Nein',
                'add-tester-via-mail': 'Lade Tester via E-Mail ein',
                'add-tester-by-account': 'Lade Tester via Kontoname ein',
                'add-tester-via-link': 'Erstelle einen Link mit welchem Tester beitreten können',
                'e-mail': 'E-Mail',
                'username-of-account': 'Benutzername des eingeladenen Kontos',
                'link-multi-use': 'Mehrfache Verwendung des generierten Links?',
                'single-use': 'Link kann nur einmal verwendet werden',
                'infinite-use': 'Link kann beliebig oft verwendet werden',
                'invite': 'Einladen',
                'generate-link': 'Link generieren',
                'invitation-was-sent': 'Einladung wurde verschickt',
                'invite-link': 'Link zur Einladung',
                'invite-link-description': 'Bitte kopieren Sie den Link und stellen Sie ihn Ihren potentiellen Testern zur Verfügung',
                'could-not-invite': 'Konnte Tester nicht einladen',
                'link-copied': 'Link wurde in die Zwischenablage kopiert',
                'email-invites': 'Einladungen via E-Mail',
                'account-invites': 'Einladungen via Konto',
                'code-invites': 'Einladungen via Code',
                'no-open-invites': 'Keine offenen Einladungen',
                'open-invites': 'Offene Einladungen',
                'invite-link': 'https://account.symcon.de/konto/test-einladung?code={code}&bundle={bundle}',
                'infinite-use-short': '(Beliebig oft verwendbar)',
                'single-use-short': '(Einmalig verwendbar)',
                'could-not-remove-tester': 'Konnte Tester nicht entfernen',
                'could-not-remove-invite': 'Konnte Einladung nicht entfernen',
                'invited-account-not-existing': 'Das eingeladene Konto existiert nicht'
            }
        };
    }



    _showDeclined() {
        return !!this._module.declined && (!this._module.released || (this._module.released.id < this._module.declined.id));
    }
    
    
    
    _noModules() {
        return (!this._module.uploaded && !this._module.review && !this._module.released && !this._module.declined && !this._loading);
    }



    _openNewReleaseDialog() {
        this._createNewSelection = 'from-scratch';
        this.shadowRoot.getElementById('new-release-dialog').show();
    }



    _showAddRelease(uploaded, loading) {
        return !uploaded && !loading;
    }



    _releaseExists(channel) {
        // There needs to be a released version in the channel
        for (let release of this._releases) {
            if ((release.channel === channel) && (release.status === 'released') && (!this._releases.find((innerRelease) => {
                return (innerRelease.channel === channel) && (innerRelease.status === 'endChannel') && (innerRelease.id > release.id);
            }))) {
                return true;
            }
        }

        // If there is no released version in the original channel, we cannot change
        return false;
    }



    _updateModules() {
        if (!this._releases) {
            return;
        }

        const oldModule = JSON.parse(JSON.stringify(this._module));

        this._module = this._orderReleases(this._releases, this._channel);

        this.requestUpdate('_module', oldModule);
    }



    _setReleases(releases) {
        this._releases = releases;

        this._updateModules();
    }
    
    
    
    _newReleaseFromChannel(channel) {
        this._loading = true;
        this.makeLambdaRequest('publish/module-channel', 'POST', {account: this._account, bundle: this.bundleID, channel: channel, newChannel: this._channel}).then(
            () => {
                this._updateReleases();
            },
            (error) => {
                this._showError(this._('could-not-create-new-from-channel'), error);
            }
        )
    }



    _createNewRelease() {
        switch (this._createNewSelection) {
            case 'from-stable':
                this._newReleaseFromChannel('stable');
                break;
                
            case 'from-beta':
                this._newReleaseFromChannel('beta');
                break;
                
            case 'from-testing':
                this._newReleaseFromChannel('testing');
                break;
                
            case 'from-scratch':
                this._loading = true;
                this.makeLambdaRequest('publish/add-module', 'POST', {account: this._account, bundle: this.bundleID, channel: this._channel}).then(
                    () => {
                        this._updateReleases();
                    },
                    (error) => {
                        this._showError(this._('could-not-create-new'), error);
                    }
                );
                break;
                
            default:
                this._showError(this._('invalid-selection'));
        }
    }
    
    
    
    _updateReleases() {
        if (!this._userLoggedIn) {
            return;
        }
        
        this._loading = true;
        this._setReleases([]);
        this.makeLambdaRequest('publish/module', 'GET', {account: this._account, bundle: this.bundleID, language: this.language}).then(
            (releases) => {
                this._loading = false;
                if (!this._channel) {
                    if (releases.length === 0) {
                        this._channel = 'stable';
                    }
                    else {
                        let releaseToNumber = (release) => {
                            let number = 0;
                            switch (release.status) {
                                case 'uploaded':
                                    number += 20;
                                    break;
                                    
                                case 'review':
                                    number += 10;
                                    break;
                                    
                                case 'released':
                                default:
                                    break;
                            }
                            
                            switch (release.channel) {
                                case 'stable':
                                    number += 2;
                                    break;
                                    
                                case 'review':
                                    number += 1;
                                    break;
                                    
                                case 'testing':
                                default:
                                    break;
                            }
                            
                            return number;
                        };
                        
                        let maxRelease = null;
                        let maxNumber = -1;
                        
                        for (let release of releases) {
                            let releaseNumber = releaseToNumber(release);
                            if (releaseNumber > maxNumber) {
                                maxNumber = releaseNumber;
                                maxRelease = release;
                            }
                        }
                        
                        this._channel = maxRelease.channel;
                    }
                }
                this._setReleases(releases);
            },
            (error) => {
                this._showError(this._('could-not-update-releases'), error);
                this._loading = false;
            }
        )
    }



    _generateCopyInviteLink(inviteLink) {
        return () => {
            let tmpArea = document.createElement('textarea');
            tmpArea.value = inviteLink;
            document.body.appendChild(tmpArea);
            if (navigator.userAgent.match(/ipad|iphone/i)) {
                let range = document.createRange();
                range.selectNodeContents(tmpArea);
                let selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);
                tmpArea.setSelectionRange(0, 999999);
            }
            else {
                tmpArea.select();
            }
            document.execCommand('copy');
            document.body.removeChild(tmpArea);
            this.shadowRoot.getElementById('link-copy-toast').show();
        }
    }



    _generateOpenRemoveInvitationDialog(invite) {
        return () => {
            this._removedInvite = invite;
            this.shadowRoot.getElementById('remove-invite-dialog').show();
        };
    }



    _generateOpenRemoveTesterDialog(tester) {
        return () => {
            this._removedTester = tester;
            this.shadowRoot.getElementById('remove-tester-dialog').show();
        };
    }



    _removeTester() {
        this._loadingDialog = true;
        this.makeLambdaRequest('publish/tester', 'DELETE', {account: this._account, bundle: this.bundleID, tester: this._removedTester}).then(
            () => {
                this._module.released.testers.splice(this._module.released.testers.indexOf(this._removedTester), 1);
                this.shadowRoot.getElementById('remove-tester-dialog').close();
            },
            (error) => {
                this._showError(this._('could-not-remove-tester'), error);
            }
        ).finally(() => {
            this._loadingDialog = false;
        });
    }



    _removeInvite() {
        this._loadingDialog = true;
        this.makeLambdaRequest('publish/test-invitation', 'DELETE', {account: this._account, bundle: this.bundleID, code: this._removedInvite.code}).then(
            () => {
                console.log(this._module.released.invites.findIndex((invite) => {
                    return invite.code === this._removedInvite.code;
                }));
                this._module.released.invites.splice(this._module.released.invites.findIndex((invite) => {
                    return invite.code === this._removedInvite.code;
                }), 1);
                this.shadowRoot.getElementById('remove-invite-dialog').close();
            },
            (error) => {
                this._showError(this._('could-not-remove-invite'), error);
            }
        ).finally(() => {
            this._loadingDialog = false;
        });
    }



    _openInviteTesterDialog() {
        this._addTesterSelection = this.INVITE_TESTER_TYPE.MAIL;
        this.shadowRoot.getElementById('add-tester-dialog').show();
    }



    _addTester() {
        this._loadingDialog = true;
        const parameters = {
            account: this._account,
            bundle: this.bundleID,
        };
        switch (this._addTesterSelection) {
            case this.INVITE_TESTER_TYPE.MAIL:
                parameters.type = 'mail';
                parameters.email = this.shadowRoot.getElementById('invite-email-input').value;
                break;

            case this.INVITE_TESTER_TYPE.ACCOUNT:
                parameters.type = 'account';
                parameters.invite = this.shadowRoot.getElementById('invite-account-input').value;
                break;

            case this.INVITE_TESTER_TYPE.LINK:
                parameters.type = 'code';
                parameters.uses = this._addTesterMultiUse ? 0 : 1;
                break;
        }

        this.makeLambdaRequest('publish/test-invitation', 'POST', parameters).then(
            (result) => {
                this.shadowRoot.getElementById('add-tester-dialog').close();

                const invite = {
                    code: result,
                    type: parameters.type,
                    uses: parameters.uses
                };

                switch (this._addTesterSelection) {
                    case this.INVITE_TESTER_TYPE.MAIL:
                        invite.target = parameters.email;
                        break;
        
                    case this.INVITE_TESTER_TYPE.ACCOUNT:
                        invite.target = parameters.invite;
                        break;
        
                    case this.INVITE_TESTER_TYPE.LINK:
                        invite.target = '';
                        break;
                }

                this._module.released.invites.push(invite);

                switch (this._addTesterSelection) {
                    case this.INVITE_TESTER_TYPE.MAIL:
                    case this.INVITE_TESTER_TYPE.ACCOUNT:
                        this._showNotification(this._('invitation-was-sent'));
                        break;

                    case this.INVITE_TESTER_TYPE.LINK:
                        this._inviteLink = this._('invite-link', 'bundle', this.bundleID, 'code', result);
                        this.shadowRoot.getElementById('link-dialog').show();
                        break;
                }
            },
            (error) => {
                if (error.status === 404) {
                    this._showNotification(this._('invited-account-not-existing'));
                }
                else {
                    this._showError(this._('could-not-invite'), error);
                }
            }
        ).finally(
            () => {
                this._loadingDialog = false;
            }
        )
    }
    
    
    
    connectedCallback() {
        super.connectedCallback();

        this.makeLambdaRequest('store/categories', 'GET', {language: this.language}, false).then(
            (categories) => {
                this._availableCategories = {};
                for (let availableCategory of categories) {
                    this._availableCategories[availableCategory.id] = availableCategory;
                }
            },
            (error) => {
                this._showError(this._('could-not-load-available-categories'), error);
            }
        );

        this.makeLambdaRequest('account/oauth-token', 'GET', {provider: 'github'}).then(
            (accessToken) => {
                this._accessToken = accessToken;
            },
            (error) => {
                this._showError(this._('could-not-get-access-token'), error);
            }
        );

        const parameters = querystring.parse(location.search.substr(1));
        this._account = parameters.account;
        if (parameters.channel) {
            this._channel = parameters.channel;
        }
        
        this._updateReleases();
    }
}

customElements.define('my-module', Module);
