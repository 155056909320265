import {LitElement, html, css} from 'lit-element';
import '@material/mwc-dialog';
import '@material/mwc-button';
import '@material/mwc-textfield';
import '@material/mwc-button';
import '@material/mwc-icon-button';

import {UserPoolMixin} from "../../mixins/user-pool-mixin";
import {CommonStyles} from '../../mixins/common-styles';
import '../../components/loading-backdrop';
import '../../components/notification-dialog';
import '../../components/symcon-card';
import {LambdaRequestMixin} from "../../mixins/lambda-request-mixin";
import {ShowErrorMixin} from "../../mixins/show-error-mixin";
import {TranslateMixin} from "../../mixins/translate-mixin";

class AccountSettingsPage extends CommonStyles(TranslateMixin(ShowErrorMixin(LambdaRequestMixin(UserPoolMixin(LitElement))))) {

    static get styles() {
        return [
            css`
                .regular-text.connected-label {
                    font-weight: bold;
                    padding-top: 12px;
                }
            `,
            super.styles
        ];
    }



    static get properties() {
        return {
            _realUsername: {
                type: String
            },

            _accessibleAccounts: {
                type: Array
            },

            _accessingLogins: {
                type: Array
            },
            
            _primaryAccount: {
                type: String
            },

            _loadingDialog: {
                type: Boolean
            },

            _loadingChangePassword: {
                type: Boolean
            },

            _loadingChangeEmail: {
                type: Boolean
            },

            _loadingRemoveAccessible: {
                type: Boolean
            },

            _loadingRemoveAccessing: {
                type: Boolean
            },

            _loadingSetAccessToken: {
                type: Boolean
            },

            _currentEmail: {
                type: String
            },
            
            _accessingLoginToRemove: {
                type: String
            },

            _accessedAccountToRemove: {
                type: String
            },

            _gitHubOAuthConnected: {
                type: Boolean
            },
            
            resources: {
                type: Object
            }
        };
    }



    constructor() {
        super();

        this._realUsername = '';
        this._accessibleAccounts = [];
        this._accessingLogins = [];
        this._primaryAccount = '';
        this._currentEmail = '';
        this._loadingDialog = false;
        this._loadingChangePassword = false;
        this._loadingChangeEmail = true;
        this._loadingRemoveAccessible = false;
        this._loadingRemoveAccessing = false;
        this._loadingSetAccessToken = true;
        this._accessingLoginToRemove = '';
        this._accessedAccountToRemove = '';
        this._gitHubOAuthConnected = false;
        this.resources = {
            en: {
                'remove-accessing-login-check': 'Are you sure you want to remove the connection to the account? The accounts owner will not be able to access your licenses or modules any more.',
                'remove-accessed-account-check': 'Are you sure you want to remove the connection to the the account? You will not be able to access that accounts licenses or modules any more.',
                'no': 'No',
                'yes': 'Yes',
                'add-accessing-login': 'You can share your data with another account. The owner of the other account will have the possibility to access your licenses and your own modules.',
                'username-of-account': 'Username of the account',
                'abort': 'Abort',
                'share-account': 'Share account',
                'change-password': 'Change password',
                'current-password': 'Current password',
                'new-password': 'New password',
                'confirm-new-password': 'Confirm new Password',
                'change-email': 'Change E-Mail',
                'e-mail': 'E-Mail',
                'accessible-accounts': 'Accounts that are accessible',
                'no-accounts-shared-with-you': 'No accounts are shared with you.',
                'accessing-accounts': 'Accounts that can access me',
                'no-accounts-shared': 'You have not shared your account with anyone.',
                'need-to-login': 'You need to login before you can access the account settings.',
                'no-current-user': 'No current user',
                'invalid-session': 'Session is invalid',
                'could-not-change-password': 'Could not change password',
                'password-changed': 'Password changed succesfully',
                'passwords-not-equal': 'The content of the fields for new password do not match. Thus, the password cannot be changed.',
                'could-not-add-accessing': 'Could not add access to another account.',
                'removed-account-not-in-list': 'Removed account is not in list',
                'could-not-remove-accessible': 'Could not remove access to another account',
                'primary-account-unknown': 'Primary account is unknown',
                'could-not-remove-accessing': 'Could not remove access to another account',
                'could-not-load-accessible': 'Could not load accounts that I can access',
                'could-not-load-accessing': 'Could not load accounts that can access me',
                'new-password-does-not-fulfill-requirements': 'The new password does not fulfill the security requirements',
                'password-requirements-info': '- At least 8 letters\n- Needs to contain uppercase and lowercase letters\n- Needs to contain numbers',
                'current-password-is-empty': 'Please enter your current password',
                'new-password-is-empty': 'Please enter a new password',
                'incorrect-current-password': 'The current password is incorrect',
                'current-password-too-short': 'The current password is too short',
                'email-is-empty': 'Please enter an email address',
                'email-same-as-current': 'The entered email is your current email, no need to change',
                'invalid-email': 'The entered email address is invalid',
                'email-taken': 'The email address is already taken by another account',
                'login-already-has-access': 'This account already has access to your account',
                'login-does-not-exist': 'This account does not exist',
                'accessing-login-is-empty': 'Please enter an account',
                'username': 'Username',
                'could-not-get-access-token': 'Could not get Personal Access Token',
                'could-not-change-access-token': 'Could not change Personal Access Token',
                'could-not-get-session': 'Could not get session',
                'current-session-invalid': 'Current Session is invalid',
                'could-not-get-attributes': 'Could not get attributes',
                'e-mail-attribute-not-set': 'E-Mail attribute is not set',
                'could-not-change-email': 'Could not change e-mail',
                'email-changed': 'E-Mail was succesfully changed',
                'invalid-access-token': 'The entered access token is invalid',
                'invalid-access-token-description': 'A Personal Access Token needs to have exactly 40 symbols',
                'github-oauth-connection': '(Developer) GitHub OAuth Connection',
                'github-oauth-connection-description': 'Connecting your account to GitHub will enable the use of private repositories as well as a significantly higher number of requests to the GitHub API. These are used to check if published modules are up-to-date.',
                'github-oauth-connected': 'You have already connected your account to GitHub',
                'connect': 'Connect',
                'disconnect': 'Disconnect',
                'could-not-connect-to-github': 'Could not connect to GitHub'
            },
            de: {
                'remove-accessing-login-check': 'Sind Sie sicher, dass Sie die Verbindung zu diesem Konto lösen wollen? Der Besitzer des Kontos wird nicht mehr in der Lage sein auf Ihre Lizenzen und Module zuzugreifen.',
                'remove-accessed-account-check': 'Sind Sie sicher, dass Sie die Verbindung zu diesem Konto lösen wollen? Sie werden nicht mehr in der Lage sein, auf die Lizenzen und Module des Kontos zuzugreifen.',
                'no': 'Nein',
                'yes': 'Ja',
                'add-accessing-login': 'Sie können hier einem anderen Konto Zugriff auf Ihre Daten gewähren. Der Besitzer des Kontos hat dann die Möglichkeit auf Ihre Lizenzen und Ihre eigenen Module zuzugreifen.',
                'username-of-account': 'Benutzername des Kontos',
                'abort': 'Abbrechen',
                'share-account': 'Konto freigeben',
                'change-password': 'Passwort ändern',
                'current-password': 'Aktuelles Passwort',
                'new-password': 'Neues Passwort',
                'confirm-new-password': 'Neues Passwort bestätigen',
                'change-email': 'E-Mail ändern',
                'e-mail': 'E-Mail',
                'accessible-accounts': 'Konten auf die ich zugreifen kann',
                'no-accounts-shared-with-you': 'Es sind keine anderen Konten für Sie freigegeben.',
                'accessing-accounts': 'Konten, die auf mich zugreifen können',
                'no-accounts-shared': 'Sie haben niemanden Ihr Konto freigegeben.',
                'need-to-login': 'Sie müssen sich einloggen, bevor Sie auf die Kontoeinstellungen zugreifen können.',
                'no-current-user': 'Kein aktueller Benutzer',
                'invalid-session': 'Sitzung ist ungültig',
                'could-not-change-password': 'Konnte Passwort nicht ändern',
                'password-changed': 'Passwort erfolgreich geändert',
                'passwords-not-equal': 'Der Inhalt der Felder für das neue Passwort stimmen nicht überein, daher kann das Passwort nicht geändert werden.',
                'could-not-add-accessing': 'Konnte Zugriff nicht für anderes Konto freigeben',
                'removed-account-not-in-list': 'Entferntes Konto befindet sich nicht in der Liste',
                'could-not-remove-accessible': 'Konnte Zugriff auf anderes Konto nicht entfernen',
                'primary-account-unknown': 'Kein primäres Konto bekannt',
                'could-not-remove-accessing': 'Konnte Zugriff auf Konto nicht entfernen',
                'could-not-load-accessible': 'Konnte Konten, auf die ich Zugriff habe, nicht laden',
                'could-not-load-accessing': 'Konnte Konten mit Zugriff auf mich nicht laden',
                'new-password-does-not-fulfill-requirements': 'Das gewählte neue Passwort erfüllt nicht die Sicherheitsanforderungen',
                'password-requirements-info': '- Mindestens 8 Zeichen\n- Muss Groß- und Kleinbuchstaben enthalten\n- Muss Zahlen enthalten',
                'current-password-is-empty': 'Bitte geben Sie Ihr aktuelles Passwort ein',
                'new-password-is-empty': 'Bitte geben Sie ein neues Passwort ein',
                'incorrect-current-password': 'Das aktuelle Passwort ist falsch',
                'current-password-too-short': 'Das aktuelle Passwort ist zu kurz',
                'email-is-empty': 'Bitte geben Sie eine E-Mail-Adresse ein',
                'email-same-as-current': 'Die eingegebene E-Mail-Adresse entspricht Ihrer aktuellen Adresse, es gibt also keinen Änderungsbedarf',
                'invalid-email': 'Die eingegebene E-Mail-Adresse ist ungültig',
                'email-taken': 'Die E-Mail-Adresse wird bereits von einem anderen Konto verwendet',
                'login-already-has-access': 'Dieses Konto hat bereits Zugriff auf Ihr Konto',
                'login-does-not-exist': 'Dieses Konto existiert nicht',
                'accessing-login-is-empty': 'Bitte geben Sie ein Konto ein',
                'username': 'Benutzername',
                'could-not-get-access-token': 'Konnte Personal Access Token nicht abfragen',
                'could-not-change-access-token': 'Konnte Personal Access Token nicht ändern',
                'could-not-get-session': 'Konnte Sitzung nicht abfragen',
                'current-session-invalid': 'Aktuelle Sitzung ist ungültig',
                'could-not-get-attributes': 'Konnte Attribute nicht abfragen',
                'e-mail-attribute-not-set': 'E-Mail Attribut ist nicht gesetzt',
                'could-not-change-email': 'Konnte E-Mail nicht ändern',
                'email-changed': 'E-Mail wurde erfolgreich geändert',
                'invalid-access-token': 'Der eingegebene Access Token ist ungültig',
                'invalid-access-token-description': 'Ein Personal Access Token muss aus exakt 40 Zeichen bestehen',
                'github-oauth-connection': '(Entwickler) GitHub OAuth Verbindung',
                'github-oauth-connection-description': 'Durch eine Verbindung zu GitHub wird die Verwendung von privaten Repositories sowie eine deutlich höhere Menge an Abfragen an die GitHub-API ermöglicht. Diese werden verwendet um zu prüfen, ob die veröffentlichten Module auf aktuellem Stand sind.',
                'github-oauth-connected': 'Sie haben Ihr Konto bereits mit GitHub verknüpft',
                'connect': 'Verbinden',
                'disconnect': 'Trennen',
                'could-not-connect-to-github': 'Konnte nicht zu GitHub verbinden'
            }
        }
    }



    render() {
        return html`
            <notification-dialog id="notification-dialog"></notification-dialog>
            
            <mwc-dialog id="remove-accessing-login-dialog" class="left-column thin-dialog" scrimClickAction="">
                <loading-backdrop ?hidden=${!this._loadingDialog}></loading-backdrop>
                <div>${this._('remove-accessing-login-check')}</div>
                <mwc-button slot="secondaryAction" dialogAction="cancel">${this._('no')}</mwc-button>
                <mwc-button slot="primaryAction" @click=${this._removeAccessingLogin}>${this._('yes')}</mwc-button>
            </mwc-dialog>
            
            <mwc-dialog id="remove-accessed-account-dialog" class="left-column thin-dialog" scrimClickAction="">
                <loading-backdrop ?hidden=${!this._loadingDialog}></loading-backdrop>
                <div>${this._('remove-accessed-account-check')}</div>
                <mwc-button slot="secondaryAction" dialogAction="cancel">${this._('no')}</mwc-button>
                <mwc-button slot="primaryAction" @click=${this._removeAccessibleAccount}>${this._('yes')}</mwc-button>
            </mwc-dialog>
            
            <mwc-dialog id="add-accessing-login-dialog" class="left-column thin-dialog" scrimClickAction="">
                <loading-backdrop ?hidden=${!this._loadingDialog}></loading-backdrop>
                <div class="top-text">${this._('add-accessing-login')}</div>
                <mwc-textfield id="new-accessing-login-input" class="full-width dialog-input" label=${this._('username-of-account')}></mwc-textfield>
                <mwc-button slot="secondaryAction" dialogAction="cancel">${this._('abort')}</mwc-button>
                <mwc-button slot="primaryAction" @click=${this._addAccessingLogin}>${this._('share-account')}</mwc-button>
            </mwc-dialog>
            
            <div class="outer-container">
                ${this._userLoggedIn ? html`
                    <symcon-card>
                        <div class="left-column">
                            <mwc-textfield class="full-width" label=${this._('username')} value=${this._realUsername} disabled></mwc-textfield>
                        </div>
                    </symcon-card>

                    <symcon-card>
                        <loading-backdrop ?hidden=${!this._loadingChangePassword}></loading-backdrop>
                        <div class="left-column">
                            <h2 class="text-color no-margin top-text">${this._('change-password')}</h2>
                            <mwc-textfield id="current-password-input" class="full-width" label=${this._('current-password')} type="password"></mwc-textfield>
                            <mwc-textfield id="new-password-input" class="full-width" label=${this._('new-password')} type="password"></mwc-textfield>
                            <mwc-textfield id="new-password-confirm-input" class="full-width" label=${this._('confirm-new-password')} type="password"></mwc-textfield>
                            <div class="row right-element action-buttons">
                                <mwc-button @click=${this._changePassword} class="btn btn-regular">${this._('change-password')}</paper-button>
                            </div>
                        </div>
                    </symcon-card>

                    <symcon-card>
                        <loading-backdrop ?hidden=${!this._loadingChangeEmail}></loading-backdrop>
                        <div class="left-column">
                            <h2 class="text-color no-margin top-text">${this._('change-email')}</h2>
                            <mwc-textfield id="email-input" class="full-width" label=${this._('e-mail')} value=${this._currentEmail}></mwc-textfield>
                            <div class="row right-element action-buttons">
                                <mwc-button @click=${this._changeEmail} class="btn btn-regular">${this._('change-email')}</paper-button>
                            </div>
                        </div>
                    </symcon-card>
                
                    <symcon-card>
                        <loading-backdrop ?hidden=${!this._loadingRemoveAccessible}></loading-backdrop>
                        <div class="left-column">
                            <h2 class="text-color no-margin top-text">${this._('accessible-accounts')}</h2>
                            ${(this._accessibleAccounts.length === 0) ? html`
                                <div class="regular-text bottom-padding">${this._('no-accounts-shared-with-you')}</div>
                            `: this._accessibleAccounts.map((item) => {
                                return html`
                                    <div class="center-row">
                                        <span class="regular-text">${item}</span>
                                        <mwc-icon-button account=${item} icon="cancel" @click=${this._openRemoveAccessedAccountDialog} class="text-color"></mwc-icon-button>
                                    </div>
                                `;
                            })}
                        </div>
                    </symcon-card>
                
                    <symcon-card>
                        <loading-backdrop ?hidden=${!this._loadingRemoveAccessing}></loading-backdrop>
                        <div class="left-column">
                            <h2 class="text-color no-margin top-text">${this._('accessing-accounts')}</h2>
                            ${(this._accessingLogins.length === 0) ? html`
                                <div class="regular-text bottom-padding">${this._('no-accounts-shared')}</div>
                            `: this._accessingLogins.map((item) => {
                                return html`
                                    <div class="center-row">
                                        <span class="regular-text">${item}</span>
                                        <mwc-icon-button account=${item} icon="cancel" @click=${this._openRemoveAccessingLoginDialog} class="text-color"></mwc-icon-button>
                                    </div>
                                `;
                            })}
                            <mwc-button class="right-element action-buttons" @click=${this._openAddAccessingLoginDialog}>${this._('share-account')}</mwc-button>
                        </div>
                    </symcon-card>

                    <symcon-card>
                        <loading-backdrop ?hidden=${!this._loadingSetAccessToken}></loading-backdrop>
                        <div class="left-column">
                            <h2 class="text-color no-margin top-text">${this._('github-oauth-connection')}</h2>
                            <div class="regular-text">${this._('github-oauth-connection-description')}</div>
                            ${this._gitHubOAuthConnected ? html` 
                                <div class="regular-text connected-label">${this._('github-oauth-connected')}</div>}
                            ` : html``}
                            <div class="row right-element action-buttons">
                                <mwc-button @click=${this._connectToGitHub}>${this._(this._gitHubOAuthConnected ? 'disconnect' : 'connect')}</paper-button>
                            </div>
                        </div>
                    </symcon-card>
                ` : html `
                    <symcon-card>
                        <div class="regular-text text-card">${this._('need-to-login')}</div>
                    </symcon-card>
                `}
            </div>
        `;
    }
    
    
    
    _arrayEmpty(array) {
        return !array || (array.base.length === 0);
    }



    _changePassword() {
        const currentPassword = this.shadowRoot.getElementById('current-password-input').value;
        if (currentPassword === '') {
            this._showNotification(this._('current-password-is-empty'));
            return;
        }

        const newPassword = this.shadowRoot.getElementById('new-password-input').value;
        if (newPassword === '') {
            this._showNotification(this._('new-password-is-empty'));
            return;
        }
        
        if (newPassword !== this.shadowRoot.getElementById('new-password-confirm-input').value) {
            this._showNotification(this._('passwords-not-equal'));
            return;
        }
        
        if (currentPassword.length < 6) {
            this._showNotification(this._('current-password-too-short'));
            return;
        }

        if ((newPassword.length < 8) || !/.*[a-z].*/.test(newPassword) || !/.*[A-Z].*/.test(newPassword) || !/.*[0-9].*/.test(newPassword)) {
            this._showNotification(this._('new-password-does-not-fulfill-requirements'), this._('password-requirements-info'));
            return;
        }

        this._loadingChangePassword = true;
        
        let displayError = (message) => {
            this._showError(this._('could-not-change-password'), message);
            this._loadingChangePassword = false;
        };
        
        if (!this.USER_POOL.getCurrentUser()) {
            displayError(this._('no-current-user'));
            return;
        }
        
        let user = this.USER_POOL.getCurrentUser();

        user.getSession((error, session) => {
            if (error) {
                displayError(error);
                return;
            }

            if (!session.isValid()) {
                displayError(this._('invalid-session'));
                return;
            }

            user.changePassword(currentPassword, newPassword, (error, result) => {
                if (error) {
                    switch (error.name) {
                        case 'NotAuthorizedException':
                            this._showNotification(this._('incorrect-current-password'));
                            this._loadingChangePassword = false;
                            break;
                            
                        default:
                            displayError(error);
                            
                    }
                }
                else {
                    this._showNotification(this._('password-changed'));
                    this.shadowRoot.getElementById('current-password-input').value = '';
                    this.shadowRoot.getElementById('new-password-input').value = '';
                    this.shadowRoot.getElementById('new-password-confirm-input').value = '';
                    this._loadingChangePassword = false;
                }
            });
        });
    }



    _changeEmail() {
        const newMail = this.shadowRoot.getElementById('email-input').value;
        if (newMail === '') {
            this._showNotification(this._('email-is-empty'));
            return;
        }

        if (newMail === this._currentEmail) {
            this._showNotification(this._('email-same-as-current'));
            return;
        }
        
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(newMail)) {
            this._showNotification(this._('invalid-email'));
            return;
        }

        this._loadingChangeEmail = true;

        this.makeLambdaRequest('account/change-email', 'POST', {email: newMail}).then(
            () => {
                this._currentEmail = newMail;
                this._showNotification(this._('email-changed'));
            },
            (error) => {
                if (error.message === 'E-Mail is already taken') {
                    this._showNotification(this._('email-taken'));
                }
                else {
                    this._showError(this._('could-not-change-email'), error);
                }
            }
        ).finally(() => {
            this._loadingChangeEmail = false;
        })
    }



    _openRemoveAccessedAccountDialog(event) {
        this._accessedAccountToRemove = event.target.getAttribute('account');
        this.shadowRoot.getElementById('remove-accessed-account-dialog').show();        
    }



    _openRemoveAccessingLoginDialog(event) {
        this._accessingLoginToRemove = event.target.getAttribute('account');
        this.shadowRoot.getElementById('remove-accessing-login-dialog').show();
    }



    _removeAccessibleAccount() {
        this._loadingDialog = true;
        this.makeLambdaRequest('account/account', 'DELETE', { accessingLogin: this.USER_POOL.getCurrentUser().username, accessedAccount: this._accessedAccountToRemove }).then(
            () => {
                let index = this._accessibleAccounts.indexOf(this._accessedAccountToRemove);
                if (index === -1) {
                    this._showError(this._('removed-account-not-in-list'));
                }
                else {
                    this._accessibleAccounts.splice(index, 1);
                }
                this.shadowRoot.getElementById('remove-accessed-account-dialog').close();
                this._loadingDialog = false;
            },
            (error) => {
                this._showError(this._('could-not-remove-accessible'), error);
                this._loadingDialog = false;                
            }
        )
    }
    
    
    
    _removeAccessingLogin() {
        if (!this._primaryAccount) {
            this._showError(this._('primary-account-unknown'));
        }
        else {
            this._loadingDialog = true;
            this.makeLambdaRequest('account/account', 'DELETE', { accessingLogin: this._accessingLoginToRemove, accessedAccount: this._primaryAccount }).then(
                () => {
                    let index = this._accessingLogins.indexOf(this._accessingLoginToRemove);
                    if (index === -1) {
                        this._showError(this._('removed-account-not-in-list'));
                    }
                    else {
                        this._accessingLogins.splice(index, 1);
                    }
                    this.shadowRoot.getElementById('remove-accessing-login-dialog').close();
                    this._loadingDialog = false;
                },
                (error) => {
                    this._showError(this._('could-not-remove-accessing'), error);
                    this._loadingDialog = false;
                }
            );
        }
    }



    _openAddAccessingLoginDialog() {
        this.shadowRoot.getElementById('new-accessing-login-input').value = '';
        this.shadowRoot.getElementById('add-accessing-login-dialog').show();
    }



    _addAccessingLogin() {
        const newAccessingLogin = this.shadowRoot.getElementById('new-accessing-login-input').value;
        if (newAccessingLogin.trim() === '') {
            this._showNotification(this._('accessing-login-is-empty'));
            return;
        }
        
        for (let accessingLogin of this._accessingLogins) {
            if (accessingLogin === newAccessingLogin.trim()) {
                this._showNotification(this._('login-already-has-access'));
                return;
            }
        }
        
        this._loadingDialog = true;
        this.makeLambdaRequest('account/add-account', 'POST', { targetLogin: newAccessingLogin.trim() }).then(
            () => {
                this._accessingLogins.push(newAccessingLogin.trim());
                this.shadowRoot.getElementById('add-accessing-login-dialog').close();
                this._loadingDialog = false;
            },
            (error) => {
                if (error.status === 404) {
                    this._showNotification(this._('login-does-not-exist'));
                }
                else {
                    this._showError(this._('could-not-add-accessing'), error);
                }
                this._loadingDialog = false;
            }
        );
    }



    _connectToGitHub() {
        this._loadingSetAccessToken = true;
        if (this._gitHubOAuthConnected) {
            this.makeLambdaRequest('account/oauth-token', 'DELETE', { provider: 'github' }).then(
                () => {
                    this._gitHubOAuthConnected = false;
                },
                (error) => {
                    this._showError(this._('could-not-connect-to-github'), error);
                }
            ).finally(() => {
                this._loadingSetAccessToken = false;
            });

        }
        else {
            this.makeLambdaRequest('account/github-oauth-connect', 'GET').then(
                (loginUrl) => {
                    window.open(loginUrl, '_self');
                },
                (error) => {
                    this._showError(this._('could-not-connect-to-github'), error);
                }
            ).finally(() => {
                this._loadingSetAccessToken = false;
            });
        }
    }
    
    
    
    connectedCallback() {
        super.connectedCallback();

        if (!this._userLoggedIn) {
            return;
        }
        
        this._loadingRemoveAccessible = true;
        this._loadingRemoveAccessing = true;
        
        this.makeLambdaRequest('account/accounts', 'GET', { }).then(
            (accounts) => {
                let otherAccounts = [];
                for (let account of accounts) {
                    if (!account.primary) {
                        otherAccounts.push(account.account);
                    }
                    else {
                        this._primaryAccount = account.account;
                    }
                }

                this._accessibleAccounts = otherAccounts;
                this._loadingRemoveAccessible = false;
            },
            (error) => {
                this._showError(this._('could-not-load-accessible'), error);
                this._loadingRemoveAccessible = false;
            }
        );
        
        this.makeLambdaRequest('account/accessing-logins', 'GET', { }).then(
            (logins) => {
                let otherLogins = [];
                for (let account of logins) {
                    if (!account.primary) {
                        otherLogins.push(account.login);
                    }
                }

                this._accessingLogins = otherLogins;
                this._loadingRemoveAccessing = false;
            },
            (error) => {
                this._showError(this._('could-not-load-accessing'), error);
                this._loadingRemoveAccessing = false;
            }
        );
        
        this.makeLambdaRequest('account/oauth-token', 'GET', {provider: 'github'}).then(
            (token) => {
                this._gitHubOAuthConnected = (token !== '');
                this._loadingSetAccessToken = false;
            },
            (error) => {
                this._showError(this._('could-not-get-access-token'), error);
            }
        );

        const user = this.USER_POOL.getCurrentUser();
        user.getSession((error, session) => {
            if (error) {
                this._showError(this._('could-not-get-session'), error);
                return;
            }

            if (!session.isValid()) {
                this._showError(this._('current-session-invalid'));
                return;
            }
            user.getUserAttributes((error, attributes) => {
                if (error) {
                    this._showError(this._('could-not-get-attributes'), error);
                    return;
                }
                const emailAttribute = attributes.find((attribute) => {
                    return attribute.Name === 'email';
                });
                if (!emailAttribute) {
                    this._showError(this._('e-mail-attribute-not-set'));
                    return;                        
                }

                this._currentEmail = emailAttribute.Value;
                this._loadingChangeEmail = false;
            });
        });

        this._getRealUsername().then((realUsername) => {
            this._realUsername = realUsername;
        });
    }
}

customElements.define('account-settings-page', AccountSettingsPage);
