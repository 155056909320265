import {LitElement, html} from 'lit-element';
import '@material/mwc-textfield';
import '@material/mwc-button';

const querystring = require('querystring');
const AmazonCognitoIdentity = require('amazon-cognito-identity-js');

import {UserPoolMixin} from "../../mixins/user-pool-mixin";
import {CommonStyles} from '../../mixins/common-styles';
import '../../components/loading-backdrop';
import '../../components/symcon-card';
import '../../components/notification-dialog';
import '../../components/send-activation-again-dialog';
import {ShowErrorMixin} from "../../mixins/show-error-mixin";
import {TranslateMixin} from "../../mixins/translate-mixin";
import {LambdaRequestMixin} from '../../mixins/lambda-request-mixin';
import {hideMenu} from '../../account-body';

class ForgotPasswordPage extends CommonStyles(LambdaRequestMixin(TranslateMixin(ShowErrorMixin(UserPoolMixin(LitElement))))) {

    render() {
        const getContent = () => {
            if (this._userLoggedIn) {
                return html`
                    <symcon-card class="regular-card text-card center-column">
                        <div class="regular-text">${this._('not-usable-while-logged-in')}</div>
                    </symcon-card>
                `;
            }
            else if (!this.passwordSent) {
                return html`
                    <symcon-card class="regular-card">
                        <loading-backdrop ?hidden=${!this._loading}></loading-backdrop>
                        <div class="left-column side-margin">
                            <div class="regular-text top-text">${this._('reset-description')}</div>
                            <mwc-textfield id="username-input" class="full-width" label=${this._('username')} @keypress=${this._handleForgotPress}></mwc-textfield>
                            <mwc-button class="right-element action-buttons" @click=${this._resetPassword}>${this._('reset-password')}</mwc-button>
                        </div>
                    </symcon-card>
                `;
            }
            else if (this._passwordChanged) {
                return html`
                    <symcon-card class="regular-card text-card center-column">
                        <div class="bottom-padding regular-text">
                            ${this._('password-successfully-changed')}
                        </div>
                        <mwc-button class="action-buttons" @click=${this._openLogin} raised>${this._('to-login')}</mwc-button>
                    </symcon-card>
                `;
            }
            else {
                return html`
                    <symcon-card class="regular-card">
                        <loading-backdrop ?hidden=${!this._loading}></loading-backdrop>
                        <div class="right-column side-margin">
                            <div class="regular-text top-text">${this._('code-via-mail')}</div>
                            <mwc-textfield id="username-input" class="full-width" label=${this._('username')} @keypress=${this._handleChangePress} value=${this._username} ?disabled=${!!this._username}></mwc-textfield>
                            <mwc-textfield id="change-code-input" class="full-width" label=${this._('change-code')} @keypress=${this._handleChangePress}></mwc-textfield>
                            <mwc-textfield id="password-input" class="full-width" label=${this._('new-password')} @keypress=${this._handleChangePress} type="password"></mwc-textfield>
                            <mwc-textfield id="confirm-password-input" class="full-width" label=${this._('confirm-new-password')} @keypress=${this._handleChangePress} type="password"></mwc-textfield>
                            <mwc-button class="action-buttons" @click=${this._changePassword}>${this._('change-password')}</mwc-button>
                        </div>
                    </symcon-card>
                `;
            }
        }

        return html`      
            <notification-dialog id="notification-dialog"></notification-dialog>

            <send-activation-again-dialog id="send-activation-again-dialog" .cognitoUser=${this._cognitoUser}></send-activation-again-dialog>

            <div class="outer-container">
                <div class="card-container">
                    ${getContent()}
                </div>
            </div>
        `;
    }



    static get properties() {
        return {
            _disableUser: {
                type: Boolean
            },
            
            _username: {
                type: String
            },
            
            _passwordChanged: {
                type: Boolean
            },
            
            _loading: {
                type: Boolean
            },
            
            _changeCode: {
                type: String
            },
            
            _password: {
                type: String
            },
            
            _passwordConfirm: {
                type: String
            },

            _cognitoUser: {
                type: Object
            },
            
            passwordSent: {
                type: Boolean
            },
            
            resources: {
                type: Object
            }
        };
    }



    constructor() {
        super();
        
        this._disableUser = false;
        this._username = '';
        this.passwordSent = false;
        this._passwordChanged = false;
        this._loading = false;
        this._changeCode = '';
        this._passwordConfirm = '';
        this._cognitoUser = null;
        this.resources = {
            en: {
                'not-usable-while-logged-in': 'You cannot use the \'Forgot password\' function while you are logged in.',
                'password-successfully-changed': 'Password changed succesfully. You can login with your new password.',
                'login-link': '/account/login',
                'to-login': 'To Login',
                'code-via-mail': 'You have received a code via mail. Please enter the code here to choose a new password.',
                'username': 'Username',
                'change-code': 'Change Code',
                'new-password': 'New Password',
                'confirm-new-password': 'Confirm New Password',
                'change-password': 'Change Password',
                'reset-password': 'Reset Password',
                'passwords-not-equal': 'The content of the fields for new password do not match. Thus, the password cannot be changed.',
                'could-not-reset-password': 'Could not reset password',
                'could-not-change-password': 'Could not change password',
                'reset-description': 'Please enter the username of your account, to reset its password.',
                'username-is-empty': 'Please enter a username',
                'invalid-username': 'The entered username is invalid. It probably contains spaces.',
                'password-is-empty': 'Please enter a password',
                'change-code-is-empty': 'Please enter a change code',
                'user-does-not-exist': 'User does not exist. Please consider that this login is separate from our community forum.',
                'password-does-not-fulfill-requirements': 'The chosen password does not fulfill the security requirements',
                'password-requirements-info': '- At least 8 letters\n- Needs to contain uppercase and lowercase letters\n- Needs to contain numbers',
                'incorrect-change-code': 'Change code is incorrect',
                'could-not-check-username': 'Could not check username',
                'forum-link': '/account/forum'
            },
            de: {
                'not-usable-while-logged-in': 'Sie können die "Passwort vergessen"-Funktion nicht benutzen während Sie eingeloggt sind',
                'password-successfully-changed': 'Passwort wurde erfolgreich geändert. Sie können sich mit Ihrem neuen Passwort anmelden.',
                'login-link': '/konto/anmelden',
                'to-login': 'Zur Anmeldung',
                'code-via-mail': 'Sie haben einen Code per E-Mail erhalten, bitte geben Sie ihn hier ein und wählen Sie ein neues Passwort.',
                'username': 'Benutzername',
                'change-code': 'Änderungscode',
                'new-password': 'Neues Passwort',
                'confirm-new-password': 'Neues Passwort bestätigen',
                'change-password': 'Passwort ändern',
                'reset-password': 'Passwort zurücksetzen',
                'passwords-not-equal': 'Der Inhalt der Felder für das neue Passwort stimmen nicht überein, daher kann das Passwort nicht geändert werden.',
                'could-not-reset-password': 'Konnte Passwort nicht zurücksetzen',
                'could-not-change-password': 'Konnte Passwort nicht ändern',
                'reset-description': 'Bitte geben Sie den Benutzernamen Ihres Kontos ein um das Passwort zurückzusetzen.',
                'username-is-empty': 'Bitte geben Sie einen Benutzernamen ein',
                'invalid-username': 'Der eingegebene Benutzername ist ungültig. Er enthält wahrscheinlich Leerzeichen.',
                'password-is-empty': 'Bitte geben Sie ein Passwort ein',
                'change-code-is-empty': 'Bitte geben Sie einen Änderungscode ein',
                'user-does-not-exist': 'Benutzer existiert nicht. Bitte beachten Sie, dass diese Anmeldung separat von unserem Community-Forum ist.',
                'password-does-not-fulfill-requirements': 'Das gewählte Passwort erfüllt nicht die Sicherheitsanforderungen',
                'password-requirements-info': '- Mindestens 8 Zeichen\n- Muss Groß- und Kleinbuchstaben enthalten\n- Muss Zahlen enthalten',
                'incorrect-change-code': 'Falscher Änderungscode',
                'could-not-check-username': 'Konnte Benutzername nicht prüfen',
                'forum-link': '/konto/forum'
            }
        };
    }



    _handleForgotPress(event) {
        if (event.key === 'Enter') {
            this._resetPassword();
        }
    }
    
    
    
    _resetPassword() {
        const username = this.shadowRoot.getElementById('username-input').value.trim();
        if (username === '') {
            this._showNotification(this._('username-is-empty'));
            return;
        }

        if (/\s/.test(username)) {
            this._showNotification(this._('invalid-username'));
            return;
        }
        
        this._loading = true;                
        
        let userData = {
            Username : username,
            Pool : this.USER_POOL
        };
        this._cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        this._cognitoUser.forgotPassword({
            onSuccess: (result) => {
                this.passwordSent = true;
                this._loading = false;
                this._disableUser = true;
                this._username = username;
            },

            onFailure: (error) => {
                if (username.includes('@') && (error.code === 'UserNotFoundException')) {
                    this.makeLambdaRequest('account/login-mail-check', 'GET', {email: username}, false).then(
                        (account) => {
                            if (!account.username) {
                                this._showNotification(this._('user-does-not-exist'));
                                this._loading = false;
                                return;                    
                            }
            
                            let userData = {
                                Username : account.username,
                                Pool : this.USER_POOL
                            };
                            this._cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
                            this._cognitoUser.forgotPassword({
                                onSuccess: (result) => {
                                    this.passwordSent = true;
                                    this._loading = false;
                                    this._disableUser = true;
                                    this._username = username;
                                },

                                onFailure: (error) => {
                                    if (error.code === 'InvalidParameterException') {
                                        this.shadowRoot.getElementById('send-activation-again-dialog').show();
                                    }
                                    else if (error.code === 'UserNotFoundException') {
                                        this._showNotification(this._('user-does-not-exist'));
                                    }
                                    else {
                                        this._showError(this._('could-not-reset-password'), error);
                                    }
                                    this._loading = false;
                                }
                            });
                        },
                        (error) => {
                            this._showError(this._('could-not-check-username'), error);
                            this._loading = false;
                        }
                    );
                }
                else {
                    if (error.code === 'UserNotFoundException') {
                        this._showNotification(this._('user-does-not-exist'));
                    }
                    else {
                        this._showError(this._('could-not-reset-password'), error);
                    }
                    this._loading = false;
                }
            }
        });
        return;

        
    }
    
    
    
    _handleChangePress(event) {
        if (event.key === 'Enter') {
            this._changePassword();
        }
    }



    _changePassword() {
        const username = this.shadowRoot.getElementById('username-input').value.trim();
        if (username === '') {
            this._showNotification(this._('username-is-empty'));
            return;            
        }

        if (/\s/.test(username)) {
            this._showNotification(this._('invalid-username'));
            return;
        }
        
        const password = this.shadowRoot.getElementById('password-input').value;
        if (password === '') {
            this._showNotification(this._('password-is-empty'));
            return;
        }
        
        const changeCode = this.shadowRoot.getElementById('change-code-input').value.trim();
        if (changeCode === '') {
            this._showNotification(this._('change-code-is-empty'));
            return;            
        }

        if (password !== this.shadowRoot.getElementById('confirm-password-input').value) {
            this._showNotification(this._('passwords-not-equal'));
            return;
        }
        
        if ((password.length < 8) || !/.*[a-z].*/.test(password) || !/.*[A-Z].*/.test(password) || !/.*[0-9].*/.test(password)) {
            this._showNotification(this._('password-does-not-fulfill-requirements'), this._('password-requirements-info'));
            return;
        }

        this._loading = true;

        let userData = {
            Username : username,
            Pool : this.USER_POOL
        };
        let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        cognitoUser.confirmPassword(changeCode, password, {
            onSuccess: (result) => {
                this._passwordChanged = true;
                this._loading = false;
            },

            onFailure: (error) => {
                switch (error.name) {
                    case 'CodeMismatchException':
                        this._showNotification(this._('incorrect-change-code'));
                        this._loading = false;
                        break;

                    case 'UserNotFoundException':
                        if (username.includes('@')) {
                            this.makeLambdaRequest('account/login-mail-check', 'GET', {email: username}, false).then(
                                (account) => {
                                    if (!account.username) {
                                        this._showNotification(this._('user-does-not-exist'));
                                        this._loading = false;
                                        return;
                                    }
                                    let userData = {
                                        Username : account.username,
                                        Pool : this.USER_POOL
                                    };
                                    let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
                                    cognitoUser.confirmPassword(changeCode, password, {
                                        onSuccess: (result) => {
                                            this._passwordChanged = true;
                                            this._loading = false;
                                        },

                                        onFailure: (error) => {
                                            switch (error.name) {
                                                case 'CodeMismatchException':
                                                    this._showNotification(this._('incorrect-change-code'));
                                                    break;

                                                default:
                                                    this._showError(this._('could-not-change-password'), error);
                                                    break;
                                            }
                                            this._loading = false;
                                        }
                                    });
                                },
                                (error) => {
                                    this._showError(this._('could-not-change-password'), error);
                                    this._loading = false;
                                }
                            );
                        }
                        else {
                            this._showNotification(this._('user-does-not-exist'));
                            this._loading = false;
                        }

                        
                    default:
                        this._showError(this._('could-not-change-password'), error);
                        this._loading = false;
                        break;                        
                }
            }
        });
    }



    _openLogin() {
        const parameters = querystring.parse(location.search.substr(1));
        if (parameters && parameters.forward) {
            switch (parameters.forward) {
                case 'forum':
                    location.replace(location.protocol + '//' + location.host + this._('forum-link') + '?sso=' + parameters.sso + '&sig=' + parameters.sig);
                    break;

                default:
                    location.replace(location.protocol + '//' + location.host);
                    break;
            }
        }
        else {
            location.replace(location.protocol + '//' + location.host);
        }
    }
    
    
    
    firstUpdated() {
        const parameters = querystring.parse(location.search.substr(1));
        if (parameters && parameters.forward === 'forum') {
            hideMenu();
        }
    }
}

customElements.define('forgot-password-page', ForgotPasswordPage);
