import {LitElement, html, css} from 'lit-element';
import '@material/mwc-dialog';

import {CommonStyles} from '../mixins/common-styles.js';

class NotificationDialog extends CommonStyles(LitElement) {
    static get styles() {
        return [ super.styles, css`
            :host {
                position: absolute;
            }

            mwc-icon {
                fill: #b71c1c;
            }

            #notification-dialog {
                margin-top: auto;
                margin-bottom: auto;
                max-width: 400px;
            }

            #dialog-content {
                margin-top: 20px;
                max-height: 70vh;
                -webkit-overflow-scrolling: touch;
                overflow-y: auto;
            }

            #dialog-message {
                font-weight: 600;
                white-space: pre-wrap;
            }

            #dialog-explanation {
                margin-top: 20px;
                white-space: pre-wrap;
            }
        `];
    }



    static get is() {
        return 'notification-dialog';
    }



    static get properties() {
        return {
            _cssClass: {
                type: String
            },

            _hideErrorIcon: {
                type: Boolean
            },

            _initialOpen: {
                type: Boolean
            },

            message: {
                type: String
            },

            explanation: {
                type: String
            }
        };
    }



    constructor() {
        super();

        this._cssClass = '';
        this._hideErrorIcon = false;
        this._initialOpen = false;
        this.message = '';
        this.explanation = '';
    }



    render() {
        return html`
            <mwc-dialog id="notification-dialog" scrimClickAction="">
                <div class=${'left-column ' + this._cssClass}>
                    <mwc-icon ?hidden=${this._hideErrorIcon}>error</mwc-icon>
                    <div id="dialog-content">
                        <div id="dialog-message">${this.message}</div>
                        <div id="dialog-explanation" ?hidden=${!this.explanation}>${this.explanation}</div>
                    </div>
                </div>
                <mwc-button slot="primaryAction" dialogAction="close">Ok</mwc-button>
            </mwc-dialog>
        `;
    }



    showNotification(message, explanation) {
        this._cssClass = '';
        this._hideErrorIcon = true;
        this.message = message;
        this.explanation = explanation;
        if (this.shadowRoot.getElementById('notification-dialog')) {
            this.shadowRoot.getElementById('notification-dialog').show();
        }
        else {
            this._initialOpen = true;
        }
    }



    showError(message, explanation) {
        this._cssClass = 'error-red';
        this._hideErrorIcon = false;
        this.message = message;
        this.explanation = explanation;
        if (this.shadowRoot.getElementById('notification-dialog')) {
            this.shadowRoot.getElementById('notification-dialog').show();
        }
        else {
            this._initialOpen = true;
        }
    }



    firstUpdated() {
        if (this._initialOpen) {
            this.shadowRoot.getElementById('notification-dialog').show();
        }
    }
}

customElements.define(NotificationDialog.is, NotificationDialog);
