import {LitElement, html, css} from 'lit-element';
import '@material/mwc-dialog';
import '@material/mwc-button';
import '@material/mwc-textfield';
import '@material/mwc-icon-button';
import '@material/mwc-menu';
import '@material/mwc-list/mwc-list-item';

import {UserPoolMixin} from "../mixins/user-pool-mixin";
import '../components/account-selection';
import '../components/loading-card';
import '../components/loading-backdrop';
import '../components/notification-dialog';
import '../components/symcon-card';
import {LambdaRequestMixin} from "../mixins/lambda-request-mixin";
import {TranslateMixin} from "../mixins/translate-mixin";
import {ShowErrorMixin} from "../mixins/show-error-mixin";
import {CommonStyles} from "../mixins/common-styles";

class LicensesPage extends CommonStyles(TranslateMixin(ShowErrorMixin(LambdaRequestMixin(UserPoolMixin(LitElement))))) {

    static get styles() {
        return [super.styles, css`
            .no-left-margin {
                margin-left: 0 !important;
            }

            .file-label {
                color: #808080 !important;
            }

            .mdl-textfield {
                padding-bottom: 0;
                margin-top: 10px;
            }

            .icon-button-container {
                height: 36px;
                overflow: display;
            }

            .icon-button-container > mwc-icon-button {
                margin-top: -6px;
            }

            h3.overwrite-material {
                font-family: 'Ubuntu', sans-serif;
                font-weight: 700;
                font-size: 1.17em;
                line-height: 1.42857143;
            }

            .license-name {
                max-width: calc(100% - 130px);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            #sort-select {
                width: 250px;
                padding-left: 12px;
            }

            .subscription-expired {
                color: var(--error-on-dark);
            }

            .subscription-expiring {
                color: var(--warning-on-dark);
            }
        `];
    }

    static get properties() {
        return {
            _account: {
                type: String
            },
            
            _licenses: {
                type: Array
            },

            _removeLicenseName: {
                type: String
            },

            _addLicenseUsername: {
                type: String
            },

            _addLicenseFile: {
                type: String
            },

            _addLicenseFileContent: {
                type: String
            },
            
            _loadingLicenses: {
                type: Boolean
            },

            _loadingDialog: {
                type: Boolean
            },

            _loadingSendLicense: {
                type: Boolean
            },
            
            _fileReader: {
                type: Object
            },

            _userLoggedIn: {
                type: Boolean
            },

            _filter: {
                type: String
            },
            
            resources: {
                type: Object
            }
        };
    }



    constructor() {
        super();

        // Use strings instead of numbers, because the mwc-select would parse numbers to strings otherwise
        this.SORT_TYPE = {
            NAME: 'name',
            SUBSCRIPTION: 'subscription',
            INSTALLATION: 'installation',
            VERSION: 'version'
        }        
        this._account = '';
        this._filter = '';
        this._licenses = [];
        this._removeLicenseName = '';
        this._addLicenseUsername = '';
        this._addLicenseFile = '';
        this._addLicenseFileContent = '';        
        this._loadingLicenses = true;
        this._loadingDialog = false;
        this._loadingSendLicense = false;
        this._fileReader = new FileReader();
        this.resources = {
            en: {
                'license-management': 'License Management',
                'filter': 'Filter',
                'username-email': 'Username (E-Mail)',
                'license-file': 'License File',
                'abort': 'Abort',
                'add': 'Add',
                'remove-license-check': 'Are you sure you want to remove the license {licenseName} from your account? This does not delete the license but only removes it from your account',
                'no': 'No',
                'yes': 'Yes',
                'system': 'System:',
                'version': 'Version:',
                'installed-on': 'Installed on:',
                'subscription-until': 'Subscription until:',
                'remote-maintenance': 'Remote maintenance',
                'upgrade-license': 'Upgrade license',
                'purchase-license': 'Purchase license',
                'extend-subscription': 'Extend subscription',
                'remove-license': 'Remove license',
                'send-license-file': 'Send license file again',
                'add-license': 'Add license',
                'need-to-login': 'You need to login before you can manage your licenses',
                'internal-input-not-available': 'Internal input element not available',
                'adding-license-failed': 'Adding license failed',
                'removing-license-failed': 'Removing license failed',
                'message-with-license-file-sent': 'A mail with the license file was sent. Please check your inbox.',
                'requesting-license-file-failed': 'Requesting the license file failed',
                'updating-licenses-failed': 'Updating the licenses failed',
                'subscription-is-expired': 'Your subscription has expired. An active subscription is required to use the Connect service, which is used for remote maintenance.',
                'no-licenses-yet': 'You currently have no licenses registered. Add a license to your account by clicking \'Add license\'.',
                'username-is-empty': 'Please enter a username',
                'no-license-file': 'Please select a license file',
                'invalid-license-file': 'Invalid license file',
                'invalid-license-file-or-wrong-user': 'Invalid license file or file does not match username',
                'invalid-license-file-decrypt': 'Invalid license file: Decryption failed',
                'user-not-in-database': 'The user is not known in our database. Did you use an outdated license file?',
                'license-already-bound': 'This license is already bound to an account',
                'file-not-base64': 'License file is not handled as base64',
                'sort-by': 'Sort by',
                'name': 'Name',
                'expiring-subscription': 'Expiring subscription',
                'newest-installation': 'Newest installation',
                'version': 'Version'
            },
            de: {
                'license-management': 'Lizenzverwaltung',
                'filter': 'Filter',
                'username-email': 'Benutzername (E-Mail)',
                'license-file': 'Lizenzdatei',
                'abort': 'Abbrechen',
                'add': 'Hinzufügen',
                'remove-license-check': 'Soll die Lizenz {licenseName} wirklich von Ihrem Account entfernt werden? Dies löscht nicht die Lizenz, sondern löst diese nur von Ihrem Account.',
                'no': 'Nein',
                'yes': 'Ja',
                'system': 'System:',
                'version': 'Version:',
                'installed-on': 'Installiert am:',
                'subscription-until': 'Subskription bis:',
                'remote-maintenance': 'Fernwartung',
                'upgrade-license': 'Lizenz upgraden',
                'purchase-license': 'Lizenz kaufen',
                'extend-subscription': 'Subskription verlängern',
                'remove-license': 'Lizenz entfernen',
                'send-license-file': 'Lizenzdatei erneut verschicken',
                'add-license': 'Lizenz hinzufügen',
                'need-to-login': 'Sie müssen sich einloggen, bevor Sie die Lizenzverwaltung benutzen können',
                'internal-input-not-available': 'Internes input-Element nicht verfügbar',
                'adding-license-failed': 'Lizenz hinzufügen fehlgeschlagen',
                'removing-license-failed': 'Lizenz entfernen fehlgeschlagen',
                'message-with-license-file-sent': 'Nachricht mit Lizenzdatei wurde verschickt. Bitte prüfen Sie Ihren E-Mail Eingang.',
                'requesting-license-file-failed': 'Anfrage der Lizenzdatei fehlgeschlagen',
                'updating-licenses-failed': 'Aktualisierung der Lizenzen fehlgeschlagen',
                'subscription-is-expired': 'Ihre Subskription ist abgelaufen. Eine aktive Subskription ist erforderlich um den Connect Dienst zu verwenden, über welchen die Fernwartung läuft.',
                'no-licenses-yet': 'Sie haben aktuell keine Lizenzen registriert. Fügen Sie eine Lizenz hinzu, indem Sie auf "Lizenz hinzufügen" klicken.',
                'username-is-empty': 'Bitte geben Sie den Benutzernamen ein',
                'no-license-file': 'Bitte wählen Sie eine Lizenzdatei aus',
                'invalid-license-file': 'Ungültige Lizenzdatei',
                'invalid-license-file-or-wrong-user': 'Ungültige Lizenzdatei oder Lizenzdatei passt nicht zum Benutzernamen',
                'invalid-license-file-decrypt': 'Ungültige Lizenzdatei: Entschlüsselung fehlgeschlagen',
                'user-not-in-database': 'Dieser Benutzer ist nicht in der Datenbank vorhanden. Haben Sie vielleicht eine veraltete Lizenzdatei verwendet?',
                'license-already-bound': 'Diese Lizenz ist bereits mit einem Konto verknüpft',
                'file-not-base64': 'Lizenzdatei liegt nicht base64-codiert vor',
                'sort-by': 'Sortieren nach',
                'name': 'Name',
                'expiring-subscription': 'Ablaufender Subskription',
                'newest-installation': 'Neuester Installation',
                'version': 'Version'
            }
        };
    }



    render() {
        const getSubscriptionClass = (expireTimestamp) => {
            if (expireTimestamp === 0) {
                return '';
            }
            else if (expireTimestamp < (Date.now() / 1000)) {
                return 'subscription-expired';
            }
            else if ((expireTimestamp - 7776000) < (Date.now() / 1000)) { // 90 days = ~3 months
                return 'subscription-expiring';
            }
            else {
                return '';
            }
        };

        return html`
            <link rel="stylesheet" href="/mdl/material.min.css">
            <notification-dialog id="notification-dialog"></notification-dialog>
            <loading-backdrop ?hidden=${!this._loadingSendLicense}></loading-backdrop>
            
            <mwc-dialog id="add-license-dialog" class="left-column thin-dialog" scrimClickAction="">
                <loading-backdrop ?hidden=${!this._loadingDialog}></loading-backdrop>
                <mwc-textfield id="license-name-input" class="dialog-input full-width" label=${this._('username-email')}></mwc-textfield>
                
                <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label full-width has-placeholder">
                    <input id="license-file-input" class="mdl-textfield__input" type="file" @change=${this._onAddLicenseFileChanged} placeholder=""></input>
                    <label class="mdl-textfield__label file-label" for="license-file-input">${this._('license-file')}</label>
                </div>
                <mwc-button slot="secondaryAction" dialogAction="cancel">${this._('abort')}</mwc-button>
                <mwc-button slot="primaryAction" @click=${this._addLicense}>${this._('add')}</mwc-button>
            </mwc-dialog>
            
            <mwc-dialog id="remove-license-dialog" class="thin-dialog left-column" scrimClickAction="">
                <loading-backdrop ?hidden=${!this._loadingDialog}></loading-backdrop>
                <div class="side-margin top-text">${this._('remove-license-check', 'licenseName', this._removeLicenseName)}</div>
                <mwc-button slot="secondaryAction" dialogAction="cancel">${this._('no')}</mwc-button>
                <mwc-button slot="primaryAction" @click=${this._removeLicense}>${this._('yes')}</mwc-button>
            </mwc-dialog>
      
            <div class="center-column">
                <div class="right-column card-width">
                    ${this._userLoggedIn ? html`
                        <div class="center-row between">
                            <h1 class="text-color">${this._('license-management')}</h1>
                            <account-selection @account=${(event) => { this._account = event.detail.account;}} class="align-center" account="{{_account}}"></account-selection>
                        </div>
                        ${this._licenses && (this._licenses.length >= 5) ? html`
                            <div class="row full-width">
                                <mwc-textfield class="remaining" label=${this._('filter')} @input=${(event) => { this._filter = event.target.value; }}></mwc-textfield>
                                <mwc-select id="sort-select" label=${this._('sort-by')} @selected=${this._updateSort}>
                                    <mwc-list-item value=${this.SORT_TYPE.NAME} selected>${this._('name')}</mwc-list-item>
                                    <mwc-list-item value=${this.SORT_TYPE.SUBSCRIPTION}>${this._('expiring-subscription')}</mwc-list-item>
                                    <mwc-list-item value=${this.SORT_TYPE.INSTALLATION}>${this._('newest-installation')}</mwc-list-item>
                                    <mwc-list-item value=${this.SORT_TYPE.VERSION}>${this._('version')}</mwc-list-item>
                                </mwc-select>
                            </div>
                        ` : html``}
                        ${this._loadingLicenses ? html`<loading-card></loading-card>` : html``}
                        ${this._noLicenses() ? html`
                            <symcon-card>
                                <div class="regular-text">${this._('no-licenses-yet')}</div>
                            </symcon-card>
                        ` : html``}
                        ${this._licenses.map((license) => {
                            if (license.username.toLowerCase().includes(this._filter.toLowerCase())) {
                                return html`
                                    <symcon-card>
                                        <div class="left-column">
                                            <div class="center-row between top-text">
                                                <h3 class="text-color no-margin overwrite-material license-name">${license.username}</h3>
                                                <h3 class="text-color no-margin overwrite-material">${this._getEdition(license)}</h3>
                                            </div>
                                            <div class="regular-text">${this._('system')} ${this._osToNiceWord(license.os)}</div>
                                            <div class="regular-text">${this._('version')} ${this._getVersion(license.version,license.date)}</div>
                                            <div class="regular-text">${this._('installed-on')} ${this._toLocaleDate(license.dateline)}</div>
                                            <div class="regular-text top-text ${getSubscriptionClass(license.valid_until)}">${this._('subscription-until')} ${this._toLocaleDate(license.valid_until)}</div>
                                            <div class="center-row right-element">
                                                <mwc-button class="no-left-margin" @click=${this._generateOpenRemoteMaintenance(license)}>${this._('remote-maintenance')}</mwc-button>
                                                <mwc-button ?disabled=${this._isUnlimited(license)} @click=${this._generateOpenUpgrade(license)}>${this._isDemo(license) ? this._('purchase-license') : this._('upgrade-license')}</mwc-button>
                                                <mwc-button ?disabled=${this._isDemo(license)} @click=${this._openSubscription}>${this._('extend-subscription')}</mwc-button>
                                                <div class="icon-button-container">
                                                    <mwc-icon-button id=${'dropdown-button-' + this._licenses.indexOf(license)} icon="arrow_drop_down_circle" @click=${this._generateMenuClick(license)}></mwc-icon-button>
                                                </div>
                                            </div>
                                        </div>
                                    </symcon-card>
                                    <mwc-menu id=${'dropdown-menu-' + this._licenses.indexOf(license)} absolute>
                                        <mwc-list-item @click=${this._generateOpenRemoveLicenseDialog(license)}>${this._('remove-license')}</mwc-list-item>
                                        <mwc-list-item @click=${this._generateSendLicenseMail(license)}>${this._('send-license-file')}</mwc-list-item>
                                    </mwc-menu>
                                `;
                            }
                            else {
                                return html``;
                            }
                        })}                    
                        <mwc-button class="bottom-button" raised @click=${this._openAddLicenseDialog}>${this._('add-license')}</mwc-button>
                    ` : html`
                        <symcon-card class="regular-card text-card center-column">
                            <div class="regular-text">${this._('need-to-login')}</div>
                        </symcon-card>
                    `}
                </div>
            </div>
        `;
    }



    _noLicenses() {
        return ((!this._licenses || this._licenses.length === 0) && !this._loadingLicenses);
    }
    
    
    
    _osToNiceWord(os) {
        switch (os) {
            case null:
                return 'N/A';
            
            case 'legacy':
            case 'win':
                return 'Windows';
                
            case 'rpi':
                return 'Raspberry Pi';
                
            case 'ubuntu':
                return 'Ubuntu';
                
            case 'symbox':
                return 'SymBox';

            case 'mac':
            case 'osx':
                return 'Mac OS';
                
            default:
                return os;
        }
    }



    _isDemo(license) {
        return (license['limit_demo'] !== 0);
    }



    _getEdition(license) {
        if (this._isDemo(license)) {
            return 'Demo';
        }
        else if ((license.limit_webfront === 1) && (license.limit_variables === 100) && (license.limit_server !== '')) {
            return 'Light';
        }
        else if ((license.limit_webfront === 1) && (license.limit_variables === 250)) {
            return 'Basic';
        }
        else if (((license.limit_webfront === 0) || (license.limit_webfront === 5)) && (license.limit_variables === 1000)) {
            return 'Professional';
        }
        else if ((license.limit_webfront === 0) && (license.limit_variables === 0)) {
            return 'Unlimited';
        }
        else {
            return 'Custom';
        }
    }



    _getVersion(version, date) {
        if (version === null) {
            return 'N/A';
        }
        else {
            return `${version} (${date})`;
        }
    }



    _isUnlimited(license) {
        return (this._getEdition(license) === 'Unlimited');
    }



    _generateOpenUpgrade(license) {
        if (this._isDemo(license)) {
            return () => {
                window.open('//symcon.de/shop/#IP-Symcon', '_blank');
            };
        }
        else {
            return () => {
                window.open('//symcon.de/shop/#upgrade', '_blank');
            };
        }
    }



    _openSubscription() {
        window.open('//symcon.de/shop/#subskription', '_blank');
    }



    _generateOpenRemoteMaintenance(license) {
        return () => {
            if (license['valid_until'] * 1000 < new Date().getTime()) {
                this._showError(this._('subscription-is-expired'));
            }
            else {
                window.open(`https://${license.hash4}.ipmagic.de/console/?username=${encodeURIComponent(license.username)}`, '_blank');
            }
        }
    }



    _generateMenuClick(license) {
        return () => {
            this.shadowRoot.getElementById('dropdown-menu-' + this._licenses.indexOf(license)).show();
        }
    }



    // This needs to happen as on-change instead of observer to _addLicenseFile as Safari does not write anything to or react to changes to _addLicenseFile
    _onAddLicenseFileChanged(event) {
        this._addLicenseFileContent = '';
        if (!event.target) {
            this._showError(this._('internal-input-not-available'));
            return;
        }
        
        if (event.target.files.length === 0) {
            return;
        }
        
        this._fileReader.onload = () => {
            this._addLicenseFileContent = this._fileReader.result;
        };
        this._fileReader.readAsDataURL(event.target.files[0]);
    }
    
    
    
    _toLocaleDate(timestamp) {
        if (timestamp === null) {
            return 'N/A';
        }
        else {
            return new Date(timestamp * 1000).toLocaleDateString(undefined, {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric'
            });
        }
    }



    _openAddLicenseDialog() {
        this._addLicenseUsername = '';
        this._addLicenseFile = '';
        // Special handling for Safari, that Browser doesn't look at _addLicenseFile...
        this._addLicenseFileContent = '';
        this.shadowRoot.getElementById('license-file-input').value = '';
        this.shadowRoot.getElementById('add-license-dialog').show();
    }
    
    
    
    _addLicense() {
        const username = this.shadowRoot.getElementById('license-name-input').value.trim();
        if (username === '') {
            this._showNotification(this._('username-is-empty'));
            return;
        }
        
        if (this._addLicenseFileContent === '') {
            this._showNotification(this._('no-license-file'));
            return;
        }

        let splitBody = this._addLicenseFileContent.split(',');
        if (splitBody.length !== 2) {
            this._showError(this._('file-not-base64'));
            return;
        }

        let licenseFile = atob(splitBody[1]).replace(/\r/g, '').split('\n');
        
        if (licenseFile.length < 5) {
            this._showNotification(this._('invalid-license-file'));
            return;
        }
        
        this._loadingDialog = true;
        
        this.makeLambdaRequest('licenses/license', 'POST', {account: this._account, license: username}, true, this._addLicenseFileContent).then(
            () => {
                this._updateLicenses();
                this._loadingDialog = false;
                this.shadowRoot.getElementById('add-license-dialog').close();
            },
            (error) => {
                switch (error.status) {
                    case 400:
                        switch (error.message) {
                            case 'Invalid license file: RSA not verified':
                                this._showNotification(this._('invalid-license-file-or-wrong-user'));
                                break;
                                
                            case 'Invalid license file: Decrypt failed':
                                this._showNotification(this._('invalid-license-file-decrypt'));
                                break;
                                
                            default:
                                this._showError(this._('adding-license-failed'), error);
                                break;
                        }
                        break;
                        
                    case 404:
                        switch (error.message) {
                            case 'User does not exist':
                                this._showNotification(this._('user-not-in-database'));
                                break;
                                
                            default:
                                this._showError(this._('adding-license-failed'), error);
                                break;
                                
                        }
                        break;
                        
                    case 409:
                        this._showNotification(this._('license-already-bound'));
                        break;
                        
                    default:
                        this._showError(this._('adding-license-failed'), error);
                        break;
                }
                this._loadingDialog = false;
            }
        );
    }



    _removeLicense() {
        if (!this._account) {
            return;
        }

        this._loadingLicenses = true;

        this.makeLambdaRequest('licenses/license', 'DELETE', {account: this._account, license: this._removeLicenseName}).then(
            () => {
                // _updateLicenses resets _loadingLicenses
                this.shadowRoot.getElementById('remove-license-dialog').close();
                this._updateLicenses();
            },
            (error) => {
                this._showError(this._('removing-license-failed'), error);
                this._loadingLicenses = false;
            }
        );
    }



    _generateOpenRemoveLicenseDialog(license) {
        return (event) => {            
            this._removeLicenseName = license.username;
            
            this.shadowRoot.getElementById('remove-license-dialog').show();
        }
    }



    _generateSendLicenseMail(license) {
        return (event) => {
            this._loadingSendLicense = true;
            this.makeLambdaRequest('licenses/send-license', 'POST', {account: this._account, license: license.username}).then(
                () => {
                    this._showNotification(this._('message-with-license-file-sent'));
                },
                (error) => {
                    this._showError(this._('requesting-license-file-failed'), error);
                }
            ).finally(() => {
                this._loadingSendLicense = false;
            });
        }
    } 
    
    
    
    _updateLicenses() {
        if (!this._account) {
            return;
        }
        
        this._loadingLicenses = true;
        this._licenses = [];
        
        this.makeLambdaRequest('licenses/licenses', 'GET', {account: this._account}).then(
            (licenses) => {
                this._licenses = licenses;
                this._updateSort();
                this._loadingLicenses = false;
            },
            (error) => {
                this._showError(this._('updating-licenses-failed'), error);
                this._loadingLicenses = false;
            }            
        );
    }



    _updateSort(event) {
        if (!event) {
            event = {
                target: {
                    value: this.SORT_TYPE.NAME
                }
            };
        }

        // During initialization, mwc-select can send events with empty values, ignore those
        if (!event.target.value) {
            return;
        }

        let sortFunction = () => {
            return 0;
        }

        switch (event.target.value) {
            case this.SORT_TYPE.NAME:
                sortFunction = (license1, license2) => {
                    if (license1.username.toLowerCase() < license2.username.toLowerCase()) {
                        return -1;
                    }

                    if (license1.username.toLowerCase() > license2.username.toLowerCase()) {
                        return 1;
                    }

                    if (license1.username < license2.username) {
                        return -1;
                    }

                    if (license1.username > license2.username) {
                        return 1;
                    }

                    return 0;
                };
                break;

            case this.SORT_TYPE.SUBSCRIPTION:
                sortFunction = (license1, license2) => {
                    return license1.valid_until - license2.valid_until;
                };
                break;

            case this.SORT_TYPE.INSTALLATION:
                sortFunction = (license1, license2) => {
                    return license2.dateline - license1.dateline;
                };
                break;

            case this.SORT_TYPE.VERSION: {
                // This will not return a proper timestamp but the numbers will be sorted correctly
                const parseDate = (date) => {
                    const dateArray = date.split('.').map((value) => { return parseInt(value)});
                    return dateArray[0] + dateArray[1] * 100 + dateArray[2] * 10000;
                }
                sortFunction = (license1, license2) => {
                    return parseDate(license2.date) - parseDate(license1.date);
                };
                break;
            }
        }

        this._licenses.sort(sortFunction);
        this.requestUpdate();
    }



    updated(changedProperties) {
        if (!this._userLoggedIn) {
            return;
        }

        if (changedProperties.has('_account')) {
            this._updateLicenses();
        }

        for (let i = 0; i < this._licenses.length; i++) {
            // Do not set anchor for licenses that are currently filtered
            if (this.shadowRoot.getElementById('dropdown-menu-' + i)) {
                this.shadowRoot.getElementById('dropdown-menu-' + i).anchor = this.shadowRoot.getElementById('dropdown-button-' + i);
            }
        }
    }
    
    
    
    connectedCallback() {
        super.connectedCallback();
        
        this._updateLicenses();
    }
}

customElements.define('licenses-page', LicensesPage);
