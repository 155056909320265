import {html, css, LitElement} from 'lit-element';
import '@material/mwc-icon-button';

import { CommonStyles } from '../mixins/common-styles';

class SymconExpansionPanel extends CommonStyles(LitElement) {

    static get styles() {
        return [super.styles, css`
            :host {
                display: block;
            }

            .flex-grow {
                flex: 1 1 auto;
            }
        `]
    }

    static get properties() {
        return {
            opened: {
                type: Boolean
            },

            header: {
                type: String
            },

            headerClass: {
                type: String
            },

            _triggerEvent: {
                type: Boolean
            }
        };
    }



    constructor() {
        super();

        this.header = '';
        this.headerClass = '';
        this.opened = false;
        this._triggerEvent = false;
    }



    render() {
        return html`
            <div class="left-column full-width">
                <div class="center-row full-width">
                    <div class="center-row flex-grow">
                        <slot name="header"></slot>
                        <div class=${this.headerClass}>${this.header}</div>
                    </div>
                    <mwc-icon-button @click=${this._clickHandler} icon=${this.opened ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}></mwc-icon-button>
                </div>
                <div class="left-column full-width" ?hidden=${!this.opened}>
                    <slot></slot>
                </div>
            </div>
        `;
    }



    _clickHandler() {
        this.opened = !this.opened;
        if (this.opened) {
            this._triggerEvent = true;
        }
    }



    updated() {
        if (this._triggerEvent) {
            const openEvent = new CustomEvent('open', {
                bubbles: true,
                cancelable: true,
                composed: true // Has to be true for the event to be able to escape from a shadow dom
            });
            this.dispatchEvent(openEvent);
            this._triggerEvent = false;
        }
    }
}

customElements.define('symcon-expansion-panel', SymconExpansionPanel);