import {LitElement, html, css} from 'lit-element';

import {CommonStyles} from '../mixins/common-styles';

class LoadingCard extends CommonStyles(LitElement) {

    static get styles() {
        return [super.styles, css`
            :host {
                width: 100%;
                margin: 20px 0;
            }
        `];
    }

    render() {
        return html`
        <div class="full-width center-column">
            <div id="wrapper_preload">
                <div class="lds-css ng-scope"><div class="lds-eclipse" style="width:100%;height:100%"><div></div></div><style>@keyframes lds-eclipse{0%{-webkit-transform:rotate(0);transform:rotate(0)}50%{-webkit-transform:rotate(180deg);transform:rotate(180deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@-webkit-keyframes lds-eclipse{0%{-webkit-transform:rotate(0);transform:rotate(0)}50%{-webkit-transform:rotate(180deg);transform:rotate(180deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}.lds-eclipse{position:relative}.lds-eclipse div{position:absolute;-webkit-animation:lds-eclipse 1s linear infinite;animation:lds-eclipse 1s linear infinite;width:160px;height:160px;top:20px;left:20px;border-radius:50%;box-shadow:0 4px 0 0 #32c9ac;-webkit-transform-origin:80px 82px;transform-origin:80px 82px}.lds-eclipse{width:50px!important;height:50px!important;-webkit-transform:translate(-25px,-25px) scale(.25) translate(25px,25px);transform:translate(-25px,-25px) scale(.25) translate(25px,25px)}</style></div>
            </div>
        </div>
    `;
    }



    static get properties() {
        return {};
    }
}

customElements.define('loading-card', LoadingCard);
