import {css} from 'lit-element';

const primaryTextColor = css`white`;
const primaryButtonColor = css`#32c9ac`;
const cardWidth = css`width: 700px; max-width: 90vw;`;

const centerColumn = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
`;

export const commonStyles = css`
    :host {
        --mdc-theme-primary: ${primaryButtonColor};
        --error-red: #b71c1c;
        --error-on-dark: #f71616;
        --warning-on-dark: #f79516;
    }

    h1 {
        text-transform: uppercase;
        font-weight: 300;
        font-size: 40px;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    a {
        display: inline-flex;
    }

    .no-margin {
        margin: 0 !important;
    }

    .relative {
        position: relative;
    }
        
    .dialog-radio-button {
        --paper-radio-button-label-color: black;
        --paper-radio-button-unchecked-color: black;
        --paper-radio-button-checked-color: black;
    }

    .text-color {
        color: ${primaryTextColor};
    }
        
    .left-column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .right-column {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
        
    .center-column {
        ${centerColumn}
    }

    .action-buttons {
        margin-top: 20px;
    }
    
    .button-row :not(:last-child) {
        margin-right: 20px;
    }

    .bottom-button {
        margin-bottom: 20px;
    }

    .center-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
    }

    .top-row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }
        
    .row {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
    }
        
    .side-margin {
        margin: 0 12px;
    }
        
    .right-element {
        align-self: flex-end;
    }

    .outer-container {
        ${centerColumn}
        width: 100%;
    }

    .card-width {
        ${cardWidth};
    }

    .card-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        ${cardWidth};
    }

    .card-color {
        background-color: var(--card-background);
    }
        
    .regular-card {
        width: 100%;
        box-sizing: border-box;
        background-color: var(--card-background);
        margin: 20px 0;
    }

    mwc-select {
        --mdc-select-fill-color: rgba(0, 0, 0, 0);
        --mdc-select-label-ink-color: rgb(128, 128, 128);
        --mdc-select-idle-line-color: rgb(128, 128, 128);
        --mdc-select-dropdown-icon-color: rgb(128, 128, 128);
        --mdc-select-ink-color: white;
        --mdc-select-hover-line-color: ${primaryButtonColor};
    }
            
    mwc-select.dialog-select {
        --mdc-select-ink-color: black;
    }

    mwc-icon-button {
        color: ${primaryButtonColor}
    }

    mwc-textfield.dialog-input,
    mwc-textarea.dialog-input {
        --mdc-text-field-ink-color: black;
    }

    mwc-textfield,
    mwc-textarea {
        --mdc-text-field-fill-color: rgba(0, 0, 0, 0);
        --mdc-text-field-disabled-fill-color: rgba(0, 0, 0, 0);
        --mdc-text-field-label-ink-color: #808080;
        --mdc-text-field-idle-line-color: #808080;
        --mdc-text-field-hover-line-color: ${primaryButtonColor};
        --mdc-text-field-ink-color: white;
        --mdc-text-field-disabled-line-color: #808080;
        --mdc-text-field-disabled-ink-color: #808080;
    }

    mwc-switch {
        margin-right: 20px;
    }

    mwc-button {
        font-family: 'Ubuntu', sans-serif;
        font-weight: 400;
        text-transform: uppercase;
        border-radius:0px;
        --mdc-button-horizontal-padding: 15px;
        --mdc-typography-button-font-weight: 400;
        --mdc-typography-font-family: 'Ubuntu', sans-serif;
        --mdc-theme-primary: ${primaryButtonColor};
        --mdc-theme-on-primary: white;
        --mdc-button-disabled-ink-color: #a8a8a8;
    }

    .btn-regular {
        color: var(--mdc-theme-primary);
    }

    .btn-regular[disabled] {
        color: #a8a8a8;
    }

    .regular-text {
        color: ${primaryTextColor};
        font-weight: 300;
        font-size: 14px;
    }

    .text-card {
        padding: 30px;
    }

    .top-text {
        padding-bottom: 10px;
    }

    .no-link-style {
        background-color: inherit;
        color: inherit;
        text-decoration: none;
    }

    .bottom-padding {
        padding-bottom: 15px;
    }

    .full-width {
        width: 100%;
        box-sizing: border-box;
    }

    .no-side-margin {
        margin-right: 0;
        margin-left: 0;
    }

    .no-bottom-margin {
        margin-bottom: 0;
    }

    .between {
        justify-content: space-between;
        width: 100%;
    }

    .thin-dialog {
        max-width: 400px;
    }
        
    .align-center {
        align-self: center;
    }

    [hidden] {
        display: none !important;
    }

    #notification-dialog {
        z-index: 9999;
    }

    .error-red {
        color: var(--error-red);
    }
            
    .remaining {
        flex: 1 1 0;
    }
`

export const CommonStyles = (superClass => {
    class CommonStyles extends superClass {
        static get styles() {
            const styles = [
                commonStyles
            ];
            if (super.styles) {
                styles.push(super.styles);
            }
            return styles;
        }
    }

    return CommonStyles;
});