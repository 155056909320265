import { LitElement, html, css } from 'lit-element';
import '@material/mwc-button';
import '@material/mwc-tab-bar';
import '@material/mwc-linear-progress';
import { CommonStyles } from '../../mixins/common-styles';
import { ShowErrorMixin } from "../../mixins/show-error-mixin";
import { LambdaRequestMixin } from "../../mixins/lambda-request-mixin";
import { TranslateMixin } from "../../mixins/translate-mixin";
import '../../components/loading-card';
import '../../components/loading-backdrop';

class Releases extends CommonStyles(TranslateMixin(LambdaRequestMixin(ShowErrorMixin(LitElement)))) {

    static get styles() {
        return [super.styles, css`
            .releases-text {
                color: white;
                font-weight: 400;
                font-size: 14px;
            }
        
            .releases-text-blue {
                color: #57bcff;
                font-weight: 400;
                font-size: 16px;
                margin-left: 3px;
            }
        
            .releases-text-orange-not-centered {
                color: #ff9933;
                font-weight: 400;
                font-size: 16px;
            }
        
            .releases-text-orange-centered {
                color: #ff9933;
                font-weight: 400;
                font-size: 16px;
                margin-left: 13.5px;
            }
        
            .releases-text-os{
                color: white;
                font-weight: 400;
                font-size: 14px;
                margin-left: 1em;
            }
        
            .center-info{
                text-align: center;
            }
        
            .select-version{
                width: 100%;
            }
        
            .colored-release-line {
                background-color: #585D70;
                padding-top: 1px;
            }
        
            mwc-tab {
                --mdc-tab-text-label-color-default : rgba(255, 255, 255, 0.8);
            }
        
            .container-versions {
                width: 660px;
                padding-top: 3px;
                padding-bottom: 3px;
            }
        
            .column-os {
                width: 30%;
                margin-bottom: 4px;
            }
        
            .column-version {
                width: 50%;
            }
        
            .column-empty {
                width: 9%;
            }
        
            .column-info {
                width: 11%;
            }
        
            .check-icon-not-centered{
                color: #32c9ac;
                --mdc-icon-size: 1.75em;
            }
        
            .check-icon-centered{
                color: #32c9ac;
                --mdc-icon-size: 1.75em;
                margin-bottom: 13px;
                margin-left: 15px;
            }
        
            .cancel-icon{
                color: #c93232;
                --mdc-icon-size: 1.75em;
                margin-bottom: 13px;
                margin-left: 15px;
            }

            .simple-mwc-select {
                --mdc-select-fill-color: rgba(0, 0, 0, 0);
                --mdc-select-label-ink-color: rgba(0, 0, 0, 0);
                --mdc-select-idle-line-color: rgba(0, 0, 0, 0);
                --mdc-select-hover-line-color:rgba(0, 0, 0, 0);
                --mdc-theme-primary: rgb(128, 128, 128); //the theme of the ripple, the bottom bar when cliked, and the selected item on the list
                --mdc-select-dropdown-icon-color: rgb(128, 128, 128);
                --mdc-select-ink-color: white; 
                --mdc-menu-min-width: 700px; 
                --mdc-typography-subtitle1-font-size: 14px;
                --mdc-typography-subtitle1-font-weight: 100;
            }
        `];
    }

    static get properties() {
        return {
            _availableSystems: {
                type: Array
            },

            _availableFiles: {
                type: Array
            },

            _configChannels: {
                type: Object
            },

            _currentChannel: {
                type: String
            },

             _initialLoad: {
                type: Boolean
            },

            _loading: {
                type: Boolean
            },

            _pageIsLoaded: {
                type: Boolean
            },

            _shouldPerformFirstUpdate: {
                type: Boolean
            },

             _channels: {
                type: Array
            },
           
            resources: {
                type: Object
            }
        }
    }

    constructor() {
        super();

        this.resources = {
            en: {
                'releases-title': 'Releases',
                'copy-button': 'copy from {channelName}',
                'most-recent-files-button': 'get most recent files',
                'publish-button': 'publishing {channelName}',
                'publishing-card': 'Publishing to {channelName}',
                'currently-publishing-message': 'Publishing to {channel} is already in progress!',
                'waiting-message': 'Please wait...',
                'lambda-request-error-publish': 'An error has occurred when publishing in channel {channelName}',
                'lambda-request-error-system-file': 'Could not get list of files for system {systemName}',
                'lambda-request-error-config': 'Could not get current configuration for channel {channelName}',
                'lambda-request-error-system': 'Could not get available systems',
                'error-file-config': 'Could not find the file given by the configuration in the list of files',
                'error-file-name': 'Impossible to find the version of the file named {filename}',
                'no-file-selected': 'No version selected',
                'publish-version-mismatch': 'Inconsistent Versions. Please ensure that the same version is selected for all major products',
                'error-system': 'Could not find product for system {system}'
            },
            de: {
                'releases-title': 'Releases',
                'copy-button': 'Kopiere von {channelName}',
                'most-recent-files-button': 'Neuste auswählen',
                'publish-button': 'Veröffentliche {channelName}',
                'publishing-card': 'Veröffentliche auf {channelName}',
                'currently-publishing-message': 'Veröffentlichung auf {channel} bereits in Durchführung!',
                'waiting-message': 'Bitte warten...',
                'lambda-request-error-publish': 'Ein Fehler ist beim Veröffentlichen auf Kanal {channelName} aufgetreten',
                'lambda-request-error-system-file': 'Konnte Liste der Dateien für System {systemName} nicht abfragen',
                'lambda-request-error-config': 'Konnte Konfiguration für Kanal {channelName} nicht abfragen',
                'lambda-request-error-system': 'Konnte verfügbare Systeme nicht abfragen',
                'error-file-config': 'Konnte Datei aus der Konfiguration nicht in der Liste der Dateien finden',
                'error-file-name': 'Es ist nicht möglich die Version für die Datei {filename} zu ermitteln',
                'no-file-selected': 'Keine Version ausgewählt',
                'publish-version-mismatch': 'Inkonsistente Versionen. Bitte stellen Sie sicher, dass für alle Hauptprodukte die gleiche Version ausgewählt ist',
                'error-system': 'Produkt für System {system} konnte nicht gefunden werden'
            }
        };

        this._availableSystems = []; //An array with all the info about the type of each system, the name of the product and the os architecture
        this._availableFiles = []; //An array similar to _availableSystems but with also all the exe files for each system 
        this._configChannels = {}; //An object with all the configuration for each channel

        this._currentChannel = 'stable';
        this._loading = false; // For longer operations during runtime
        this._initialLoad = true; //show a spinner when it is true. Stay true until all the arrays needed to display the right infos are not finished to fill up, and BEFORE everything is display on screen
        this._pageIsLoaded = false; //became true AFTER the page display everythings for the first time. This is use to fix an issue with the initialisation of all the infos
        this._shouldPerformFirstUpdate = false;

        this._channels = [
            'stable',
            'beta', 
            'testing', 
            'ninja'
        ];

        this._receiveAvailableSystems(); //this will initialyze all the arrays 
    }

    render() {
        //Show a spinner when loading all the promises
        if (this._initialLoad) {
            return html`
                <notification-dialog id="notification-dialog"></notification-dialog>
                <div class="outer-container">
                    <loading-card></loading-card>
                </div>
            `;
        }
        //Show everything but the spinner
        else {
            //Select the most recent versions of each files on Ninja, or get the same config as the channel above the current one
            const selectNewVersions = () => {
                if (this._currentChannel === 'ninja') {
                    const container = this.shadowRoot.getElementById('container');
                    const allSelect = container.querySelectorAll('mwc-select');
                    for (var indexCurrentSelect = 0; indexCurrentSelect < allSelect.length; indexCurrentSelect++) {
                        allSelect[indexCurrentSelect].select(1);
                    }
                    //After selecting every first file of each list, update the configuration with the new values,
                    //and then call _copySelectionFromChannel to update the informations displayed.
                    //_updateConfiguration is called first because _copySelectionFromChannel will re-select every files depending on the configuration,
                    //and we want the configuration to be updated with the values of the first file for each system
                    this._updateConfiguration(); 
                    this._copySelectionFromChannel(this._currentChannel);
                } 
                else {
                    let channelAbove = '';
                    switch (this._currentChannel) {
                        case 'stable':
                            channelAbove = 'beta';
                            break;
                        case 'beta':
                            channelAbove = 'testing';
                            break;
                        case 'testing':
                            channelAbove = 'ninja';
                            break;
                    }
                    //Copy the selection of the files like they are selected in the channel above the current one,
                    //and then update the configuration of the current channel to save the new selected values of the files 
                    this._copySelectionFromChannel(channelAbove);
                    this._updateConfiguration();
                }
            }

            //Publish the changes done on the current channel
            const publishChanges = () => {
                const configToUpdate = {};
                const channel = this._configChannels[this._currentChannel];
                const arreyKeysConfig = Object.keys(channel);
                for (const fileConfig of this._availableFiles) {
                    for (const keyConfig of arreyKeysConfig) {
                        if (fileConfig.systemName === keyConfig) {
                            configToUpdate[keyConfig] = channel[fileConfig.systemName].file;
                        }
                    }
                }

                //Make pre-check that versions match
                let skipList = ["legacy-i386", "symos-armhf", "symcon-symbox-armhf"];
                let desiredVersion = null;
                let desiredVersionProduct = null;
                for (let system in configToUpdate) {
                    if (skipList.includes(system)) {
                        continue;
                    }
                    let version = this._getVersion(configToUpdate[system]).full;
                    let versionProduct = this._getProductForSystem(system);
                    if (desiredVersion) {
                        if (version !== desiredVersion) {
                            this._showError(this._('publish-version-mismatch'), version.toString() + ' => ' + versionProduct.product + ' (' + versionProduct.os_arch + ')' + "\r\n" + desiredVersion.toString() + ' => ' + desiredVersionProduct.product + ' (' + desiredVersionProduct.os_arch + ')');
                            return;
                        }
                    }
                    else {
                        // use first version as reference
                        desiredVersion = version;
                        desiredVersionProduct = versionProduct;
                    }
                }
                
                //Call the endpoint and update the config of a specific channel
                this._loading = true;
                this.makeLambdaRequest('releases/configuration/' + this._currentChannel, 'POST', {}, true, JSON.stringify(configToUpdate)).then(
                    () => {
                        this._updateConfiguration();
                    },
                    (error) => {
                        this._showError(this._('lambda-request-error-publish', 'channelName', this._currentChannel), error);
                    }
                ).finally(() => {
                    this._loading = false;
                });
            }

            return html`
                <loading-backdrop ?hidden=${!this._loading}></loading-backdrop>
                <notification-dialog id="notification-dialog"></notification-dialog>

                <div class="outer-container" id="container">
                    <div class="releases-text">
                        <h1>${this._('releases-title')}</h1>
                    </div>
                    <symcon-card displayOverflow>
                        <mwc-tab-bar>
                            ${this._channels.map((item) => html`
                                <mwc-tab label=${item}  @click=${this._switchChannel(item)}></mwc-tab>
                            `)}
                        </mwc-tab-bar>

                        ${this._getAvailableProducts().map((productName) => html`
                            <div class="container-versions">
                                <symcon-expansion-panel header=${productName} headerClass="text-color">
                                ${this._availableSystems.map((system) => {
                                    if (system.product === productName) {
                                        return html`
                                            <div class="container-versions" >
                                                <div class="colored-release-line">
                                                    <div class="row">
                                                        <div class="column-os">
                                                            <div class="releases-text-os">
                                                                <p>${system.os_arch}</p>
                                                            </div>
                                                        </div>
                                                        <div class="column-version">
                                                            <mwc-select class="simple-mwc-select select-version" id="${system.system}" naturalMenuWidth @closed=${this._updateInfoWhenSelectClosed}>
                                                                ${this._availableFiles.map((file) => {
                                                                    let fileVersion = '';
                                                                    if ((system.os_arch === file.os_arch) && (system.product === file.productName)) {
                                                                        return html`
                                                                            <mwc-list-item value="">${this._('no-file-selected')}</mwc-list-item>
                                                                            <li divider role="separator"></li>
                                                                            ${file.listFiles.map((element) => {
                                                                                let nonInteractiveVersionNumber = html``;
                                                                                //If the current file do not have the same version as the last file, create a non interactive item with the new version number
                                                                                if (this._getVersion(element).full !== fileVersion) {
                                                                                    fileVersion = this._getVersion(element).full;
                                                                                    nonInteractiveVersionNumber = html`
                                                                                        <mwc-list noninteractive>
                                                                                                <mwc-list-item value="${file.productName + fileVersion}">${file.productName} ${fileVersion}</mwc-list-item>
                                                                                                <li divider role="separator"></li>
                                                                                        </mwc-list>
                                                                                    `;
                                                                                }
                                                                                return html`
                                                                                    ${nonInteractiveVersionNumber}
                                                                                    <mwc-list-item graphic="avatar" value="${element}">${element}</mwc-list-item>
                                                                                    <li divider role="separator"></li>
                                                                                `;
                                                                            })}
                                                                        `;
                                                                    }
                                                                })}
                                                            </mwc-select>
                                                        </div>
                                                        <div class="column-empty"></div>
                                                        <div class="column-info">
                                                            <div class="center-row">
                                                                <mwc-icon class="cancel-icon" id="cancel-icon-${system.system}">cancel</mwc-icon>
                                                                <mwc-icon class="check-icon-centered" id="check-icon-${system.system}">check_circle</mwc-icon>
                                                                <p class="releases-text-orange-centered" id="info-number-of-sub-versions-above-${system.system}"></p>
                                                                <p class="releases-text-blue" id="info-number-of-versions-above-${system.system}"></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        `;
                                    } 
                                })} 
                                </symcon-expansion-panel>
                            </div>
                            <div class="colored-release-line"></div>
                        `)}
                        
                        <div class="container-versions"></div>

                        <div class="right-column">
                            <div >
                                <mwc-button id="new-versions-button" label="${this._('copy-button', 'channelName', 'beta')}" raised @click=${selectNewVersions}></mwc-button>
                                <mwc-button id="publish-button" label="${this._('publish-button', 'channelName', 'stable')}" raised @click=${publishChanges}></mwc-button>
                            </div>
                        </div>

                    </symcon-card>

                    <mwc-dialog id="alert-dialog-upload">
                        <div>${this._('currently-publishing-message', 'channel', this._currentChannel.toUpperCase())}</div>
                        <div>${this._('waiting-message')}</div>
                        <mwc-button slot="primaryAction" dialogAction="Ok">Ok</mwc-button>
                    </mwc-dialog>

                </div>
            `;
        }
    }

    //This method fix an issue with the initialization of the informations AND select all the good files according to the configuration at the initialization.
    //The issue is that all the cancel icons and check icons are displayed everywhere at the initialisation (after stopping showing the spinner), even when they are not supposed to appear.
    //Like render(), this is a lit html function and the name shouldn't be changed.
    async updated() {
        if (this._pageIsLoaded) {
            this.updateComplete.then(async() => {
                if (this._shouldPerformFirstUpdate) {
                    this._shouldPerformFirstUpdate = false;
                    this._copySelectionFromChannel('stable');
                    this.updateComplete.then(async() => {
                        //This will change all the values currently selected. 
                        //This is used to trigger an update of the rendering and it help to fix an issue with the initialization of the informations displayed. 
                        const container = this.shadowRoot.getElementById('container');
                        const allSelect = container.querySelectorAll('mwc-select');
                        for (const fileConfig of this._availableFiles) {
                            for (const currentSelect of allSelect) {
                                if (currentSelect.id === fileConfig.systemName) {
                                    currentSelect.select((currentSelect.index === 0) ? 1 : 0);
                                    this._updateInfo(currentSelect.value, currentSelect.id);
                                }
                            }
                        }
                        //Select all the good files according to the configuration at the initialization
                        this.updateComplete.then(async() => {
                            this._copySelectionFromChannel('stable');
                        })
                    })
                }
            });
        }
    }
    
    //Fill an array with all the info about the type of each system, the name of the product and the os architecture
    //+ fill an array with all the exe files for each system
    //+ fill an array with all the actual configuration for each channel
    //Everything is done while showing a loading spinner on screen
    _receiveAvailableSystems() {
        this.makeLambdaRequest('releases/systems', 'GET').then(
            (availableSystems) => {
                this._availableSystems = availableSystems;
               
               //push the list of exe files for a particular system into the array
                const receiveAvailableFiles = (systemName, productName, os_arch) => {
                    return this.makeLambdaRequest('releases/files', 'GET', {system: systemName}).then(
                        (availableFiles) => {
                            availableFiles.sort((file1, file2) => {
                                const version1 = this._getVersion(file1);
                                const version2 = this._getVersion(file2);

                                if (version1.major !== version2.major) {
                                    return version2.major - version1.major;
                                }
                                else if (version1.minor !== version2.minor) {
                                    return version2.minor - version1.minor;
                                }
                                else {
                                    return version2.sub - version1.sub;
                                }
                            });
                            this._availableFiles.push({systemName: systemName, productName: productName,  os_arch: os_arch, listFiles: availableFiles});
                        },
                        (error) => {
                            this._showError(this._('lambda-request-error-system-file', 'systemName', systemName), error);
                        }
                    );
                };

                let promisesBeforeGetConfig = [];

                for (const availableSystem of this._availableSystems) {
                    promisesBeforeGetConfig.push(receiveAvailableFiles(availableSystem.system, availableSystem.product, availableSystem.os_arch));
                }
               
                Promise.all(promisesBeforeGetConfig).then(() => {
                    //get the configuration for a specific channel
                    const receiveConfigChannel = (channelName) => {
                        return this.makeLambdaRequest('releases/configuration/' + channelName, 'GET').then(
                            (configChannel) => {
                                for (const system of this._availableSystems) {
                                    for (const listFileEntry of this._availableFiles) {
                                        if ((system.os_arch === listFileEntry.os_arch) && (system.product === listFileEntry.productName)) {
                                            for (const uniqueFile of listFileEntry.listFiles) {
                                                if (configChannel[system.system] === uniqueFile) {
                                                    configChannel[system.system] = ({file: configChannel[system.system]});
                                                }
                                                else if (configChannel[system.system] === '') {
                                                    configChannel[system.system] = ({file: ''});
                                                }
                                            }
                                        }
                                    }
                                }
                                this._configChannels[channelName] = configChannel;
                            },
                            (error) => {
                                this._showError(this._('lambda-request-error-config', 'channelName', channelName), error);
                            }
                        );
                    };

                    let promises = [];
                    for (const oneChannel of this._channels) {
                        promises.push(receiveConfigChannel(oneChannel));
                    }

                    Promise.all(promises).then(() => {

                        //Add some informations about all the versions for each files in the configuration
                        for (const oneChannel of this._channels) {
                            const channel = this._configChannels[oneChannel];
                            for (const instanceOfAvailableSystems of this._availableSystems) {
                                for (const instanceOfAvailableFiles of this._availableFiles) {
                                    let fileVersion = '';
                                    let arrayVersions = [];
                                    if ((instanceOfAvailableSystems.os_arch === instanceOfAvailableFiles.os_arch) && (instanceOfAvailableSystems.product === instanceOfAvailableFiles.productName)) {
                                        for (const oneFile of instanceOfAvailableFiles.listFiles) {
                                            const subversion = this._getVersion(oneFile).sub;
                                            if (this._getVersion(oneFile).full !== fileVersion) {
                                                fileVersion = this._getVersion(oneFile).full;
                                                arrayVersions.push({ version: fileVersion, subVersions: [subversion] });
                                            }
                                            else {
                                                arrayVersions[arrayVersions.length - 1].subVersions.push(subversion);
                                            }
                                        }
                                        channel[instanceOfAvailableFiles.systemName] = ({ file: channel[instanceOfAvailableFiles.systemName].file, allVersions: arrayVersions });
                                    }
                                }
                            }
                        }

                        //Get the most recent sub-version for each major version 
                        for (const oneChannel of this._channels) {
                            let channel = this._configChannels[oneChannel];
                            for (const system of this._availableSystems) { 
                                for (const file of this._availableFiles) {
                                    if ((system.os_arch === file.os_arch) && (system.product === file.productName)) {
                                        channel[file.systemName].allVersions.forEach((oneBigVersion, index) => {
                                            channel[file.systemName].allVersions[index].mostRecentSubVersion = oneBigVersion.subVersions[0];
                                        });
                                    }
                                }
                            }
                        }

                        //when all the promises are done, stop showing the spinner 
                        this._initialLoad = false; 
                        this.requestUpdate();
                        this.updateComplete.then(() => {
                            this._pageIsLoaded = true;
                            this._shouldPerformFirstUpdate = true;
                            this.requestUpdate();
                        });
                    });
                });
            },
           (error) => {
               this._showError(this._('lambda-request-error-system'), error);
           }
       );
   }

   //return an array with all the products 
   _getAvailableProducts() {
        let availableProducts = [];
        for (const element of this._availableSystems) {
            if (availableProducts[availableProducts.length - 1] !== element.product) {
                availableProducts.push(element.product);
            }
        }
        return availableProducts;
   }

   _getProductForSystem(system) {
       for (const element of this._availableSystems) {
           if (element.system === system) {
               return element;
           }
       }
       this._showError(this._('error-system', 'system', system), system);
       throw this._('error-system', 'system', system);
   }

    //Return an object with all the versions numbers of a file
    _getVersion(filename) {
        const splitFile = filename.split('_');
        for (const partOfFile of splitFile) {
            if (partOfFile.includes('-')) {
                const versionNumbers = partOfFile.split('-');
                const fullVersionSplitted = versionNumbers[0].split('.');
                return {
                    full: versionNumbers[0], //A String like "major.minor" (ex: "6.0")
                    major: parseInt(fullVersionSplitted[0]), //An Int representing the major version (ex: 6)
                    minor: parseInt(fullVersionSplitted[1]), //An Int representing the minor version (ex: 0)
                    sub: versionNumbers[1] //A String representing the subversion (ex: "123")
                };
            }
        }
        this._showError(this._('error-file-name', 'filename', filename), filename);
        throw this._('error-file-name', 'filename', filename);
    }

   //when switching to another channel, load the config for this channel 
   _switchChannel(newSelectedChannelName) {
        return (event) => {
            this._currentChannel = newSelectedChannelName;
            this._copySelectionFromChannel(newSelectedChannelName);
            const newVersionsButton = this.shadowRoot.getElementById('new-versions-button');
            const publishButton = this.shadowRoot.getElementById('publish-button');
            
            switch (newSelectedChannelName) {
                case 'stable':
                    newVersionsButton.label = this._('copy-button', 'channelName', 'beta');
                    break;
                case 'beta':
                    newVersionsButton.label = this._('copy-button', 'channelName', 'testing');
                    break;
                case 'testing':
                    newVersionsButton.label = this._('copy-button', 'channelName', 'ninja');
                    break;
                case 'ninja':
                    newVersionsButton.label = this._('most-recent-files-button');
                    break;
            }
            publishButton.label = this._('publish-button', 'channelName', newSelectedChannelName);
        }
    }

    //update all the selected values and the informations displayed
    _copySelectionFromChannel(originalChannelName) {
        const channel = this._configChannels[originalChannelName];
        for (const fileConfig of this._availableFiles) {
            if (channel[fileConfig.systemName].file !== '') {
                const index = this.shadowRoot.getElementById(fileConfig.systemName).items
                            .map((select) => { return select.value; })
                            .indexOf(channel[fileConfig.systemName].file);
                            
                if (index === -1) {
                    // Object is not in array
                    this._showError(this._('error-file-config'), index);
                }
                else {
                    this.shadowRoot.getElementById(fileConfig.systemName).select(index);
                    this._updateInfo(this.shadowRoot.getElementById(fileConfig.systemName).value, this.shadowRoot.getElementById(fileConfig.systemName).id);
                }
            } 
            else {
                if (this.shadowRoot.getElementById(fileConfig.systemName).id === fileConfig.systemName) {
                    this.shadowRoot.getElementById(fileConfig.systemName).select(0);
                    this._updateInfo(this.shadowRoot.getElementById(fileConfig.systemName).value, this.shadowRoot.getElementById(fileConfig.systemName).id);
                }
            }
        }
    }

    //Update the configuration for a specific channel with the new values of all the selected files
    _updateConfiguration() {
        for (const system of this._availableSystems) {
            const oneSelect = this.shadowRoot.getElementById(system.system);
            let channel = this._configChannels[this._currentChannel];
            
            if (oneSelect.value === '') {
                channel[system.system].file = '';
            }
            else {
                for (const listFileEntry of this._availableFiles) {
                    if ((system.os_arch === listFileEntry.os_arch) && (system.product === listFileEntry.productName)) {
                        for (const uniqueFile of listFileEntry.listFiles) {
                            if (oneSelect.value === uniqueFile) {
                                channel[system.system].file = oneSelect.value;
                            }
                        }
                    }
                }
            }
        }
    }

    //Update the informations displayed about a selected file
    _updateInfo(selectValue, systemName) {

        let tagInfoNumberOfVersionsAbove = this.shadowRoot.getElementById('info-number-of-versions-above-' + systemName);
        let tagInfoNumberOfSubVersionsAbove = this.shadowRoot.getElementById('info-number-of-sub-versions-above-' + systemName);

        //get the number of the sub versions that are above the current selected version, and/or display the icons needed
        let infoNumberOfSubVersionsAbove = '';
        if (selectValue === '') {
            this.shadowRoot.getElementById('cancel-icon-' + systemName).hidden = false;
            this.shadowRoot.getElementById('check-icon-' + systemName).hidden = true;
            tagInfoNumberOfVersionsAbove.innerHTML = '';
            tagInfoNumberOfSubVersionsAbove.innerHTML = '';
            return;
        }
        
        this.shadowRoot.getElementById('cancel-icon-' + systemName).hidden = true;
        const version = this._getVersion(selectValue).full;
        const subVersion = this._getVersion(selectValue).sub;
        const channel = this._configChannels[this._currentChannel];
        for (const oneBigVersion of channel[systemName].allVersions) {
            if (oneBigVersion.version == version) {
                if (oneBigVersion.mostRecentSubVersion == subVersion) {
                    this.shadowRoot.getElementById('check-icon-' + systemName).hidden = false;
                } 
                else {
                    this.shadowRoot.getElementById('check-icon-' + systemName).hidden = true;
                    const diff = oneBigVersion.subVersions.indexOf(subVersion);
                    infoNumberOfSubVersionsAbove = '(+' + diff + ')';
                }
            }
        }
        tagInfoNumberOfSubVersionsAbove.innerHTML = infoNumberOfSubVersionsAbove;

        //get the number of the major versions that are above the current selected version
        let infoNumberOfVersionsAbove = '';
        let numberOfVersionsAbove = 0;

        for (const oneBigVersion of channel[systemName].allVersions) {
            if (oneBigVersion.version == version) {
                numberOfVersionsAbove = channel[systemName].allVersions.indexOf(oneBigVersion);
                break;
            }
        }

        if (numberOfVersionsAbove !== 0) {
            infoNumberOfVersionsAbove = '(+' + numberOfVersionsAbove + ')';
            this.shadowRoot.getElementById('check-icon-' + systemName).classList.replace('check-icon-centered','check-icon-not-centered');
            this.shadowRoot.getElementById('info-number-of-sub-versions-above-' + systemName).classList.replace('releases-text-orange-centered','releases-text-orange-not-centered');
        } 
        else {
            this.shadowRoot.getElementById('check-icon-' + systemName).classList.replace('check-icon-not-centered','check-icon-centered');
            this.shadowRoot.getElementById('info-number-of-sub-versions-above-' + systemName).classList.replace('releases-text-orange-not-centered','releases-text-orange-centered');
        }
        tagInfoNumberOfVersionsAbove.innerHTML = infoNumberOfVersionsAbove;
    }

    //When selected a new file, update the configuration array
    _updateInfoWhenSelectClosed() {
        for (const system of this._availableSystems) {
            const oneSelect = this.shadowRoot.getElementById(system.system);
            let channel = this._configChannels[this._currentChannel];
            
            if (oneSelect.value === '') {
                channel[system.system].file = '';
                this._updateInfo(oneSelect.value, oneSelect.id);
            }
            else {
                for (const listFileEntry of this._availableFiles) {
                    if ((system.os_arch === listFileEntry.os_arch) && (system.product === listFileEntry.productName)) {
                        for (const uniqueFile of listFileEntry.listFiles) {
                            if (oneSelect.value === uniqueFile) {
                                channel[system.system].file = oneSelect.value;
                                this._updateInfo(oneSelect.value, oneSelect.id);
                            }
                        }
                    }
                }
            }
        }
    }
}
customElements.define('releases-page', Releases);
