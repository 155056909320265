import {LitElement, html, css} from 'lit-element';
import '@material/mwc-button';
import '@material/mwc-switch';

import {UserPoolMixin} from "../../mixins/user-pool-mixin";
import '../../components/notification-dialog';
import '../../components/symcon-card';
import {CommonStyles} from '../../mixins/common-styles';
import '../../components/loading-backdrop';
import {LambdaRequestMixin} from "../../mixins/lambda-request-mixin";
import {ShowErrorMixin} from "../../mixins/show-error-mixin";
import {TranslateMixin} from "../../mixins/translate-mixin";
import {TermsConstants} from "../../mixins/terms-constants";

class TermsAndConditions extends CommonStyles(TermsConstants(TranslateMixin(ShowErrorMixin(LambdaRequestMixin(UserPoolMixin(LitElement)))))) {

    static get styles() {
        return [super.styles, css`
            .accept-field {
                padding-top: 24px;
                padding-bottom: 24px;
            }
            
            .accept-text {
                white-space: normal;
                width: calc(100% - 104px);
            }
            
            .error-toast {
                --paper-toast-background-color: #b71c1c;
                --paper-toast-color: white;
                padding: 16px 24px;
            }
        `];
    }

    render() {
        return html`
            <notification-dialog id="notification-dialog"></notification-dialog>

            <div class="outer-container">
                <symcon-card>
                    <loading-backdrop ?hidden=${!this._loading}></loading-backdrop>
                    <div class="regular-text">${this._('conditions')}</div>
                    <div class="center-row accept-field">
                        <mwc-switch id="accept-switch" class="create-content"></mwc-switch>
                        <div class="accept-text regular-text">${this._('accept-text')}</div>
                    </div>
                    <div class="row right-element">
                        <a class="no-link-style" href="/"><mwc-button class="btn btn-regular">${this._('reject')}</mwc-button></a>
                        <mwc-button class="btn btn-regular" @click=${this._acceptTerms}>${this._('accept')}</mwc-button>
                    </div>
                </symcon-card>
            </div>
        `;
    }



    static get properties() {
        return {            
            _loading: {
                type: Boolean
            },

            _checkedAcceptConditions: {
                type: Boolean
            },

            resources: {
                type: Object
            }
        };
    }



    constructor() {
        super();

        this._loading = false;
        this._checkedAcceptConditions = false;
        this.resources = {
            'en': {
                'module-store-conditions': 'General Terms and Conditions for the Module Store',
                'module-store-conditions-description': 'Please read and accept the general terms and conditions',
                'conditions': [html`<b>Wichtiger Hinweis:<br>`,
                    html`Bitte lesen Sie die nachstehenden Vereinbarungen und Verträge sorgfältig durch, `,
                    html`bevor Sie über den Module Store der Symcon GmbH ein Modul hochladen. `,
                    html`Wenn Sie den Allgemeine Geschäftsbedingungen nicht zustimmen sind Sie nicht berechtigt `,
                    html`Module hochzuladen und diese anzubieten.</b><br>`,
                    html`<h3>Allgemeines</h3>`,
                    html`Symcon GmbH ist lediglich Vermittler der Module, die vom Entwickler erstellt und dem `,
                    html`Lizenznehmer zur Nutzung angeboten werden. Symcon GmbH wird nicht Vertragspartner vom `,
                    html`Entwickler. Vertragspartner vom Lizenznehmer wird der jeweilige Lizenznehmer. `,
                    html`Ein Schenkungsvertrag wird geschlossen zwischen dem Entwickler und dem Lizenznehmer, `,
                    html`und zwar durch Installation des Moduls bzw. Herunterladen über den Module Store. `,
                    html`Vor Abschluss eines Schenkungsvertrags mit dem Entwickler ist der Lizenznehmer nicht `,
                    html`berechtigt, die Module zu nutzen.<br>`,
                    html`<h3>Schenkungsvertrag</h3>`,
                    html`Der Entwickler räumt dem Lizenznehmer ein nicht ausschließliches und nicht übertragbares Recht `,
                    html`zur Nutzung der Module gemäß der vom Entwickler bereit gestellten Bedingungen sowie ggf. `,
                    html`daneben von dem Entwickler bereit gestellten Softwaredokumentation dargelegten Zwecke und `,
                    html`Anwendungen ein. Der Vertrag kommt zustande, wenn ein Modul installiert wird.<br>`,
                    html`<h3>Verschiedenes</h3>`,
                    html`Diese Vereinbarung und dem zwischen Ihnen und dem Lizenznehmer geschlossenen `,
                    html`Schenkungsvertrag nebst Softwaredokumentation („Vereinbarungen“) stellen den gesamten `,
                    html`Vertrag zwischen Ihnen und dem Lizenznehmer dar und regeln Ihre Nutzung des Module Store. `,
                    html`Diese Vereinbarungen ersetzen etwaige frühere Vereinbarungen. Gegebenenfalls unterliegen sie `,
                    html`auch weiteren Nutzungsbedingungen, die zur Anwendung kommen, wenn Sie hiermit verbundene `,
                    html`Dienste, bestimmte Produkte, Inhalte von Dritten oder Software von Dritten nutzen. Sollten `,
                    html`einzelne Bestimmungen dieser Vereinbarungen ganz oder teilweise nichtig oder unwirksam sein `,
                    html`oder werden, so wird dadurch die Wirksamkeit der übrigen Bestimmungen nicht berührt. `,
                    html`An die Stelle von nicht einbezogenen oder unwirksamen Allgemeinen Geschäftsbedingungen tritt `,
                    html`das Gesetzesrecht. Im Übrigen werden die Parteien anstelle der nichtigen oder unwirksamen `,
                    html`Bestimmung eine wirksame Regelung treffen, die ihr wirtschaftlich möglichst nahe kommt, soweit `,
                    html`keine ergänzende Vertragsauslegung vorrangig oder möglich ist.<br>`,
                    html`Der Module Store wird von der Symcon GmbH betrieben. Sie sind verpflichtet, alle lokalen, `,
                    html`landesrechtlichen, bundesrechtlichen und nationalen Gesetze, Anordnungen und Verordnungen, `,
                    html`die auf Ihre Nutzung des Module Store anzuwenden sind, zu befolgen.<br><br>`,
                    html`<h3>§ 1 Grundlegende Bestimmungen</h3>`,
                    html`Die nachstehenden Geschäftsbedingungen gelten für alle Verträge, die Sie über den Module Store `,
                    html`der Symcon GmbH mit dem jeweiligen Lizenznehmer schließen und für die Nutzung des Module `,
                    html`Store. Verbraucher im Sinne der nachstehenden Regelungen ist jede natürliche Person, die ein `,
                    html`Rechtsgeschäft zu Zwecken abschließt, das überwiegend weder ihrer gewerblichen noch ihrer `,
                    html`selbständigen beruflichen Tätigkeit zugerechnet werden kann. Unternehmer ist jede natürliche `,
                    html`oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines `,
                    html`Rechtsgeschäfts in Ausübung ihrer selbständigen beruflichen oder gewerblichen Tätigkeit handelt.<br><br>`,
                    html`<h3>§ 2 Zustandekommen des Vertrages</h3>`,
                    html`Gegenstand des Vertrages ist die Einräumung von Lizenzen zur Nutzung von Modulen. `,
                    html`Diese werden in verschiedenen Kategorien im Module Store unverbindlich angeboten und sind `,
                    html`kein verbindliches Angebot zum Abschluss eines Vertrages.<br>`,
                    html`Über die Schaltfläche „INSTALLATION“ wird das gewünschte Modul heruntergeladen, `,
                    html`installiert und der Vertrag mit dem Lizenznehmer kommt zustande.<br><br>`,
                    html`<h3>§ 3 Datenschutz</h3>`,
                    html`Sie sind über Art, Umfang, Ort und Zweck der Erhebung, Verarbeitung und Nutzung der für die `,
                    html`Nutzung des Module Store und die Tätigung von Geschäften erforderlichen personenbezogenen `,
                    html`bzw. geschäftsbezogenen Daten ausführlich unterrichtet worden. Die Datenschutzerklärung - `,
                    html`abrufbar unter https://www.symcon.de/impressum/#Datenschutz-Webseite wurde zur Kenntnis `,
                    html`genommen und akzeptiert. Symcon GmbH ist berechtigt mit Ihnen im Zusammenhang mit `,
                    html`Verkäufen und Dienstleistungen auf elektronischem oder anderem Weg zu kommunizieren und Sie `,
                    html`stimmen einer solchen Kommunikation zu.<br>`,
                    html`Symcon GmbH ist berechtigt Ihnen Informationen über die Module zukommen zu lassen. Sie `,
                    html`stimmen der Erhebung, Verarbeitung und Nutzung personenbezogener Daten ausdrücklich zu. Die `,
                    html`Löschung bzw. Sperrung der Daten, die Sie gemäß der Datenschutzerklärung jederzeit verlangen `,
                    html`können, bedingt, dass keine weiteren Aktionen im Module Store mehr möglich sind.<br><br>`,
                    html`<h3>§ 4 Haftung</h3>`,
                    html`Gewährleistung und Haftung richten sich dann nach den §§ 521, 523, 524 BGB (Schenkung)<br><br>`,
                    html`<h3>§ 5 Geistiges Eigentum</h3>`,
                    html`Der Module Store einschließlich Abbildungen, Grafiken, Benutzeroberflächen, redaktionellen `,
                    html`Inhalte sowie der Skripte und Software, die zur Implementierung des Module Store genutzt `,
                    html`werden, enthalten geschützte Inhalte, die der Symcon GmbH und/oder den Entwicklern zustehen `,
                    html`und die von den anwendbaren Gesetzen zum Schutz des geistigen Eigentums einschließlich des `,
                    html`Urheberrechtsgesetzes und anderen Gesetzen geschützt werden.<br>`,
                    html`Symcon GmbH hat das Urheberrecht an dem Module Store, einschließlich des Rechts zur `,
                    html`Zusammenstellung von Inhalten, von Beiträgen, von Links auf andere Internetquellen und der `,
                    html`Beschreibungen dieser Quellen. Die Nutzung von jeglichen Teilen des Module Store, außer die `,
                    html`Nutzung des Module Store wie sie hierin erlaubt ist, ist strengstens verboten und verletzt die `,
                    html`Immaterialgüterrechte von anderen und kann für Sie zu zivil- oder strafrechtlichen Folgen, `,
                    html`einschließlich etwaiger Verpflichtungen zur Zahlung von Schadensersatz wegen Verletzung von `,
                    html`Urheberrechten führen. Marken, Grafiken und Logos, die im Zusammenhang mit dem Modul `,
                    html`genutzt werden, sind Marken oder registrierte Marken von Symcon GmbH. Andere Marken, `,
                    html`Dienstleistungsmarken, Grafiken und Logos, die im Zusammenhang mit dem Module Store `,
                    html`genutzt werden, können die Marken von anderen Rechteinhabern sein. Ihnen werden keine Rechte `,
                    html`und keine Lizenzen bezüglich der vorgenannten Marken und deren Nutzung eingeräumt. `,
                    html`Garantien bedürfen der ausdrücklichen und schriftlichen Bestätigung der Symcon GmbH in `,
                    html`Textform. Support-Dienstleistungen der Module übernimmt ausschließlich der Entwickler.<br><br>`,
                    html`<h3>§ 6 Änderungen</h3>`,
                    html`Symcon GmbH behält sich das Recht vor, jederzeit die vorliegenden Vereinbarungen zu ändern `,
                    html`und Ihre Nutzung des Module Store neuen oder weiteren Vertragsbedingungen zu unterwerfen. `,
                    html`Solche Änderungen und weitere Vertragsbedingungen werden Ihnen mitgeteilt, erlangen `,
                    html`unverzüglich Wirkung, sobald sie akzeptiert wurden, und sind sodann in diese Vereinbarung `,
                    html`integriert. Für den Fall, dass Sie die Änderungen nicht akzeptieren, können wir Sie mit Wirkung `,
                    html`für die Zukunft von der Nutzung des Module Store ausschließen. Der Ausschluss vom Module `,
                    html`Store hat keine Auswirkungen auf Produkte, die Sie bereits über den Module Store erworben `,
                    html`haben.<br><br>`,
                    html`<h3>§ 7 Rechtswahl, Erfüllungsort, Gerichtsstand</h3>`,
                    html`Es gilt deutsches Recht unter Ausschluss jeglicher Regelungen des internationalen Privatrechts. `,
                    html`Erfüllungsort für alle Leistungen aus den mit uns bestehenden Geschäftsbeziehungen sowie `,
                    html`Gerichtsstand ist Lübeck. Dasselbe gilt, wenn Sie keinen allgemeinen Gerichtsstand in `,
                    html`Deutschland oder der EU haben oder der Wohnsitz oder gewöhnliche Aufenthalt im Zeitpunkt der `,
                    html`Klageerhebung nicht bekannt ist.<br><br>`,
                    html`<h3>Begriffsbestimmungen:</h3>`,
                    html`<b>„Schenkungsvertrag“</b> bezeichnet die Bedingungen auf deren Grundlage der Entwickler an den `,
                    html`Lizenznehmer das Modul lizensiert, soweit von dem Entwickler keine eigenen Lizenzbedingungen `,
                    html`bzw. Lizenzverträge zur Verfügung gestellt werden<br>`,
                    html`<b>„Modul“</b> ist eine in der IP-Symcon Software zusätzlich installierbare Software Komponente. `,
                    html`Die Software Komponente umfasst den durch den Entwickler erstellten Code sowie zusätzlich `,
                    html`integrierbare Komponenten Dritter, die für die Erlangung der Funktion notwendig sind.<br>`,
                    html`<b>„Module Store“</b> bezeichnet die Plattform der Symcon, mit dem die Module und sonstiges (z.B. `,
                    html`Dokumentation) online zur Verfügung gestellt werden.<br>`,
                    html`<b>„Module Store Anwender“</b> sind die Lizenznehmer, die über den Module Store `,
                    html`in Geschäftsbeziehung mit Entwicklern treten<br>`,
                    html`<b>„Symcon GmbH“</b> bezeichnet die Firma Symcon GmbH mit Sitz in Lübeck<br>`,
                    html`<b>„Entwickler”</b> Lizenzgeber, der seine Software bzw. Softwaredokumentation über die Symcon `,
                    html`GmbH vertreibt<br>`,
                    html`<b>„Lizenzen“</b> ist ein Oberbegriff, mit dem die einzelnen Softwarelizenzen abgedeckt werden.<br>`,
                    html`<b>„Lizenznehmer“</b> bezeichnet den rechtmäßigen Anwender der installierten und / oder installierten `,
                    html`Module<br>`,
                    html`<b>„Lizenzvertrag für Module“</b> bezeichnet den zwischen dem Entwickler und dem Anwender `,
                    html`der Module geschlossenen Lizenzvertrag<br>`,
                    html`<b>„Softwaredokumentation“</b> bezeichnet die Hilfedatei als Teil der Software sowie sämtliche `,
                    html`sonstigen Handbücher, Benutzerdokumentationen und sonstige damit in Zusammenhang stehende `,
                    html`Materialien, die zu den Modulen gehören<br>`,
                    html`<b>„Unternehmer“</b> ist jede natürliche oder juristische Person oder eine rechtsfähige `,
                    html`Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer selbständigen `,
                    html`beruflichen oder gewerblichen Tätigkeit handelt<br>`,
                    html`<b>„Verbraucher“</b> ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die `,
                    html`überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet `,
                    html`werden können`],
                'accept-text': 'Hiermit bestätige ich, dass ich die allgemeinen Geschäftsbedingungen, Kundeninformationen, ' +
                    'die Bedingungen des jeweiligen Softwarelizenzvertrages sowie die Datenschutzerklärung ' +
                    'gelesen habe und denen zustimme.',
                'accept': 'Accept',
                'reject': 'Reject',
                'need-to-accept-conditions': 'You need to confirm that you have read and accepted the general terms and conditions',
                'set-last-confirmed-store-conditions-failed': 'Setting last confirmed store conditions failed',
                'could-not-accept-terms': 'Could not accept terms and conditions'
            },
            'de': {
                'module-store-conditions': 'Allgemeine Geschäftsbedingungen für den Module Store',
                'module-store-conditions-description': 'Bitte lesen und akzeptieren Sie die allgemeinen Geschäftsbedingungen',
                'conditions': [html`<b>Wichtiger Hinweis:<br>`,
                    html`Bitte lesen Sie die nachstehenden Vereinbarungen und Verträge sorgfältig durch, `,
                    html`bevor Sie über den Module Store der Symcon GmbH ein Modul hochladen. `,
                    html`Wenn Sie den Allgemeine Geschäftsbedingungen nicht zustimmen sind Sie nicht berechtigt `,
                    html`Module hochzuladen und diese anzubieten.</b><br>`,
                    html`<h3>Allgemeines</h3>`,
                    html`Symcon GmbH ist lediglich Vermittler der Module, die vom Entwickler erstellt und dem `,
                    html`Lizenznehmer zur Nutzung angeboten werden. Symcon GmbH wird nicht Vertragspartner vom `,
                    html`Entwickler. Vertragspartner vom Lizenznehmer wird der jeweilige Lizenznehmer. `,
                    html`Ein Schenkungsvertrag wird geschlossen zwischen dem Entwickler und dem Lizenznehmer, `,
                    html`und zwar durch Installation des Moduls bzw. Herunterladen über den Module Store. `,
                    html`Vor Abschluss eines Schenkungsvertrags mit dem Entwickler ist der Lizenznehmer nicht `,
                    html`berechtigt, die Module zu nutzen.<br>`,
                    html`<h3>Schenkungsvertrag</h3>`,
                    html`Der Entwickler räumt dem Lizenznehmer ein nicht ausschließliches und nicht übertragbares Recht `,
                    html`zur Nutzung der Module gemäß der vom Entwickler bereit gestellten Bedingungen sowie ggf. `,
                    html`daneben von dem Entwickler bereit gestellten Softwaredokumentation dargelegten Zwecke und `,
                    html`Anwendungen ein. Der Vertrag kommt zustande, wenn ein Modul installiert wird.<br>`,
                    html`<h3>Verschiedenes</h3>`,
                    html`Diese Vereinbarung und dem zwischen Ihnen und dem Lizenznehmer geschlossenen `,
                    html`Schenkungsvertrag nebst Softwaredokumentation („Vereinbarungen“) stellen den gesamten `,
                    html`Vertrag zwischen Ihnen und dem Lizenznehmer dar und regeln Ihre Nutzung des Module Store. `,
                    html`Diese Vereinbarungen ersetzen etwaige frühere Vereinbarungen. Gegebenenfalls unterliegen sie `,
                    html`auch weiteren Nutzungsbedingungen, die zur Anwendung kommen, wenn Sie hiermit verbundene `,
                    html`Dienste, bestimmte Produkte, Inhalte von Dritten oder Software von Dritten nutzen. Sollten `,
                    html`einzelne Bestimmungen dieser Vereinbarungen ganz oder teilweise nichtig oder unwirksam sein `,
                    html`oder werden, so wird dadurch die Wirksamkeit der übrigen Bestimmungen nicht berührt. `,
                    html`An die Stelle von nicht einbezogenen oder unwirksamen Allgemeinen Geschäftsbedingungen tritt `,
                    html`das Gesetzesrecht. Im Übrigen werden die Parteien anstelle der nichtigen oder unwirksamen `,
                    html`Bestimmung eine wirksame Regelung treffen, die ihr wirtschaftlich möglichst nahe kommt, soweit `,
                    html`keine ergänzende Vertragsauslegung vorrangig oder möglich ist.<br>`,
                    html`Der Module Store wird von der Symcon GmbH betrieben. Sie sind verpflichtet, alle lokalen, `,
                    html`landesrechtlichen, bundesrechtlichen und nationalen Gesetze, Anordnungen und Verordnungen, `,
                    html`die auf Ihre Nutzung des Module Store anzuwenden sind, zu befolgen.<br><br>`,
                    html`<h3>§ 1 Grundlegende Bestimmungen</h3>`,
                    html`Die nachstehenden Geschäftsbedingungen gelten für alle Verträge, die Sie über den Module Store `,
                    html`der Symcon GmbH mit dem jeweiligen Lizenznehmer schließen und für die Nutzung des Module `,
                    html`Store. Verbraucher im Sinne der nachstehenden Regelungen ist jede natürliche Person, die ein `,
                    html`Rechtsgeschäft zu Zwecken abschließt, das überwiegend weder ihrer gewerblichen noch ihrer `,
                    html`selbständigen beruflichen Tätigkeit zugerechnet werden kann. Unternehmer ist jede natürliche `,
                    html`oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines `,
                    html`Rechtsgeschäfts in Ausübung ihrer selbständigen beruflichen oder gewerblichen Tätigkeit handelt.<br><br>`,
                    html`<h3>§ 2 Zustandekommen des Vertrages</h3>`,
                    html`Gegenstand des Vertrages ist die Einräumung von Lizenzen zur Nutzung von Modulen. `,
                    html`Diese werden in verschiedenen Kategorien im Module Store unverbindlich angeboten und sind `,
                    html`kein verbindliches Angebot zum Abschluss eines Vertrages.<br>`,
                    html`Über die Schaltfläche „INSTALLATION“ wird das gewünschte Modul heruntergeladen, `,
                    html`installiert und der Vertrag mit dem Lizenznehmer kommt zustande.<br><br>`,
                    html`<h3>§ 3 Datenschutz</h3>`,
                    html`Sie sind über Art, Umfang, Ort und Zweck der Erhebung, Verarbeitung und Nutzung der für die `,
                    html`Nutzung des Module Store und die Tätigung von Geschäften erforderlichen personenbezogenen `,
                    html`bzw. geschäftsbezogenen Daten ausführlich unterrichtet worden. Die Datenschutzerklärung - `,
                    html`abrufbar unter https://www.symcon.de/impressum/#Datenschutz-Webseite wurde zur Kenntnis `,
                    html`genommen und akzeptiert. Symcon GmbH ist berechtigt mit Ihnen im Zusammenhang mit `,
                    html`Verkäufen und Dienstleistungen auf elektronischem oder anderem Weg zu kommunizieren und Sie `,
                    html`stimmen einer solchen Kommunikation zu.<br>`,
                    html`Symcon GmbH ist berechtigt Ihnen Informationen über die Module zukommen zu lassen. Sie `,
                    html`stimmen der Erhebung, Verarbeitung und Nutzung personenbezogener Daten ausdrücklich zu. Die `,
                    html`Löschung bzw. Sperrung der Daten, die Sie gemäß der Datenschutzerklärung jederzeit verlangen `,
                    html`können, bedingt, dass keine weiteren Aktionen im Module Store mehr möglich sind.<br><br>`,
                    html`<h3>§ 4 Haftung</h3>`,
                    html`Gewährleistung und Haftung richten sich dann nach den §§ 521, 523, 524 BGB (Schenkung)<br><br>`,
                    html`<h3>§ 5 Geistiges Eigentum</h3>`,
                    html`Der Module Store einschließlich Abbildungen, Grafiken, Benutzeroberflächen, redaktionellen `,
                    html`Inhalte sowie der Skripte und Software, die zur Implementierung des Module Store genutzt `,
                    html`werden, enthalten geschützte Inhalte, die der Symcon GmbH und/oder den Entwicklern zustehen `,
                    html`und die von den anwendbaren Gesetzen zum Schutz des geistigen Eigentums einschließlich des `,
                    html`Urheberrechtsgesetzes und anderen Gesetzen geschützt werden.<br>`,
                    html`Symcon GmbH hat das Urheberrecht an dem Module Store, einschließlich des Rechts zur `,
                    html`Zusammenstellung von Inhalten, von Beiträgen, von Links auf andere Internetquellen und der `,
                    html`Beschreibungen dieser Quellen. Die Nutzung von jeglichen Teilen des Module Store, außer die `,
                    html`Nutzung des Module Store wie sie hierin erlaubt ist, ist strengstens verboten und verletzt die `,
                    html`Immaterialgüterrechte von anderen und kann für Sie zu zivil- oder strafrechtlichen Folgen, `,
                    html`einschließlich etwaiger Verpflichtungen zur Zahlung von Schadensersatz wegen Verletzung von `,
                    html`Urheberrechten führen. Marken, Grafiken und Logos, die im Zusammenhang mit dem Modul `,
                    html`genutzt werden, sind Marken oder registrierte Marken von Symcon GmbH. Andere Marken, `,
                    html`Dienstleistungsmarken, Grafiken und Logos, die im Zusammenhang mit dem Module Store `,
                    html`genutzt werden, können die Marken von anderen Rechteinhabern sein. Ihnen werden keine Rechte `,
                    html`und keine Lizenzen bezüglich der vorgenannten Marken und deren Nutzung eingeräumt. `,
                    html`Garantien bedürfen der ausdrücklichen und schriftlichen Bestätigung der Symcon GmbH in `,
                    html`Textform. Support-Dienstleistungen der Module übernimmt ausschließlich der Entwickler.<br><br>`,
                    html`<h3>§ 6 Änderungen</h3>`,
                    html`Symcon GmbH behält sich das Recht vor, jederzeit die vorliegenden Vereinbarungen zu ändern `,
                    html`und Ihre Nutzung des Module Store neuen oder weiteren Vertragsbedingungen zu unterwerfen. `,
                    html`Solche Änderungen und weitere Vertragsbedingungen werden Ihnen mitgeteilt, erlangen `,
                    html`unverzüglich Wirkung, sobald sie akzeptiert wurden, und sind sodann in diese Vereinbarung `,
                    html`integriert. Für den Fall, dass Sie die Änderungen nicht akzeptieren, können wir Sie mit Wirkung `,
                    html`für die Zukunft von der Nutzung des Module Store ausschließen. Der Ausschluss vom Module `,
                    html`Store hat keine Auswirkungen auf Produkte, die Sie bereits über den Module Store erworben `,
                    html`haben.<br><br>`,
                    html`<h3>§ 7 Rechtswahl, Erfüllungsort, Gerichtsstand</h3>`,
                    html`Es gilt deutsches Recht unter Ausschluss jeglicher Regelungen des internationalen Privatrechts. `,
                    html`Erfüllungsort für alle Leistungen aus den mit uns bestehenden Geschäftsbeziehungen sowie `,
                    html`Gerichtsstand ist Lübeck. Dasselbe gilt, wenn Sie keinen allgemeinen Gerichtsstand in `,
                    html`Deutschland oder der EU haben oder der Wohnsitz oder gewöhnliche Aufenthalt im Zeitpunkt der `,
                    html`Klageerhebung nicht bekannt ist.<br><br>`,
                    html`<h3>Begriffsbestimmungen:</h3>`,
                    html`<b>„Schenkungsvertrag“</b> bezeichnet die Bedingungen auf deren Grundlage der Entwickler an den `,
                    html`Lizenznehmer das Modul lizensiert, soweit von dem Entwickler keine eigenen Lizenzbedingungen `,
                    html`bzw. Lizenzverträge zur Verfügung gestellt werden<br>`,
                    html`<b>„Modul“</b> ist eine in der IP-Symcon Software zusätzlich installierbare Software Komponente. `,
                    html`Die Software Komponente umfasst den durch den Entwickler erstellten Code sowie zusätzlich `,
                    html`integrierbare Komponenten Dritter, die für die Erlangung der Funktion notwendig sind.<br>`,
                    html`<b>„Module Store“</b> bezeichnet die Plattform der Symcon, mit dem die Module und sonstiges (z.B. `,
                    html`Dokumentation) online zur Verfügung gestellt werden.<br>`,
                    html`<b>„Module Store Anwender“</b> sind die Lizenznehmer, die über den Module Store `,
                    html`in Geschäftsbeziehung mit Entwicklern treten<br>`,
                    html`<b>„Symcon GmbH“</b> bezeichnet die Firma Symcon GmbH mit Sitz in Lübeck<br>`,
                    html`<b>„Entwickler”</b> Lizenzgeber, der seine Software bzw. Softwaredokumentation über die Symcon `,
                    html`GmbH vertreibt<br>`,
                    html`<b>„Lizenzen“</b> ist ein Oberbegriff, mit dem die einzelnen Softwarelizenzen abgedeckt werden.<br>`,
                    html`<b>„Lizenznehmer“</b> bezeichnet den rechtmäßigen Anwender der installierten und / oder installierten `,
                    html`Module<br>`,
                    html`<b>„Lizenzvertrag für Module“</b> bezeichnet den zwischen dem Entwickler und dem Anwender `,
                    html`der Module geschlossenen Lizenzvertrag<br>`,
                    html`<b>„Softwaredokumentation“</b> bezeichnet die Hilfedatei als Teil der Software sowie sämtliche `,
                    html`sonstigen Handbücher, Benutzerdokumentationen und sonstige damit in Zusammenhang stehende `,
                    html`Materialien, die zu den Modulen gehören<br>`,
                    html`<b>„Unternehmer“</b> ist jede natürliche oder juristische Person oder eine rechtsfähige `,
                    html`Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer selbständigen `,
                    html`beruflichen oder gewerblichen Tätigkeit handelt<br>`,
                    html`<b>„Verbraucher“</b> ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die `,
                    html`überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet `,
                    html`werden können`],
                'accept-text': 'Hiermit bestätige ich, dass ich die allgemeinen Geschäftsbedingungen, Kundeninformationen, ' +
                    'die Bedingungen des jeweiligen Softwarelizenzvertrages sowie die Datenschutzerklärung ' +
                    'gelesen habe und denen zustimme.',
                'accept': 'Akzeptieren',
                'reject': 'Ablehnen',
                'need-to-accept-conditions': 'Sie müssen bestätigen, dass sie die allgemeinen Geschäftsbedingungen gelesen und akzeptiert haben',
                'set-last-confirmed-store-conditions-failed': 'Das Setzen der zuletzt bestätigten Store Bedingungen ist fehlgeschlagen',
                'could-not-accept-terms': 'Konnte allgemeine Geschäftsbedingungen nicht akzeptieren'
            }
        };
    }
    
    
    
    _acceptTerms() {
        if (this.shadowRoot.getElementById('accept-switch').selected) {
            this._loading = true;
            this.makeLambdaRequest('publish/terms', 'POST', {version: this.CURRENT_TERMS_VERSION}).then(
                () => {
                    const reloadEvent = new CustomEvent('reload', {
                        bubbles: true,
                        cancelable: true,
                        composed: true // Has to be true for the event to be able to escape from a shadow dom
                    });
                    this.dispatchEvent(reloadEvent);
                },
                (error) => {
                    this._loading = false;
                    this._showError(this._('could-not-accept-terms'), error);
                }
            )
        }
        else {
            this._showNotification(this._('need-to-accept-conditions'));
        }
    }
}

customElements.define('terms-and-conditions', TermsAndConditions);
