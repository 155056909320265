const AmazonCognitoIdentity = require('amazon-cognito-identity-js');

export const getUserPool = () => {
    return new AmazonCognitoIdentity.CognitoUserPool({
        UserPoolId: 'eu-west-1_K2c2fvRQa', // Your user pool id here
        ClientId: '68riald5ep442qvu4j8j5avvhn' // Your client id here
    });
}

export const UserPoolMixin = (superClass => {
    class UserPoolMixin extends superClass {

        static get properties() {
            return {
                USER_POOL: {
                    type: Object
                },
                
                _userLoggedIn: {
                    type: Boolean
                }
            };
        }



        constructor() {
            super();

            this.USER_POOL = getUserPool();

            this._userLoggedIn = !!this.USER_POOL.getCurrentUser();
        }



        // getCurrentUser().username or .getUsername provides the username that was used for login
        // and could have other capitalization or even be an email, so we have to dig a bit deeper for the real one
        _getRealUsername() {
            return new Promise((resolve, reject) => {
                const user = this.USER_POOL.getCurrentUser();
                if (!user) {
                    reject('No user');
                    return;
                }
                user.getSession((error, session) => {
                    if (error) {
                        reject(error);
                    }
                    else {
                        user.getUserData((error, data) => {
                            if (error) {
                                reject(error);
                            }
                            else {
                                resolve(data.Username);
                            }
                        });
                    }
                });

            });
        }
    }

    return UserPoolMixin;
});
