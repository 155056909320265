export const OrderReleasesMixin = (superClass => {
    class OrderReleasesMixinImpl extends superClass {

        _orderReleases(releases, channel) {
            const result = {
                uploaded: null,
                review: null,
                released: null,
                declined: null
            };
            for (let release of releases) {
                if (release.channel === channel) {
                    switch (release.status) {
                        case 'uploaded':
                            result.uploaded = release;
                            break;
    
                        case 'review':
                            result.review = release;
                            break;
    
                        case 'endChannel':
                        case 'released':
                            if (!result.released || (result.released.id < release.id)) {
                                result.released = release;
                            }
                            break;
                            
                        case 'declined':
                            result.declined = release;
                            break;
                            
                    }
                }
            }

            return result;
        }

    }

    return OrderReleasesMixinImpl;
});
