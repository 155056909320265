import { LitElement, html } from 'lit-element';
import { Octokit } from '@octokit/rest';
import '@material/mwc-button';

import '../components/symcon-card';
import { UserPoolMixin } from "../mixins/user-pool-mixin";
import { CommonStyles } from '../mixins/common-styles';
import { TranslateMixin } from "../mixins/translate-mixin";
import { LambdaRequestMixin } from "../mixins/lambda-request-mixin";

class IndexPage extends LambdaRequestMixin(CommonStyles(TranslateMixin(UserPoolMixin(LitElement)))) {

    static get properties() {
        return {
            _permissions: {
                type: Array
            },

            logoutCallback: {
                type: Object
            },

            resources: {
                type: Object
            }
        };
    }



    constructor() {
        super();

        this._permissions = [];

        this.resources = {
            en: {
                'welcome': 'Welcome to your personal area',
                'welcome-symcon': 'This area is only available to Symcon employees',
                'licenses-link': '/licenses',
                'licenses': 'Licenses',
                'developer-link': '/developer/modules',
                'developer': 'Developer Area',
                'reviews-link': '/developer/reviews',
                'reviews': 'Reviews',
                'builds-link': '/developer/builds',
                'builds': 'Builds',
                'settings-link': '/account/settings',
                'settings': 'Settings',
                'releases-link': '/developer/releases',
                'releases': 'Releases',
                'logout': 'Logout',
                'could-not-check-permissions': 'Could not check permissions',
                'could-not-get-access-token': 'Could not get Personal Access Token',
                'could-not-get-orgs': 'Could not get Organizations'
            },
            de: {
                'welcome': 'Willkommen in Ihrem persönlichen Bereich',
                'welcome-symcon': 'Dieser Bereich ist nur für Symcon-Mitarbeiter verfügbar',
                'licenses-link': '/lizenzen',
                'licenses': 'Lizenzen',
                'developer-link': '/entwickler/module',
                'developer': 'Entwicklerbereich',
                'reviews-link': '/entwickler/reviews',
                'reviews': 'Reviews',
                'builds-link': '/entwickler/builds',
                'builds': 'Builds',
                'settings-link': '/konto/einstellungen',
                'settings': 'Einstellungen',
                'releases-link': '/entwickler/releases',
                'releases': 'Releases',
                'logout': 'Abmelden',
                'could-not-check-permissions': 'Konnte Rechte nicht überprüfen',
                'could-not-get-access-token': 'Konnte Personal Access Token nicht abfragen',
                'could-not-get-orgs': 'Konnte Organisationen nicht abfragen'
            }
        };
    }



    render() {
        return html`
            <div class="outer-container">
                <symcon-card>
                    <div class="center-column">
                        <div class="regular-text">
                            ${this._('welcome')}
                        </div>
                        <div class="center-row button-row">
                            <a href=${this._('licenses-link')} class="no-link-style action-buttons"><mwc-button raised>${this._('licenses')}</mwc-button></a>
                            <a href=${this._('developer-link')} class="no-link-style action-buttons"><mwc-button raised>${this._('developer')}</mwc-button></a>
                            <a href=${this._('settings-link')} class="no-link-style action-buttons"><mwc-button raised>${this._('settings')}</mwc-button></a>
                            <mwc-button class="action-buttons" raised @click=${this._logout}>${this._('logout')}</mwc-button>
                        </div>
                    </div>
                </symcon-card>
                ${(this._permissions.length > 0) ? html`
                    <symcon-card>
                        <div class="center-column">
                            <div class="regular-text">
                                ${this._('welcome-symcon')}
                            </div>
                            <div class="center-row button-row">
                                ${this.getPermissionButton(this._('reviews-link'), this._('reviews'), !this._permissions.includes('reviews'))}
                                ${this.getPermissionButton(this._('builds-link'), this._('builds'), !this._permissions.includes('builds'))}
                                ${this.getPermissionButton(this._('releases-link'), this._('releases'), !this._permissions.includes('releases'))}
                            </div>
                        </div>
                    </symcon-card>
                `: html``}
            </div>
        `;
    }



    getPermissionButton(link, label, disabled) {
        if (disabled) {
            return html`<mwc-button class="action-buttons" raised disabled>${label}</mwc-button>`;
        }
        else {
            return html`<a href=${link} class="no-link-style action-buttons"><mwc-button raised>${label}</mwc-button></a>`;
        }
    }



    _logout() {
        const event = new CustomEvent('logout');
        this.dispatchEvent(event);
    }



    connectedCallback() {
        super.connectedCallback();

        this.makeLambdaRequest('account/permissions', 'GET').then(
            (permissions) => {
                this._permissions.push(...permissions);
                this.requestUpdate();
            },
            (error) => {
                this._showError(this._('could-not-check-permissions'), error);
            }
        );

        this.makeLambdaRequest('account/oauth-token', 'GET', { provider: 'github' }).then(
            (accessToken) => {
                if (accessToken !== '') {
                    const octokit = new Octokit({ auth: accessToken });
                    octokit.orgs.getMembershipForAuthenticatedUser({org: 'symcon'}).then(
                        (membership) => {
                            if (membership.data.state === 'active') {
                                this._permissions.push('builds');
                                this.requestUpdate();
                            }
                        }, (error) => {
                            // Nothing to do, error means there is no connection to the organization
                        }
                    );
                }
            },
            (error) => {
                this._showError(this._('could-not-get-access-token'), error);
            }
        );
    }
}

customElements.define('index-page', IndexPage);