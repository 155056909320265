import {LitElement, html, css} from 'lit-element';
import '@material/mwc-dialog';
import '@material/mwc-button';
import '@material/mwc-textfield';
import '@material/mwc-list/mwc-list-item';
import '@material/mwc-radio';
import '@material/mwc-formfield';
import '@material/mwc-linear-progress';

const querystring = require('querystring');

import {UserPoolMixin} from "../../mixins/user-pool-mixin";
import '../../components/account-selection';
import '../../components/loading-backdrop'
import '../../components/loading-card';
import '../../components/symcon-card';
import '../../components/notification-dialog';
import {CommonStyles} from '../../mixins/common-styles';
import {LambdaRequestMixin} from "../../mixins/lambda-request-mixin";
import {XMLRequestMixin} from "../../mixins/xml-request-mixin";
import {ShowErrorMixin} from "../../mixins/show-error-mixin";
import {TranslateMixin} from "../../mixins/translate-mixin";

class ModulesList extends XMLRequestMixin(CommonStyles(TranslateMixin(ShowErrorMixin(LambdaRequestMixin(UserPoolMixin(LitElement)))))) {

    static get styles() {
        return [super.styles, css`
            .italic {
                font-style: italic;
            }

            .right-margin {
                margin-right: 10px;
            }
        `];
    }

    render() {
        const filteredModules = this._modules.filter((module) => {
            return (this._getDisplayedName(module.name).toLowerCase().includes(this._filter.toLowerCase()) || (module.bundle.toLowerCase().includes(this._filter.toLowerCase())));
        });

        return html`      
            <notification-dialog id="notification-dialog"></notification-dialog>
            
            <mwc-dialog id="add-module-dialog" class="thin-dialog" scrimClickAction="">
                <loading-backdrop ?hidden=${!this._loadingAddModule}></loading-backdrop>
                <div class="left-column">
                    <div class="top-text">${this._('add-module-description')}</div>
                    <mwc-textfield id="bundle-id-input" class="dialog-input full-width top-text" label=${this._('bundle-id')}></mwc-textfield>
                    <div class="center-row">
                        <span>${this._('initial-channel')}</span>
                        <mwc-formfield label=${this._('stable')}>
                            <mwc-radio name="initial-channel" @change=${(event) => { this._initialChannel = 'stable'; }}></mwc-radio>
                        </mwc-formfield>
                        <mwc-formfield label=${this._('beta')}>
                            <mwc-radio name="initial-channel" @change=${(event) => { this._initialChannel = 'beta'; }}></mwc-radio>
                        </mwc-formfield>
                        <mwc-formfield label=${this._('testing')}>
                            <mwc-radio name="initial-channel" @change=${(event) => { this._initialChannel = 'testing'; }}></mwc-radio>
                        </mwc-formfield>
                    </div>
                </div>
                <mwc-button slot="secondaryAction" dialogAction="cancel">${this._('abort')}</mwc-button>
                <mwc-button slot="primaryAction" @click=${this._addModule}>${this._('add')}</mwc-button>
            </mwc-dialog>

            <mwc-dialog id="update-check-loading-dialog" hideActions scrimClickAction="">
                <div class="center-column">
                    <div>${this._('update-check-loading')}</div>
                    <mwc-linear-progress class="full-width action-buttons" indeterminate></mwc-linear-progress>
                </div>

            </mwc-dialog>

            <div class="outer-container">
                <div class="right-column card-width">
                    ${this._userLoggedIn ? html`
                        <div class="center-row between">
                            <h1 class="text-color">${this._('modules')}</h1>
                            <account-selection @account=${(event) => { this._account = event.detail.account; this._updateModules();}} account=${this._account} class="align-center"></account-selection>
                        </div>
                        ${this._modules && (this._modules.length >= 5) ? html`
                            <mwc-textfield class="full-width" label=${this._('filter')} @input=${(event) => { this._filter = event.target.value; }}></mwc-textfield>
                        ` : html``}
                        ${this._loadingModules ? html`
                            <loading-card></loading-card>
                        ` : html``}
                        ${this._noModules() ? html`
                            <symcon-card>
                                <div class="regular-text">${this._('no-modules-yet')}</div>
                            </symcon-card>
                        ` : html``}
                        ${((this._modules.length > 0) && (filteredModules.length === 0)) ? html`
                            <symcon-card>
                                <div class="regular-text">${this._('all-modules-filtered')}</div>
                            </symcon-card>
                        ` : html``}
                        ${filteredModules.map((module) => {
                            return html`
                                <symcon-card>
                                    <div class="right-column">
                                        <div class="top-row between">
                                            <div class="left-column">
                                                <h3 class=${`no-margin text-color ${this._getNameClass(module.name)}`}>${this._getDisplayedName(module.name)}</h3>
                                                <div class="regular-text top-text">${this._('bundle-id') + ': ' + module.bundle}</div>
                                                <div class="regular-text">${this._('total-downloads') + ': ' + module.statistics.downloadsTotal}</div>
                                                <div class="regular-text">${this._('monthly-downloads') + ': ' + module.statistics.downloadsMonthly}</div>
                                            </div>
                                            <div class="left-column">
                                                ${[
                                                    {channel: 'stable', data: module.stable},
                                                    {channel: 'beta', data: module.beta},
                                                    {channel: 'testing', data: module.testing}
                                                ].map((channelInfo) => {
                                                    return channelInfo.data ? html`
                                                        <div class="regular-text">${this._(channelInfo.channel)}: ${this._getNiceStatus(channelInfo.data)}</div>
                                                    ` : html ``;
                                                })}
                                            </div>
                                        </div>
                                        <a href=${`/${this._('my-modules-link')}/${this._encodeBundle(module.bundle)}?account=${this._encodeURI(this._account)}`} class="no-link-style">
                                            <mwc-button>${this._('edit')}</mwc-button>
                                        </a>
                                    </div>
                                </symcon-card>
                            `;
                        })}                    
                        <div class="row bottom-button">
                            <mwc-button @click=${this._checkModulesUpToDate} raised class="right-margin">${this._('check-modules-for-updates')}</mwc-button>
                            <mwc-button @click=${this._openAddModuleDialog} raised>${this._('add-module')}</mwc-button>
                        </div>
                    ` : html `
                        <symcon-card>
                            <div class="regular-text">${this._('not-logged-in')}</div>
                        </symcon-card>
                    `}
                </div>
            </div>
        `;
    }



    static get properties() {
        return {
            _account: {
                type: String
            },

            _modules: {
                type: Array
            },

            _loadingAddModule: {
                type: Boolean
            },
            
            _loadingModules: {
                type: Boolean
            },

            _initialChannel: {
                type: String
            },

            _filter: {
                type: String
            },
            
            resources: {
                type: Object
            }
        };
    }



    constructor() {
        super();

        this._account = '';
        this._modules = [];
        this._initialChannel = '';
        this._filter = '';
        this._loadingAddModule = false;
        this._loadingModules = true;
        this.resources = {
            en: {
                'add-module-description': 'Please enter a bundle ID for your new module and select a channel for the initial version. A bundle ID consists of lower case letters and numbers. Dots are allowed to provide structure between multiple letter/number blocks.',
                'modules': 'Modules',
                'filter': 'Filter',
                'bundle-id': 'Bundle ID',
                'total-downloads': 'Total Downloads',
                'monthly-downloads': 'Monthly Downloads',
                'initial-channel': 'Initial Channel',
                'stable': 'Stable',
                'beta': 'Beta',
                'testing': 'Testing',
                'abort': 'Abort',
                'add': 'Add',
                'edit': 'Edit',
                'add-module': 'Add Module',
                'not-logged-in': 'You need to log in before managing your modules.',
                'adding-module-failed': 'Adding modules failed',
                'update-modules-failed': 'Updating modules failed',
                'no-modules-yet': 'You currently have no own modules. Create your first module by clicking \'Add Module\'.',
                'my-modules-link': 'developer/modules',
                'bundle-id-empty': 'Please enter a bundle ID',
                'bundle-id-too-long': 'Your selected Bundle ID is too long. It must not exceed 50 symbols.',
                'bundle-id-invalid': 'The chosen bundle ID is invalid. Please enter an ID that consists of lower case letters and numbers. Dots are allowed to provide structure between multiple letter/number blocks.',
                'bundle-id-already-in-my-modules': 'You already have a module with this bundle ID',
                'bundle-id-taken': 'This bundle ID is already taken by someone else',
                'bundle-id-reserved': 'Bundle IDs starting with de.symcon are reserved for the Symcon team',
                'current-unnamed': 'Currently unnamed',
                'template': 'Template in preparation',
                'in-review': 'In Review',
                'declined': 'Submission declined',
                'released': 'Released',
                'end-channel': 'Finished',
                'no-github-repository': 'No GitHub repository',
                'repository-not-existing': 'Repository does not exist',
                'could-not-load-commits': 'Could not load commits',
                'update-check-github-only': 'Available Updates can only be checked for GitHub repositories',
                'modules-not-up-to-date': 'These modules are not up to date',
                'check-modules-for-updates': 'Check up to date',
                'could-not-check-up-to-date': 'Could not check if modules are up to date',
                'initial-channel-missing': 'Please select an initial channel',
                'update-check-loading': 'All modules that are stored at GitHub are currently compared to the most current commit. Depending on the number of modules, this may take some time.',
                'could-not-get-access-token': 'Could not get Access Token',
                'all-modules-filtered': 'The filter was set too strict and hides all modules. Update your filter to make modules visible again.'
            },
            de: {
                'add-module-description': 'Bitte geben Sie eine Bundle ID für Ihr neues Modul ein und wählen Sie einen Kanal für die initiale Version. Eine Bundle ID besteht aus Kleinbuchstaben und Zahlen. Mehrere Blöcke aus Buchstaben und Zahlen dürfen durch Punkte getrennt werden.',
                'modules': 'Module',
                'filter': 'Filter',
                'bundle-id': 'Bundle ID',
                'total-downloads': 'Gesamte Downloads',
                'monthly-downloads': 'Monatliche Downloads',
                'initial-channel': 'Intialer Kanal',
                'stable': 'Stable',
                'beta': 'Beta',
                'testing': 'Testing',
                'abort': 'Abbrechen',
                'add': 'Hinzufügen',
                'edit': 'Bearbeiten',
                'add-module': 'Modul hinzufügen',
                'not-logged-in': 'Sie müssen sich einloggen, bevor Sie Ihre Module verwalten können.',
                'adding-module-failed': 'Modul hinzufügen fehlgeschlagen',
                'update-modules-failed': 'Module laden fehlgeschlagen',
                'no-modules-yet': 'Sie haben aktuell noch keine eigenen Module. Erstellen Sie Ihr erstes Modul, indem Sie auf "Modul hinzufügen" klicken.',
                'my-modules-link': 'entwickler/module',
                'bundle-id-empty': 'Bitte geben Sie eine Bundle ID ein',
                'bundle-id-too-long': 'Ihre gewählte Bundle ID ist zu lang. Sie darf nicht länger als 50 Zeichen sein.',
                'bundle-id-invalid': 'Die gewählte Bundle ID ist ungültig. Bitte geben Sie eine ID ein, die aus Kleinbuchstaben und Zahlen besteht. Mehrere Blöcke aus Buchstaben und Zahlen dürfen durch Punkte getrennt werden.',
                'bundle-id-already-in-my-modules': 'Sie haben bereits ein Modul mit dieser Bundle ID',
                'bundle-id-taken': 'Diese Bundle ID wird bereits von jemand anderem verwendet',
                'bundle-id-reserved': 'Bundle IDs, welche mit de.symcon beginnen, sind für das Symcon-Team reserviert',
                'current-unnamed': 'Aktuell unbenannt',
                'template': 'Muster in Vorbereitung',
                'in-review': 'Im Review',
                'declined': 'Einreichung abgelehnt',
                'released': 'Veröffentlicht',
                'end-channel': 'Abgeschlossen',
                'no-github-repository': 'Kein GitHub Repository',
                'repository-not-existing': 'Repository existiert nicht',
                'could-not-load-commits': 'Konnte Commits nicht laden',
                'update-check-github-only': 'Verfügbare Aktualisierungen können nur für GitHub Repositories geprüft werden',
                'modules-not-up-to-date': 'Diese Module sind nicht auf aktuellem Stand',
                'check-modules-for-updates': 'Prüfe auf aktuellen Stand',
                'could-not-check-up-to-date': 'Konnte nicht prüfen ob Module auf aktuellem Stand sind',
                'initial-channel-missing': 'Bitte wählen Sie einen initialen Kanal',
                'update-check-loading': 'Alle Module, die bei GitHub gespeichert sind, werden momentan mit dem aktuellsten Commit verglichen. Abhängig von der Anzahl der Module, kann dies länger dauern.',
                'could-not-get-access-token': 'Konnte Access Token nicht abfragen',
                'all-modules-filtered': 'Der Filter ist zu strikt gesetzt und verbirgt alle Module. Aktualisieren Sie den Filter um Module wieder darzustellen.'
            }
        };
    }



    _checkModulesUpToDate() {
        this.shadowRoot.getElementById('update-check-loading-dialog').show();

        let getGithubRepositoryName = (repositoryURL) => {
            let splitURL = repositoryURL.trim().split('github.com/');
            if (splitURL.length !== 2) {
                return '';
            }
            
            let repositoryName = splitURL[1];
            if (/.*\.git$/.test(repositoryName)) {
                repositoryName = repositoryName.substr(0, repositoryName.length - 4);
            }
    
            return repositoryName;

        }

        let generateModuleRequireUpdatePromise = (branchData, accessToken) => {
            let repositoryName = getGithubRepositoryName(branchData.git_url);
            
            if (repositoryName === '') {
                return Promise.reject(this._('no-github-repository'));
            }

            // If the repository was checked already, use the stored value, e.g. usually, when checking stable and beta from the same module
            if (commitMap.has(repositoryName)) {
                return Promise.resolve(commitMap.get(repositoryName) !== branchData.git_commit);
            }

            const headers = {};

            if (accessToken) {
                headers.Authorization = `token ${accessToken}`;
            }

            return Promise.all([
                this.makeXMLRequest(`https://api.github.com/repos/${repositoryName}/branches`, 'GET', headers),
                this.makeXMLRequest(`https://api.github.com/repos/${repositoryName}`, 'GET', headers)
            ]).then(
                ([branches, repoData]) => {
                    const checkedBranch = branches.find((branch) => {
                        return branch.name === repoData['default_branch'];
                    });

                    if (!checkedBranch) {
                        throw 'Repository has no default branch';
                    }

                    commitMap.set(repositoryName, checkedBranch.commit.sha);
                    return (checkedBranch.commit.sha !== branchData.git_commit);
                },
                (error) => {
                    switch (error.response.message) {
                        case 'Not Found':
                            throw this._('repository-not-existing');

                        case undefined:
                            throw this._('could-not-load-commits');

                        default:
                            throw error.response.message;
                    }
                }
            );
        };

        let githubOnly = true;

        let recursiveBranchesCheck = (branches, accessToken) => {
            if (branches.length === 0) {
                return Promise.resolve([]);
            }
            
            let branchData = branches.pop();
            if (branchData.status == 'endChannel') {
                return recursiveBranchesCheck(branches, accessToken);
            }
            else if (getGithubRepositoryName(branchData.git_url) === '') {
                githubOnly = false;
                return recursiveBranchesCheck(branches, accessToken);
            }
            else {
                return recursiveBranchesCheck(branches, accessToken).then((requiringUpdate) => {
                    return generateModuleRequireUpdatePromise(branchData, accessToken).then((requireUpdate) => {
                        if (requireUpdate) {
                            requiringUpdate.push(branchData);
                        }
                        return requiringUpdate;
                    });
                });
            }
        }

        let branches = [];
        let commitMap = new Map();
        for (let module of this._modules) {
            for (let branchName of ['stable', 'beta', 'testing']) {
                if (module[branchName]) {
                    let branchData = JSON.parse(JSON.stringify(module[branchName]));
                    branchData.name = module.name;
                    branchData.branch = branchName;
                    branches.push(branchData);
                }
            }
        }

        this.makeLambdaRequest('account/oauth-token', 'GET', {provider: 'github'}).then(
            (accessToken) => {
                recursiveBranchesCheck(branches, accessToken).then(
                    (requiringUpdate) => {
                        if (requiringUpdate.length > 0) {
                            let explanation = requiringUpdate.map((branchData) => { return `- ${branchData.name} (${branchData.branch})`}).join('\n');
                            if (!githubOnly) {
                                explanation += '\n\n' + this._('update-check-github-only');
                            }
        
                            this._showNotification(this._('modules-not-up-to-date'), explanation);
                        }
                        else if (!githubOnly) {
                            this._showNotification(this._('update-check-github-only'));
                        }
                    },
                    (error) => {
                        this._showError(this._('could-not-check-up-to-date'), error);
                    }
                ).finally(() => {
                    this.shadowRoot.getElementById('update-check-loading-dialog').close();
                });
            },
            (error) => {
                this._showError(this._('could-not-get-access-token'), error);
            }
        );
        
    }



    _noModules() {
        return ((!this._modules || this._modules.length === 0) && !this._loadingModules);
    }



    _encodeBundle(bundle) {
        return this._encodeURI(bundle.replace(/\./g, '-'));
    }



    _encodeURI(uri) {
        return encodeURIComponent(uri);
    }



    _getNameClass(name) {
        if (name === '') {
            return 'italic';
        }
        else {
            return '';
        }
    }



    _getNiceStatus(statusInfo) {
        
        let statusToNiceText = (status) => {
            switch (status) {
                case 'uploaded':
                    return this._('template');

                case 'review':
                    return this._('in-review');

                case 'declined':
                    return this._('declined');

                case 'released':
                    return this._('released');

                case 'endChannel':
                    return this._('end-channel');

                default:
                    return status;
            }
        };
        
        if (typeof statusInfo === 'string') {
            return statusToNiceText(statusInfo);
        }
        else if (statusInfo.status !== 'released') {
            return statusToNiceText(statusInfo.status);
        }
        else {
            let versionString = statusInfo.version;
            if (statusInfo.build !== 0) {
                versionString += ` #${statusInfo.build}`;
            }
            if (statusInfo.date !== 0) {
                versionString += ` (${new Date(statusInfo.date * 1000).toLocaleDateString()})`;
            }
            
            return `${statusToNiceText(statusInfo.status)} (${versionString})`;
        }
    }
    
    
    
    _getDisplayedName(name) {
        if (name !== '') {
            return name;            
        }
        else {
            return this._('current-unnamed');
        }
    }



    _openAddModuleDialog() {
        this.shadowRoot.getElementById('bundle-id-input').value = '';
        this.shadowRoot.getElementById('add-module-dialog').show();
    }



    _addModule() {
        let newBundleID = this.shadowRoot.getElementById('bundle-id-input').value.trim();
        
        if (newBundleID === '') {
            this._showNotification(this._('bundle-id-empty'));
            return;
        }
        
        if (newBundleID.length > 50) {
            this._showNotification(this._('bundle-id-too-long'));
            return;
        }

        if ((this._account !== 'symcon') &&  (newBundleID.substr(0, 10) === 'de.symcon.')) {
            this._showNotification(this._('bundle-id-reserved'));
            return;
        }
        
        if (!/^([a-z0-9]+\.)*[a-z0-9]+$/.test(newBundleID)) {
            this._showNotification(this._('bundle-id-invalid'));
            return;
        }
        
        for (let module of this._modules) {
            if (module.bundle === newBundleID) {
                this._showNotification(this._('bundle-id-already-in-my-modules'));
                return;                
            }
        }

        if (this._initialChannel === '') {
            this._showNotification(this._('initial-channel-missing'));
            return;
        }
        
        this._loadingAddModule = true;
        this.makeLambdaRequest('publish/add-module', 'POST', {account: this._account, bundle: newBundleID, channel: this._initialChannel}).then(
            () => {
                this._updateModules();
                this.shadowRoot.getElementById('add-module-dialog').close();
                this._loadingAddModule = false;
            },
            (error) => {
                switch (error.status) {
                    case 403:
                        this._showNotification(this._('bundle-id-taken'));
                        break;
                        
                    default:
                        this._showError(this._('adding-module-failed'), error);
                        break;
                }
                this._loadingAddModule = false;
            }
        );
    }



    _updateModules() {
        if (!this._account) {
            return;
        }
        
        this._loadingModules = true;
        this._modules = [];

        this.makeLambdaRequest('publish/modules', 'GET', {account: this._account, language: this.language}).then(
            (modules) => {
                modules.sort((module1, module2) => {
                    const name1 = this._getDisplayedName(module1.name).toLowerCase();
                    const name2 = this._getDisplayedName(module2.name).toLowerCase();

                    if (name1 < name2) {
                        return -1;
                    }
                    else if (name1 > name2) {
                        return 1;
                    }
                    else {
                        return 0;
                    }
                });
                this._modules = modules;
            },
            (error) => {
                if (error.configured) {
                    this._showError(this._('update-modules-failed'), error);
                }
            }
        ).finally(() => {
            this._loadingModules = false;
        });
    }



    connectedCallback() {
        super.connectedCallback();

        const parameters = querystring.parse(location.search.substr(1));
        if (parameters.account) {
            this._account = parameters.account;
        }

        this._updateModules();
    }
}

customElements.define('my-modules-list', ModulesList);
