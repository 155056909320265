import {LitElement, html} from 'lit-element';
import '@material/mwc-select';
import '@material/mwc-list/mwc-list-item';

import './notification-dialog';
import {CommonStyles} from '../mixins/common-styles';
import {UserPoolMixin} from "../mixins/user-pool-mixin";
import {ShowErrorMixin} from "../mixins/show-error-mixin";
import {LambdaRequestMixin} from "../mixins/lambda-request-mixin";

class AccountSelection extends CommonStyles(LambdaRequestMixin(ShowErrorMixin(UserPoolMixin(LitElement)))) {

    static get properties() {
        return {            
            _accounts: {
                type: Array
            },

            account: {
                type: String
            },
            
            resources: {
                type: Object
            }
        };
    }



    constructor() {
        super();

        this._accounts = [];
        this.account = '';
        this.resources = {
            'en': {
                'could-not-get-accounts': 'Could not get accounts',
                'account': 'Account'
            },
            'de': {
                'could-not-get-accounts': 'Konnte Konten nicht abfragen',
                'account': 'Konto'
            }
        }
    }



    render() {
        return html`
            <notification-dialog id="notification-dialog"></notification-dialog>

            ${(this._accounts.length > 1) ? html`
                <mwc-select id="account-select" label=${this._('account')} @selected=${this._updateAccount}>
                    ${this._accounts.map((account) => {
                        return html`
                            <mwc-list-item value=${account} ?selected=${account === this.account}>${account}</mwc-list-item>
                        `;
                    })}
                </mwc-select>      
            ` : html``}
        `;
    }



    _updateAccount(event) {
        // During initialization, mwc-select can send events with empty values, ignore those
        if (!event.target.value) {
            return;
        }

        this.account = event.target.value;

        this._sendAccountEvent();
    }



    _sendAccountEvent() {
        // Don't send info about an "empty" account (can happen during setup)
        if (this.account === ''){
            return;
        }
        const accountSelectionEvent = new CustomEvent('account', {
            detail: {
                account: this.account
            },
            bubbles: true,
            cancelable: true,
            composed: true // Has to be true for the event to be able to escape from a shadow dom
        });
        this.dispatchEvent(accountSelectionEvent);
    }



    connectedCallback() {
        super.connectedCallback();

        this.makeLambdaRequest('account/accounts', 'GET').then(
            (accounts) => {
                this._accounts = accounts.map((account) => { return account.account; });

                let setToPrimary = true;
                for (let account of accounts) {
                    if (account.account === this.account) {
                        setToPrimary = false;
                        break;
                    }
                }

                if (setToPrimary) {
                    for (let account of accounts) {
                        if (account.primary) {
                            this.account = account.account;
                            break;
                        }
                    }
                    this._sendAccountEvent();
                }
            },
            (error) => {
                this._showError(this._('could-not-get-accounts'), error);
            }
        );
    }
}

customElements.define('account-selection', AccountSelection);
