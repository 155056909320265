import {LitElement, html, css} from 'lit-element';

import {UserPoolMixin} from "../../mixins/user-pool-mixin";
import '../../components/loading-card';
import '../../components/notification-dialog';
import '../../components/symcon-card';
import {CommonStyles} from '../../mixins/common-styles';
import './review-card';
import {LambdaRequestMixin} from "../../mixins/lambda-request-mixin";
import {ShowErrorMixin} from "../../mixins/show-error-mixin";
import {TranslateMixin} from "../../mixins/translate-mixin";

class ReviewsList extends CommonStyles(TranslateMixin(ShowErrorMixin(LambdaRequestMixin(UserPoolMixin(LitElement))))) {

    static get styles() {
        return [ super.styles, css`
            h1.no-uppercase {
                text-transform: none;
            }
            
            .back-link-container {
                font-weight: 300;
                font-size: 16px;
                padding: 25px 0 20px 0;
                margin: 0 0 10px;
            }
            
            .back-link {
                color: #32c9ac;
                text-decoration: none !important;
            }
        `];
    }

    render() {
        return html`      
            <notification-dialog id="notification-dialog"></notification-dialog>

            <div class="outer-container">
                <div class="card-container">
                    ${this._userLoggedIn ? html`
                        ${this._noReviews() ? html`
                            <symcon-card class="regular-card text-card center-column">
                                <div class="regular-text">${this._('no-reviews')}</div>
                            </symcon-card>
                        ` : html``}
                        ${this._loading ? html`
                            <loading-card></loading-card>
                        ` : html``}
                        ${this._reviews.map((review) => {
                            return html`
                                <review-card module=${JSON.stringify(review)} availableCategories=${JSON.stringify(this._availableCategories)}></review-card>
                            `;
                        })}
                    ` : html`
                        <symcon-card class="regular-card text-card center-column">
                            <div class="regular-text">${this._('not-logged-in')}</div>
                        </symcon-card>
                    `}
                </div>
            </div>
        `;
    }



    static get properties() {
        return {
            _reviews: {
                type: Array,
                value: []
            },
            
            _loading: {
                type: Boolean,
                value: true
            },
            
            resources: {
                type: Object,
                value: {
                    en: {
                        'could-not-load-reviews': 'Could not load reviews',
                        'could-not-load-available-categories': 'Could not load available categories',
                        'not-logged-in': 'You need to log in before managing your modules.',
                        'no-reviews': 'There are currently no reviews.'
                    },
                    de: {
                        'could-not-load-reviews': 'Konnte Reviews nicht laden',
                        'could-not-load-available-categories': 'Konnte vorhandene Kategorien nicht laden',
                        'not-logged-in': 'Sie müssen sich einloggen, bevor Sie Ihre Module verwalten können.',
                        'no-reviews': 'Es gibt aktuell keine Reviews.'
                    }
                }
            }
        };
    }



    constructor() {
        super();
        
        this._reviews = [];
        this._loading = true;
        this.resources = {
            en: {
                'could-not-load-reviews': 'Could not load reviews',
                'could-not-load-available-categories': 'Could not load available categories',
                'not-logged-in': 'You need to log in before managing your modules.',
                'no-reviews': 'There are currently no reviews.'
            },
            de: {
                'could-not-load-reviews': 'Konnte Reviews nicht laden',
                'could-not-load-available-categories': 'Konnte vorhandene Kategorien nicht laden',
                'not-logged-in': 'Sie müssen sich einloggen, bevor Sie Ihre Module verwalten können.',
                'no-reviews': 'Es gibt aktuell keine Reviews.'
            }
        };
    }



    _noReviews(reviews) {
        return ((this._reviews.length === 0) && !this._loading);
    }
    
    
    
    connectedCallback() {
        super.connectedCallback();

        if (this._userLoggedIn) {
            this.makeLambdaRequest('store/categories', 'GET', {language: this.language}, false).then(
                (categories) => {
                    this._availableCategories = {};
                    for (let availableCategory of categories) {
                        this._availableCategories[availableCategory.id] = availableCategory;
                    }
                    this._loading = true;
                    this._reviews = [];
                    this.makeLambdaRequest('publish/reviews', 'GET', {}).then(
                        (releases) => {
                            this._reviews = releases;
                            this._loading = false;
                        },
                        (error) => {
                            this._showError(this._('could-not-load-reviews'), error);
                            this._loading = false;
                        }
                    )
                },
                (error) => {
                    this._showError(this._('could-not-load-available-categories'), error);
                }
            )
        }
    }
}

customElements.define('reviews-list', ReviewsList);
