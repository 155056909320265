export const makeXMLRequest = (address, mode, headers, body) => {
    return new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();
        request.open(mode, address, true);
        if (headers) {
            for (const header in headers) {
                request.setRequestHeader(header, headers[header]);
            }
        }
        request.responseType = 'json';

        request.onload = () => {
            if (request.readyState === 4) {
                if (request.status === 200) {
                    resolve(request.response);
                }
                else {
                    reject({
                        response: request.response,
                        status: request.status
                    });
                }
            }
        };

        request.onerror = (error) => {
            reject({
                response: error
            });
        };

        request.send(body);
    });
}

export const XMLRequestMixin = (superClass => {
    class XMLRequestMixin extends superClass {
        
        makeXMLRequest(address, mode, headers, body) {
            return makeXMLRequest(address, mode, headers, body);
        }
    }

    return XMLRequestMixin;
});
