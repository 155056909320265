export const ShowErrorMixin = (superClass => {
    class ShowErrorMixin extends superClass {

        _showError(message, error) {
            // TODO: Handle this Lit-Style
            let explanation = (typeof error === 'undefined') ? '' : (((typeof error === 'object') && (error.message !== undefined)) ? error.message : error);
            if (this.shadowRoot.getElementById('notification-dialog')) {
                this.shadowRoot.getElementById('notification-dialog').showError(message, explanation);
            }
            console.error(`${message}: ${explanation}`);
        }

        _showNotification(notification, explanation) {
            // TODO: Handle this Lit-Style
            if (this.shadowRoot.getElementById('notification-dialog')) {
                this.shadowRoot.getElementById('notification-dialog').showNotification(notification, explanation);
            }
            else {
                console.log(notification + (explanation ? (' - ' + explanation) : ''));
            }
        }
    }

    return ShowErrorMixin;
});
