import {html, LitElement} from 'lit-element';

import { CommonStyles } from '../mixins/common-styles';

class SymconCard extends CommonStyles(LitElement) {

    static get properties() {
        return {
            _hideTitle: {
                type: Boolean
            },

            _hideActions: {
                type: Boolean
            },

            hidePrimary: {
                type: Boolean
            },

            displayOverflow: {
                type: Boolean
            }
        };
    }



    render() {
        return html`
            <style include="common-styles">
        
                .mdl-card {
                    min-height: 0 !important;
                    margin-left: calc(50% - 350px);
                    width: 700px !important;
                    background: #2F354C !important;
                    margin: 15px 0;
                }

                .mdl-card[overflow] {
                    overflow: visible;
                }

                .primary-slot {
                    padding: 20px;
                }
        
                @media (max-width: 777px) {
                    .mdl-card {
                        width: 90vw !important;
                    }
                }
        
                .mdl-card > .mdl-card__actions {
                    border-top: 0;
                }
                
                .mdl-card > .mdl-card__title {
                    align-items: flex-start;
                    flex-direction: column;
                }
        
                .mdl-card > .mdl-card__actions {
                    display: flex;
                    box-sizing: border-box;
                    align-items: center;
                    justify-content: flex-end;
                    flex-wrap: wrap-reverse;
                }
                
                .mdl-card > .mdl-card__title {
                    color: #fff;
                }
        
                .mdl-card .mdl-textfield__input {
                    color: #ffffff;          
                }
                
                .mdl-card .mdl-textfield__label {
                    color: #808080;
                    font-weight: 400;
                    font-size: 16px;
                }
        
                .mdl-card > .mdl-card__actions {
                    font-weight: 400;
                    color: #32c9ac;
                }

                .mdl-card {
                    position: static !important;
                }
            </style>
            <link rel="stylesheet" href="/mdl/material.min.css">

            <div class="mdl-card mdl-shadow--2dp" ?overflow=${this.displayOverflow}>
                <div class="primary-slot" ?hidden=${this.hidePrimary}>
                    <slot></slot>
                </div>
                <div id="title" class="mdl-card__title mdl-card--expand" ?hidden=${this._hideTitle}>
                    <slot name="title"></slot>
                </div>
                <div id="actions" class="mdl-card__actions mdl-card--border" ?hidden=${this._hideActions}>
                    <slot name="actions"></slot>
                </div>
            </div>
        `;
    }
    


    updated() {
        const slots = this.shadowRoot.querySelectorAll('slot');
        for (const slot of slots) {
            switch (slot.name) {
                case 'title':
                    this._hideTitle = (slot.assignedNodes().length === 0);
                    break;

                case 'actions':
                    this._hideActions = (slot.assignedNodes().length === 0)
                    break;
            }
        }
    }
}

customElements.define('symcon-card', SymconCard);