import { LitElement, html } from 'lit-element';
import '@material/mwc-icon-button';
import '@material/mwc-menu';
import '@material/mwc-list/mwc-list-item';

import './diff-display';
import '../../../components/loading-backdrop';
import { CommonStyles } from '../../../mixins/common-styles';

class WorkflowDisplay extends CommonStyles(LitElement) {
    static get properties() {
        return {
            _lastRun: {
                type: Object
            },

            _buildRunning: {
                type: Boolean
            },

            _diffCurrent: {
                type: Array
            },

            _diffPrevious: {
                type: Array
            },

            _errorRun: {
                type: Object
            },

            _loading: {
                type: Boolean
            },

            _noRuns: {
                type: Boolean
            },

            workflow: {
                type: Object
            },

            branch: {
                type: String
            },

            octokit: {
                type: Object
            },

            newestCommitSHA: {
                type: String
            },

            project: {
                type: String
            }
        };
    }

    constructor() {
        super();

        this._diffCurrent = null;
        this._diffPrevious = null;
        this._buildRunning = false;
        this._errorRun = null;
        this._lastRun = null;
        this._loading = false;
        this._noRuns = false;
    }

    async _reloadData() {
        this._loading = true;
        const runsData = await this.octokit.actions.listWorkflowRuns({
            owner: 'symcon',
            repo: this.project,
            workflow_id: this.workflow.id,
            branch: this.branch,
        });

        const branchRuns = runsData.data.workflow_runs.filter((run) => {
            return run.head_branch === this.branch;
        });

        if (branchRuns.length === 0) {
            this._noRuns = true;
            this._loading = false;
            this._buildRunning = false;
            return;
        }

        this._buildRunning = (branchRuns[0].status !== 'completed');
        this._errorRun = null;

        const completedRuns = [];
        for (let run of branchRuns) {
            if (run.status === 'completed') {
                if (run.conclusion === 'success') {
                    completedRuns.push(run);
                    if (completedRuns.length === 2) {
                        break;
                    }
                }
                else if (completedRuns.length === 0) {
                    this._errorRun = run;
                }
            }
        }

        const promises = [];

        if (completedRuns.length >= 1) {
            promises.push(this.octokit.repos.compareCommits({
                owner: 'symcon',
                repo: this.project,
                base: completedRuns[0].head_commit.id,
                head: this.newestCommitSHA
            }));
        }

        if (completedRuns.length >= 2) {
            promises.push(this.octokit.repos.compareCommits({
                owner: 'symcon',
                repo: this.project,
                base: completedRuns[1].head_commit.id,
                head: completedRuns[0].head_commit.id
            }));
        }

        this._lastRun = completedRuns[0];
        const [currentDiffData, previousDiffData] = await Promise.all(promises);
        this._diffPrevious = previousDiffData ? previousDiffData.data.commits : null;
        this._diffCurrent = currentDiffData ? currentDiffData.data.commits : null;
        if (!this._diffCurrent || !this._diffPrevious) {
            console.warn('No proper diff!');
        }
        this._loading = false;
    }

    runBuild() {
        if (this._buildRunning || (this.workflow.state === 'disabled_manually')) {
            return;
        }
        this._buildRunning = true;

        this.octokit.actions.createWorkflowDispatch({
            owner: 'symcon',
            repo: this.project,
            workflow_id: this.workflow.id,
            ref: this.branch
        })
    }

    render() {
        return html`
            <style>
                :host {
                    width: 100%;
                }

                .align-right {
                    text-align: right;
                }

                .divider {
                    margin-top: 20px;
                    width: 100%;
                    height: 2px;
                    background-color: darkgrey;
                }

                .error-run {
                    margin-bottom: 20px;
                }

                .pending {
                    margin-bottom: 40px;
                }
            </style>
            <div class="left-column">
                <loading-backdrop ?hidden=${!this._loading}></loading-backdrop>
                <div class="divider"></div>
                <div class="center-row between">
                    <a href=${'//github.com/symcon/' + this.project + '/actions?query=' + encodeURI('workflow:"' + this.workflow.name + '"')} target="_blank" class="no-link-style"><h2 class="text-color">${this.workflow.name}</h2></a>
                    <mwc-button raised .label=${(this.workflow.state === 'disabled_manually') ? 'Disabled' : this._buildRunning ? "Running..." : "Run"} .disabled=${this._buildRunning || (this.workflow.state === 'disabled_manually')} @click=${this.runBuild}></mwc-button>
                </div>
                ${this._noRuns ? html`<span class="regular-text">No runs on this branch yet</span>` :
                html`
                    ${this._errorRun ? html`
                        <a href=${'//github.com/symcon/' + this.project + '/actions/runs/' + this._errorRun.id} target="_blank" class="no-link-style error-run"><div class="error-red">Last run (#${this._errorRun.run_number}, ${new Date(this._errorRun.updated_at).toLocaleString()}) failed!</div></a>
                    ` : html``}
                    <diff-display class="pending" .diff=${this._diffCurrent} project=${this.project} label="Pending Build" emptyDiffLabel="Up-to-date" nullDiffLabel="This branch was never built"></diff-display>
                    ${this._lastRun ? html`
                        <a href=${'//github.com/symcon/' + this.project + '/actions/runs/' + this._lastRun.id} target="_blank" class="no-link-style"><span class="regular-text"><b>Last Build (#${this._lastRun.run_number}, ${new Date(this._lastRun.updated_at).toLocaleString()})</b></span></a>
                    `: html``}
                    <diff-display .diff=${this._diffPrevious} project=${this.project} label="Commits from last build" emptyDiffLabel="No changes during last build" nullDiffLabel="There was no build before the last one"></diff-display>
                `
            }
            </div>`;
    }

    updated(changedProperties) {
        // We could improve performance here by only doing the individual data loading when a parameter changes
        if (changedProperties.has('branch') || changedProperties.has('newestCommitSHA')) {
            this._reloadData();
        }
    }

    connectedCallback() {
        super.connectedCallback();

        const webhookProxyUrl = "https://smee.io/xx0rtr7zsmkCbec4"; // replace with your own Webhook Proxy URL
        const source = new EventSource(webhookProxyUrl);
        source.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data['x-github-event'] !== 'check_run') {
                return;
            }

            if (`.github/workflows/${data.body.check_run.name}.yml` !== this.workflow.path) {
                return;
            }

            this._buildRunning = (data.body.action !== 'completed');

            if ((data.body.action === 'completed') && (data.body.check_run.conclusion === 'success')) {
                // Wait two seconds before requesting data again, otherwise it may still be saved as "in_progress"...
                setTimeout(() => { this._reloadData(); }, 10000);
            }
        };

        this._reloadData();
    }
}

customElements.define('workflow-display', WorkflowDisplay);