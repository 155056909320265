import {LitElement, html, css} from 'lit-element'

class LoadingBackgrop extends LitElement {
    static get styles() {
        return css`
            :host {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                padding: 0 !important;
                margin: 0 !important;
                z-index: 1;
            }
        
            #backdrop {
                position: relative;
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgba(0, 0, 0, 0.3);
            }
        `;
    }

    render() {
        return html`
            <div id="backdrop">
                <div id="wrapper_preload">
                    <div class="lds-css ng-scope"><div class="lds-eclipse" style="width:100%;height:100%"><div></div></div><style>@keyframes lds-eclipse{0%{-webkit-transform:rotate(0);transform:rotate(0)}50%{-webkit-transform:rotate(180deg);transform:rotate(180deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@-webkit-keyframes lds-eclipse{0%{-webkit-transform:rotate(0);transform:rotate(0)}50%{-webkit-transform:rotate(180deg);transform:rotate(180deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}.lds-eclipse{position:relative}.lds-eclipse div{position:absolute;-webkit-animation:lds-eclipse 1s linear infinite;animation:lds-eclipse 1s linear infinite;width:160px;height:160px;top:20px;left:20px;border-radius:50%;box-shadow:0 4px 0 0 #32c9ac;-webkit-transform-origin:80px 82px;transform-origin:80px 82px}.lds-eclipse{width:50px!important;height:50px!important;-webkit-transform:translate(-25px,-25px) scale(.25) translate(25px,25px);transform:translate(-25px,-25px) scale(.25) translate(25px,25px)}</style></div>
                </div>
            </div>
        `;
    }

    static get is() {
        return 'loading-backdrop';
    }



    static get properties() {
        return {};
    }
}

customElements.define(LoadingBackgrop.is, LoadingBackgrop);
