import { LitElement, html } from 'lit-element';

import '../../../components/symcon-expansion-panel';
import {CommonStyles} from '../../../mixins/common-styles';

class DiffDisplay extends CommonStyles(LitElement) {
    static get properties() {
        return {
            diff: {
                type: Array
            },

            label: {
                type: String
            },

            emptyDiffLabel: {
                type: String
            },

            nullDiffLabel: {
                type: String
            },

            project: {
                type: String
            }
        };
    }

    constructor() {
        super();

        this.diff = undefined;
    }

    render() {
        if (this.diff === undefined) {
            return html`<span class="regular-text">Loading...</span>`
        }

        if (this.diff === null) {
            return html`<span class="regular-text">${this.nullDiffLabel}</span>`
        }

        if (this.diff.length === 0) {
            return html`<span class="regular-text">${this.emptyDiffLabel}</span>`;
        }

        return html`
            <symcon-expansion-panel header=${this.label + ' (' + this.diff.length + ')'} headerClass="regular-text">
                ${this.diff.map((element) => {
                    return html`<a href=${'//github.com/symcon/' + this.project + '/commit/' + element.sha} target="_blank" class="no-link-style"><span class="regular-text">- ${element.commit.message}</span></a>`;
                })}
            </symcon-expansion-panel>`;
    }
}

customElements.define('diff-display', DiffDisplay);